import * as React from 'react'

import { PageHeader } from '../../components/pageHeader'
import { Actions } from './actions'
import { employeesPath } from '../../../../routes/pathHelper'
import { usePayrollParams } from '../../hooks/usePayrollParams'

export const Header = () => {
  const { client, uuid, secureId } = usePayrollParams()

  const eeUrl = employeesPath({
    client,
    uuid,
    secureId
  })

  return (
    <>
      <PageHeader currentStep='Employee earnings'>
        <Actions className='self-end' previousUrl={eeUrl} nextUrl={eeUrl} />
      </PageHeader>
    </>
  )
}
