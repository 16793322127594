import { safeLazy } from '@local/lazy'

const LazyListAchCts = safeLazy(() => {
  return import(
    /*webpackChunkName: "operations" */ './operations/ach/list/listAchCts'
  )
})

const LazyGenerateACH = safeLazy(() => {
  return import(
    /*webpackChunkName: "page.generateACH" */ './operations/ach/achDashboard/generateACH'
  )
})

const LazyPayrollList = safeLazy(() => {
  return import(
    /*webpackChunkName: "experience.list" */ './experience/list/index'
  )
})

const LazyEmployeeComparison = safeLazy(() => {
  return import(
    /*webpackChunkName: "experience.employee-comparison-report" */
    './experience/details/review/calculated/reports/employeeComparison'
  )
})

const LazyPreflight = safeLazy(() => {
  return import(
    /*webpackChunkName: "experience.preflight" */ './experience/details/preflight'
  )
})

const LazyForm8655 = safeLazy(() => {
  return import(
    /*webpackChunkName: "experience.form8655" */ './experience/forms/8655/components/form'
  )
})

const LazyForm8655List = safeLazy(() => {
  return import(
    /*webpackChunkName: "experience.form8655List" */
    './experience/forms/8655'
  )
})

const LazyForm8655Reports = safeLazy(() => {
  return import(
    /*webpackChunkName: "experience.form8655" */ './experience/forms/8655/components/reports'
  )
})

const LazyAutoApproveTimesheetsToggle = safeLazy(() => {
  return import(
    /*webpackChunkName: "experience.timesheets" */ './experience/timesheets/components/index'
  )
})

export {
  LazyListAchCts,
  LazyGenerateACH,
  LazyPayrollList,
  LazyEmployeeComparison,
  LazyPreflight,
  LazyForm8655List,
  LazyForm8655,
  LazyForm8655Reports,
  LazyAutoApproveTimesheetsToggle
}
