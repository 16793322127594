import { PayrollRouteParams } from '@local/payroll/shared/types/params'
import { useParams } from 'react-router-dom'
import { useQueryParams } from './useQueryParam'

/*
  Most routes have been switched to use uuid as their primary route param,
  with the secureId as a query param. However some legacy routes (see pathHelpers.ts)
  still require the unchanged old legacy routes, with secureId as the route param.
  
  We won't know which is which ahead of time, but we can tell via the route param name
  we pass to react-router. Then by elimination, we *should* have the other ID in the query param.

  Once secureId is entirely deprecated, we can stop the guessing game,
  and simplify to just using the payrollUuid itself.
*/
const usePayrollUuid = (): string | undefined => {
  const { payroll_id: uuid } = useParams()
  const queryPayrollUuid = useQueryParams('id', undefined)

  return uuid || queryPayrollUuid
}

const useSecureId = (): string | undefined => {
  const { secureId } = useParams()
  const querySecureId = useQueryParams('id', undefined)

  return secureId || querySecureId
}

// See note below about coercing nullables
export const usePayrollParams = (): {
  client: string
  uuid: string
  secureId: string
} => {
  const { client } = useParams<PayrollRouteParams>()
  const uuid = usePayrollUuid()
  const secureId = useSecureId()

  if (!client) throw new Error('Company code not found.')

  // client should always be present in the Payroll domain
  // uuid and secureId can be undefined (e.g. on the payroll list page)
  // as a general pattern, assume the developer will only call in locations
  // where properly defined!

  return {
    client,
    uuid: uuid!,
    secureId: secureId!
  }
}

// same deal as usePayrollParams, act as a coercer
// this is actually accessible via a couple session state props (e.g. useEcBanquetProps),
// but use params for simplicity. can switch all at once in future.
export const useClientParam = (): string => {
  const { client } = useParams<PayrollRouteParams>()
  return client!
}
