import { SpaDate } from '@local/shared-services'
import { PayrollStatus } from '@local/payroll/shared/models/payrollDetail'
import { PayrollStatusV2 } from '../../../gen/queries'
import { previewPath, reviewPath } from '../../../routes/pathHelper'

// See commentary below about the different types of statuses.
// These utility functions are used mainly for legacy reasons (i.e. easier to keep around than refactor).
// For the same, reason must support both Status and StatusV2 because of rare references to old status that are still lingering
const canReset = (status: PayrollStatus | PayrollStatusV2) =>
  typeof status === 'number'
    ? [PayrollStatus.opened].includes(status)
    : [PayrollStatusV2.OPENED].includes(status)

const isPosted = (status: PayrollStatus | PayrollStatusV2) =>
  typeof status === 'number'
    ? [PayrollStatus.posted, PayrollStatus.generated_ach].includes(status)
    : [PayrollStatusV2.POSTED, PayrollStatusV2.GENERATED_ACH].includes(status)

const isCalculating = (status: PayrollStatus | PayrollStatusV2) =>
  typeof status === 'number'
    ? [
        PayrollStatus.scheduled,
        PayrollStatus.scheduling,
        PayrollStatus.processing
      ].includes(status)
    : [
        PayrollStatusV2.SCHEDULED,
        PayrollStatusV2.SCHEDULING,
        PayrollStatusV2.PROCESSING
      ].includes(status)

const isStarted = (status: PayrollStatus | PayrollStatusV2) =>
  typeof status === 'number'
    ? ![PayrollStatus.created, PayrollStatus.unknown].includes(status)
    : ![PayrollStatusV2.CREATED, PayrollStatusV2.UNKNOWN].includes(status)

const isCalculated = (status: PayrollStatus | PayrollStatusV2) =>
  typeof status === 'number'
    ? PayrollStatus.calculated === status
    : PayrollStatusV2.CALCULATED === status

const isScheduled = (status: PayrollStatus | PayrollStatusV2) =>
  typeof status === 'number'
    ? PayrollStatus.scheduled === status
    : PayrollStatusV2.SCHEDULED === status

const isRunningEarly = (
  status: PayrollStatus | PayrollStatusV2,
  checkDate: SpaDate
) =>
  !PayrollStatusHelpers.isStarted(status) &&
  checkDate.isGreater(SpaDate.fromToday().plus(7))

// TODO: Eventually this helper will be applied to the entire Payroll workflow wizard,
// so that you will automatically be filtered to the correct page when visiting the workflow fresh,
// thus avoiding having to fix _every_ entry point into the wizard.
const statusToStepPage = (
  status: PayrollStatusV2,
  { client, uuid, secureId }: { client: string; uuid: string; secureId: string }
): string => {
  switch (status) {
    // The review page itself will handle whether to show a loader or the full review
    case PayrollStatusV2.SCHEDULED:
    case PayrollStatusV2.SCHEDULING:
    case PayrollStatusV2.PROCESSING:
    case PayrollStatusV2.CALCULATED:
      return reviewPath({
        client,
        uuid,
        secureId
      })
    default:
      return previewPath({ client, uuid, secureId })
  }
}

/*
  There are multiple kinds of status right now:
  - PayrollStatus (graph): This one is completely unused. Ignore entirely.
  - number, returned by REST: Used to communicate with esx-web. Oldest known status value.
    Corresponds to actual DB values, and what's explicitly defined in payrollDetails.ts.
    Can mainly be ignored as the endpoints that use it will reference the enum directly,
    and then those endpoints can be gradually removed as they're migrated.
  - number, returned by GQL: Returned as a field in getPayPeriodByUuid and similar.
    Corresponds to https://github.toasttab.com/toasttab/ec-common/blob/main/ec-domain/src/main/kotlin/com/toasttab/ec/enums/PayPeriodStatus.kt
    Unfortunately, maps to the order those enum fields are listed (arguably as a bug).
    Maps to the PayrollStatusOrdinal enum defined above.
  - PayrollStatusV2 (graph): This is returned only by GQL /upcoming. We would PREFER
    to use this one universally, because it's what will continue to be updated longterm.
    However, because of the various other numbers, we must map accordingly as they come in/out.

  The exported mapping functions exist *solely* to help map from that GQL number to PayrollStatusV2, and ONLY
  when going in/out of the GQL Apollo cache and into active code reference. In other words,
  the number value should essentially never be necessary outside of this file, and PayrollStatusV2
  should be the usable type everywhere else in the application.
*/

enum PayrollStatusOrdinal {
  UNKNOWN = 0,
  CREATED = 1,
  OPENED = 2,
  SCHEDULED = 3,
  CALCULATED = 4,
  VERIFIED = 5,
  POSTED = 6,
  GENERATED_ACH = 7,
  STATUS_CHANGES = 8,
  EMPLOYEE_MAINTENANCE = 9,
  IMPORT = 10,
  VACATION = 11,
  PR_MAINTENANCE = 12,
  SPREADSHEET = 13,
  POST_PAYROLL = 14,
  REPORTS = 15,
  SCHEDULING = 16,
  PROCESSING = 17,
  G2N = 18,
  EDITING = 19,
  POSTING = 20
}

const STATUS_NAMES = Object.keys(PayrollStatusV2).filter((i) =>
  Number.isNaN(Number(i))
)

export const statusV2ToStatus = (status: PayrollStatusV2): number =>
  STATUS_NAMES.reduce(
    (prev, curr) => ({
      ...prev,
      // TS abuse because you can use strings as keys, but TS doesn't *want* you doing that
      // (since it's a smelly abuse of converting between compile<->runtime)
      [PayrollStatusV2[curr as keyof typeof PayrollStatusV2]]:
        PayrollStatusOrdinal[curr as keyof typeof PayrollStatusOrdinal]
    }),
    {} as Record<PayrollStatusV2, number>
  )[status] || 0

export const statusToStatusV2 = (status: number): PayrollStatusV2 =>
  STATUS_NAMES.reduce(
    (prev, curr) => ({
      ...prev,
      [PayrollStatusOrdinal[curr as keyof typeof PayrollStatusOrdinal]]:
        PayrollStatusV2[curr as keyof typeof PayrollStatusV2]
    }),
    {} as Record<number, PayrollStatusV2>
  )[status] || PayrollStatusV2.UNKNOWN

export const PayrollStatusHelpers = {
  canReset,
  isRunningEarly,
  isPosted,
  isCalculating,
  isCalculated,
  isStarted,
  isScheduled,
  statusToStepPage,
  statusToStatusV2,
  statusV2ToStatus
}
