import * as yup from 'yup'

import { PayCheckDeduction } from '@local/payroll/shared/types/graphDeprecated'

export const EmployeeDeductionFactory = {
  create(obj: unknown): PayCheckDeduction {
    const deduction = yup
      .object({
        id: yup.string().required(),
        deductionCodeId: yup.string().defined(),
        name: yup.string().defined(),
        amount: yup.number().required(),
        isPercentage: yup.boolean().required(),
        isReadOnly: yup.boolean().required(),
        isSimplyInsured: yup.boolean().notRequired()
      })
      .defined()
      .validateSync(obj)

    return deduction
  },
  copy(source: PayCheckDeduction) {
    return this.create(source)
  }
}
