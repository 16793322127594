import { PayrollStatusHelpers } from '@local/payroll/shared/utils/payrollStatus/helpers'
import { BaseStepProps } from '@toasttab/buffet-pui-stepper'
import { useTranslation } from 'react-i18next'
import { usePayrollParams } from '../../hooks/usePayrollParams'
import {
  employeesPath,
  previewPath,
  reviewPath
} from '../../../../routes/pathHelper'
import { usePayPeriod } from '../../hooks/usePayPeriod'
import { PayrollStatusV2 } from '../../../../gen/queries'

export type PayrollStep =
  | 'Preview payroll'
  | 'Employee earnings'
  | 'Review & submit'

interface usePageHeaderProps {
  currentStep?: PayrollStep
}

type StepConfig = {
  steps: Array<StepModel>
}

export type StepModel = {
  title: string
  link?: string
  state: BaseStepProps['state']
  isActive?: boolean
}

type Hook<TParams, TResult> = (params: TParams) => TResult

export const usePageHeader: Hook<usePageHeaderProps, StepConfig> = ({
  currentStep
}) => {
  const { t } = useTranslation('payroll')

  const { client, secureId, uuid } = usePayrollParams()
  const { payPeriod: { status } = {} } = usePayPeriod()

  //if we do not have any current step, we don't want to display anything
  if (currentStep === undefined) {
    return { steps: [] }
  }

  const getStatus = (step: PayrollStep): BaseStepProps['state'] => {
    if (PayrollStatusHelpers.isPosted(status!)) return 'complete'
    if (currentStep === step) return 'active'

    switch (step) {
      case 'Preview payroll':
        return 'complete'
      case 'Employee earnings':
        return currentStep === 'Preview payroll' ? 'inactive' : 'complete'
      case 'Review & submit':
        return 'inactive'
      default:
        return 'error'
    }
  }

  const isNavigationEnabled =
    status === PayrollStatusV2.POSTED ||
    status === PayrollStatusV2.GENERATED_ACH

  return {
    steps: [
      {
        title: t('preflight.stepper'),
        link: isNavigationEnabled
          ? previewPath({
              secureId,
              uuid,
              client
            })
          : undefined,
        state: getStatus('Preview payroll'),
        isActive: currentStep === 'Preview payroll'
      },
      {
        title: t('spreadsheet.stepper'),
        link: isNavigationEnabled
          ? employeesPath({
              secureId,
              uuid,
              client
            })
          : undefined,
        state: getStatus('Employee earnings'),
        isActive: currentStep === 'Employee earnings'
      },
      {
        title: t('review.stepper'),
        link: isNavigationEnabled
          ? reviewPath({
              secureId,
              uuid,
              client
            })
          : undefined,
        isActive: currentStep === 'Review & submit',
        state: getStatus('Review & submit')
      }
    ]
  }
}
