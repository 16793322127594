import { SpaDateTime, SpaDate } from '@local/shared-services'
import { PayPeriod, PayrollStatusV2 } from '@local/payroll/gen/queries'

type PayPeriodWithStatusV2 = PayPeriod & {
  status: PayrollStatusV2
}

export const PayPeriodFactory = {
  create(obj: any): PayPeriodWithStatusV2 {
    return {
      ...obj,
      finalCheckDate: obj.checkDateOverride || obj.checkDate
    }
  },

  copy(obj: Omit<PayPeriod, 'finalCheckDate'>) {
    return this.create(obj)
  },
  deserialize(payload: any): PayPeriodWithStatusV2 {
    /*
      We only need to map the properties that need to be modified in some way on the way in. Otherwise we can use take them verbatim with `...payload`
    */
    return PayPeriodFactory.copy({
      ...payload,
      // See PayPeriod type for types of individual fields (e.g. which are optional)
      // Date vs. DateTime will vary based on functional behavior of the field, as all are stored in DB as datetime,
      // but some are functionally just dates.
      startDate: SpaDate.fromString(payload.startDate),
      endDate: SpaDate.fromString(payload.endDate),
      checkDate: SpaDate.fromString(payload.checkDate),
      checkDateOverride: SpaDate.fromStringNullable(payload.checkDateOverride),
      taxDebitDate: SpaDate.fromStringNullable(payload.taxDebitDate),
      datePosted: SpaDate.fromStringNullable(payload.datePosted),
      isReadOnly: payload.isReadOnly ?? false,
      debitDate: SpaDate.fromString(payload.debitDate),
      dueDate: SpaDateTime.fromStringNullable(payload.dueDate),
      // TODO: This should be used very rarely, if at all. Use dueDate and postingDeadline instead wherever possible.
      // Remove this once payrollDate has been fully eliminated from references in the SPA
      payrollDate: SpaDateTime.fromStringNullable(payload.payrollDate)
    })
  }
}
