import {
  GetPayPeriodByUuidQuery,
  GetPayPeriodByUuidQueryVariables,
  PayrollStatusV2
} from '@local/payroll/gen/queries'
import { usePayrollParams } from './usePayrollParams'
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { SpaDate, SpaDateTime } from '@local/shared-services'
import {
  statusToStatusV2,
  statusV2ToStatus
} from '../../../shared/utils/payrollStatus/helpers'

const PAYPERIOD_QUERY = gql`
  query GetPayPeriodByUuid($payPeriodUuid: ID!) {
    payperiod(payPeriodUuid: $payPeriodUuid) {
      uuid
      name
      status
      startDate
      endDate
      checkDate
      checkDateOverride
      checkCode {
        value
        label
      }
      forceLiveChecks
      taxDebitDate
      dueDate
      postDeadlineText
      datePosted
      isReadOnly
      debitDate
      finalCheckDate
      deliveryMethod
      # This loads an opaque secureID, have to keep loading for now
      adjustmentUrl
      # We don't need the URL itself, just the presence of this field
      # which indicates whether or not to show the Aloha URL
      timesheetsImportUrlAloha
      checkCodeUuid
      checkCodeName
      previousPayPeriodUuid
      previousPayPeriodStartDate
      previousPayPeriodEndDate
      payGroupUuids
      isManual
      previousProviderPayrollType
    }
  }
`

type PayPeriodWithStatusV2 = Omit<
  GetPayPeriodByUuidQuery['payperiod'],
  'status'
> & {
  status: PayrollStatusV2
}

const migratePayPeriod = (
  payroll: GetPayPeriodByUuidQuery['payperiod']
): PayPeriodWithStatusV2 => ({
  // Current PayPeriod query unfortunately makes ambiguous several of these types due to legacy GQL infra.
  // See PayPeriodFactory for more info on what (historically, at least) was expected.
  // Expect a PayPeriod type refactor in the graph as well at some point.
  ...payroll,
  status: statusToStatusV2(payroll.status),
  // Date vs. DateTime will vary based on functional behavior of the field, as all are stored in DB as datetime,
  // but some are functionally just dates.
  startDate: SpaDate.fromString(payroll.startDate),
  endDate: SpaDate.fromString(payroll.endDate),
  checkDate: SpaDate.fromString(payroll.checkDate),
  checkDateOverride: SpaDate.fromStringNullable(payroll.checkDateOverride),
  taxDebitDate: SpaDate.fromStringNullable(payroll.taxDebitDate),
  datePosted: SpaDate.fromStringNullable(payroll.datePosted),
  // inconsistent between false, null, undefined -- make an actual boolean
  isReadOnly: payroll.isReadOnly ?? false,
  debitDate: SpaDate.fromString(payroll.debitDate),
  dueDate: SpaDateTime.fromStringNullable(payroll.dueDate),
  finalCheckDate: SpaDate.fromStringNullable(payroll.finalCheckDate)
})

export const usePayPeriod = () => {
  const { uuid } = usePayrollParams()
  const client = useApolloClient()

  const {
    data,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling,
    networkStatus
  } = useQuery<GetPayPeriodByUuidQuery, GetPayPeriodByUuidQueryVariables>(
    PAYPERIOD_QUERY,
    {
      variables: {
        payPeriodUuid: uuid!
      },
      notifyOnNetworkStatusChange: true
    }
  )

  return {
    payPeriod: data ? migratePayPeriod(data.payperiod) : undefined,
    loading,
    error,
    refetch,
    networkStatus,
    startPolling,
    stopPolling,
    /*
      TODO: These functions are provided for legacy reasons. They are VERY
      unsafe, because of how easy it is for local state to get out of state
      with the actual server state. To make them work, we have to carefully
      make sure to trigger refetches shortly after the statuses change, either by a
      manual refetch or a page reload.

      We can replace these with proper auto-refetching protocols once GQL mutations
      are added to replace the existing POST endpoints that affect status/checkdate
      (mainly status, which all of the payroll operations ultimately affect).
    */
    setStatus: (status: PayrollStatusV2) => {
      client.cache.updateQuery(
        { query: PAYPERIOD_QUERY, variables: { payPeriodUuid: uuid } },
        (data) => ({
          payperiod: {
            ...data.payperiod,
            status: statusV2ToStatus(status)
          }
        })
      )
    },
    setFinalCheckDate: (finalCheckDate: SpaDate) => {
      client.cache.updateQuery(
        { query: PAYPERIOD_QUERY, variables: { payPeriodUuid: uuid } },
        (data) => ({
          payperiod: {
            ...data?.payperiod,
            finalCheckDate: finalCheckDate.toString()
          }
        })
      )
    },
    setIsReadOnly: (isReadOnly: boolean) => {
      client.cache.updateQuery(
        { query: PAYPERIOD_QUERY, variables: { payPeriodUuid: uuid } },
        (data) => ({
          payperiod: {
            ...data?.payperiod,
            isReadOnly
          }
        })
      )
    }
  }
}
