import * as React from 'react'
import { usePayrollParams } from '../experience/details/hooks/usePayrollParams'
import { PageLoader } from '../shared/components/pageLoader'
import { Page } from '../shared/components/layout'
import { ResetPayrollModal } from '../experience/details/ResetPayrollModal'
import { Outlet } from 'react-router-dom'
import { usePayPeriod } from '../experience/details/hooks/usePayPeriod'

// Less of a provider and more of a guard + layout, since Apollo queries depend on
// validating there is a payroll UUID in the params. (Provider is mainly the choice
// of name for legacy reasons, when we used to have an actual provider.) In the future,
// this element might add utility functions which could be an actual provider.
export const PayPeriodProvider = () => {
  const { uuid } = usePayrollParams()

  if (!uuid) throw new Error('Payroll UUID not found.')

  return <PayPeriodContent />
}

// Split as a separate component to avoid a conditional hook,
// since usePayPeriod hard errors without a uuid
const PayPeriodContent = () => {
  const { payPeriod } = usePayPeriod()

  // TODO: Apollo has a more native way of interfacing with Suspense
  // https://www.apollographql.com/docs/react/data/suspense
  // However, fully integrating requires some refactoring for usePayPeriod.
  // Using Suspense in the future makes the page slightly more performant, but
  // this simpler approach is sufficient for now
  // XXX do we want a loading state during refetches?
  if (!payPeriod) return <PageLoader />

  return (
    <React.Suspense fallback={<PageLoader />}>
      <Page>
        <ResetPayrollModal />
        <Outlet />
      </Page>
    </React.Suspense>
  )
}
