import * as faker from 'faker'
import { SpaDate, SpaDateTime } from '@local/shared-services'
import {
  AuditEmployee,
  PaperCheckAudit,
  AuditUser,
  LabelledValue
} from '@local/payroll/experience/details/models'
import { PayrollStatus } from '@local/payroll/shared/models/payrollDetail'
import { PayrollEmployeeSerializer } from '@local/payroll/experience/details/resources'
import { PayPeriodFactory } from '@local/payroll/shared/models/factories/PayPeriodFactory'
import { CheckCodeFactory } from '@local/payroll/shared/models/factories'
import {
  CheckPackage,
  DeliveryMethod,
  EnumAmountType,
  Message,
  MessageActionType,
  PayPeriod,
  PostDeadline,
  PreviousProviderPayrollType
} from '@local/payroll/gen/queries'

export const createEmployee = (job: string | null, location: string | null) => {
  return PayrollEmployeeSerializer.deserialize({
    id: '10',
    name: 'Matthew Lee',
    employeeNumber: '10',
    employeeId: 'employeeId',
    employeeUuid: 'employeeUuid',
    payperiodUuid: 'payperiodUuid',
    paystubs: [
      {
        id: '0',
        number: 1,
        paidByCheck: false,
        deductions: [],
        isManual: false,
        isVoid: false,
        isReadOnly: false,
        earnings: [
          {
            id: '0',
            uuid: '1234',
            name: '',
            rate: 0,
            hours: 0,
            baseRate: 10,
            location: location,
            job: job,
            checkCode: { label: '', value: '' },
            earningCode: { label: '', value: '', isHourly: true, isOT: false },
            isFirstWeek: true,
            amount: 0,
            paystub: 1
          }
        ],
        checkCode: LabelledValue.of('value', 'label')
      }
    ]
  })
}

export const updatePayrollDeliverySettings = (
  payroll: PayPeriod,
  isPayOwnWay: boolean
) => {
  return Object.assign({}, payroll, {
    printPaperChecks: !isPayOwnWay,
    payOwnWay: isPayOwnWay
  })
}

export const createPayroll = (client: string, payroll_id: string) => {
  const startDate = SpaDate.fromToday()
  const endDate = startDate.plus(7)
  const checkDate = endDate.plus(4)
  const checkDateOverride = checkDate.plus(2)
  const postDeadline = 1
  const postDeadlineText = PostDeadline.OneDayBefore
  const dueDate = endDate.minus(1)
  const taxDebitDate = endDate.plus(2)
  const datePosted = endDate.plus(3)
  const debitDate = endDate.plus(4)
  const previousPayPeriodStartDate = startDate.minus(7)
  const previousPayPeriodEndDate = endDate.minus(7)

  const checkCode = CheckCodeFactory.copy({ label: 'Bonus', value: '1' })

  const uuid = faker.random.uuid()
  return PayPeriodFactory.copy({
    uuid,
    name: 'Toast Payroll 1',
    status: PayrollStatus.opened,
    startDate,
    endDate,
    checkDate,
    checkDateOverride,
    dueDate,
    postDeadline,
    postDeadlineText,
    adjustmentUrl: `/${client}/payroll/employees/spreadsheet/${payroll_id}`,
    dashboardUrl: `/${client}/payroll/details/preview/${payroll_id}`,
    timesheetsImportUrlAloha: `/mvc/${client}/company/payroll/alohalaborreport/${payroll_id}`,
    checkCode: checkCode,
    forceLiveChecks: false,
    taxDebitDate,
    datePosted,
    isReadOnly: false,
    debitDate,
    deliveryMethod: DeliveryMethod.ToastPrinted,
    toastPrintedChecks: {
      totalChecks: 4,
      totalEmployees: 2
    },
    checkPackages: [createCheckPackages(), createCheckPackages()],
    checkCodeName: checkCode.label,
    checkCodeUuid: checkCode.value,
    messages: [createMessage()],
    // TODO: payroll date is an effectively unused value, it should be ignored
    payrollDate: SpaDateTime.fromPostingDeadline(),
    previousPayPeriodUuid: faker.random.uuid(),
    previousPayPeriodStartDate,
    previousPayPeriodEndDate,
    previousProviderPayrollType: PreviousProviderPayrollType.none
  })
}

export const createPaperChecksAudit = () => {
  return [
    PaperCheckAudit.copy({
      id: faker.random.number() + '',
      createdAt: SpaDateTime.fromRecent(),
      user: AuditUser.of(faker.random.uuid(), faker.internet.userName()),
      employee: AuditEmployee.copy({
        uuid: faker.random.uuid(),
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        employeeNumber: faker.random.number() + ''
      })
    })
  ]
}

export const createCheckPackages = (): CheckPackage => {
  return {
    shippingAddress: {
      shippingAddressUuid: faker.random.uuid(),
      recipientCompany: faker.company.companyName(),
      attention: faker.name.jobTitle(),
      addressLine1: faker.address.streetAddress(),
      addressLine2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      stateName: faker.address.state(),
      state: faker.address.stateAbbr(),
      zipCode: faker.address.zipCode(),
      trackingNumber: faker.random.alphaNumeric()
    },
    workTaxLocations: [
      {
        name: faker.name.jobArea(),
        customerUuid: faker.random.uuid(),
        shippingAddressUuid: faker.random.uuid()
      },
      {
        name: faker.name.jobArea(),
        customerUuid: faker.random.uuid(),
        shippingAddressUuid: faker.random.uuid()
      }
    ],
    numberOfChecks: faker.random.number()
  }
}

export const createMessage = (): Message => {
  const actions = [
    MessageActionType.approved,
    MessageActionType.recorded,
    MessageActionType.scheduled
  ]

  const amountTypes = [
    EnumAmountType.Currency,
    EnumAmountType.Percent,
    EnumAmountType.Value
  ]

  return {
    name: faker.name.title(),
    action: actions[faker.random.number(3)],
    amount: faker.random.number(),
    amountType: amountTypes[faker.random.number(3)],
    whom: `${faker.name.firstName} ${faker.name.lastName}`,
    author: `${faker.name.firstName} ${faker.name.lastName}`,
    datePosted: SpaDateTime.fromNow()
  }
}
