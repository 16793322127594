export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: any
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: any
  /** A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999". */
  Decimal: any
  HashId: any
  LegacyId: any
  Long: any
  SpaDate: any
  SpaDateTime: any
  join__FieldSet: any
  link__Import: any
}

export type AchConsent = {
  __typename?: 'ACHConsent'
  employee: Maybe<BankConsentEmployee>
  needsConsent: Scalars['Boolean']
  payPeriodUuid: Scalars['ID']
  timestamp: Scalars['DateTime']
}

export type AbsentPayChangeLogTokenError = {
  __typename?: 'AbsentPayChangeLogTokenError'
  message: Maybe<Scalars['String']>
}

export type AccessibleCustomer = {
  __typename?: 'AccessibleCustomer'
  companyCode: Scalars['String']
  name: Scalars['String']
  shardName: Scalars['String']
}

export type AccessibleCustomersInput = {
  cursor: InputMaybe<Scalars['ID']>
  pageSize: InputMaybe<Scalars['Int']>
  search: InputMaybe<Scalars['String']>
}

export type AccessibleCustomersResult = {
  __typename?: 'AccessibleCustomersResult'
  customers: Array<AccessibleCustomer>
  nextCursor: Maybe<Scalars['ID']>
}

export type ActiveEmploymentStatus = EmploymentStatus & {
  __typename?: 'ActiveEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type Actor = {
  __typename?: 'Actor'
  /** The ID of the actor */
  id: Scalars['ID']
  /** The actor's primary job name */
  jobName: Maybe<Scalars['String']>
  /** The name of the actor */
  name: Maybe<PersonName>
}

export type ActorPermissionsForEmployee = {
  __typename?: 'ActorPermissionsForEmployee'
  employeeId: Scalars['ID']
  permissions: Array<HumanResourcesPermission>
}

export type AdditionalDocumentInput = {
  fileName: Scalars['String']
  filePath: Scalars['String']
}

export type AdditionalEarningResponse =
  | EarningListEmptyError
  | EmployeeNotFoundError
  | JobAssignmentNotFoundError
  | MissingPayGroupError
  | NextPayPeriodNotFoundError
  | NextPaycheckDateNotFoundError
  | Paycheck

export type AggregateInvoiceResult =
  | AggregatePayrollInvoiceError
  | AggregatePayrollInvoiceSuccess

export type AggregatePayrollInvoice = {
  __typename?: 'AggregatePayrollInvoice'
  caption: Scalars['String']
  invoiceTypeId: Scalars['Int']
  totalCharge: Scalars['Decimal']
  totalEmployeeCount: Scalars['Int']
}

export type AggregatePayrollInvoiceError = QueryError & {
  __typename?: 'AggregatePayrollInvoiceError'
  errors: Array<Error>
  status: ResponseStatus
}

export type AggregatePayrollInvoiceSuccess = QuerySuccess & {
  __typename?: 'AggregatePayrollInvoiceSuccess'
  invoices: Array<AggregatePayrollInvoice>
  status: ResponseStatus
}

export type AllEmploymentStatuses =
  | ActiveEmploymentStatus
  | ApplicantEmploymentStatus
  | DeletedEmploymentStatus
  | DemoEmploymentStatus
  | ImplementationEmploymentStatus
  | LeaveOfAbsenceEmploymentStatus
  | NeverEmployedEmploymentStatus
  | PendingEmploymentStatus
  | RehiredEmploymentStatus
  | SeasonalEmploymentStatus
  | TerminatedEmploymentStatus

export type AmountWithPayInterval = {
  __typename?: 'AmountWithPayInterval'
  amount: Money
  interval: PayInterval
}

export type ApplicantEmploymentStatus = EmploymentStatus & {
  __typename?: 'ApplicantEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type ArchiveError = MutationError & {
  __typename?: 'ArchiveError'
  errors: Array<Error>
  status: ResponseStatus
}

export type ArchiveResponse = ArchiveError | ArchiveSuccess

export type ArchiveSuccess = MutationSuccess & {
  __typename?: 'ArchiveSuccess'
  result: Scalars['ID']
  status: ResponseStatus
}

export enum Assignee {
  CUSTOMER = 'CUSTOMER',
  ONBOARDING = 'ONBOARDING'
}

export type Audit = {
  __typename?: 'Audit'
  action: AuditAction
  date: Scalars['SpaDateTime']
  postponedDate: Maybe<Scalars['String']>
  whom: Scalars['String']
}

export enum AuditAction {
  Checks = 'Checks',
  Opened = 'Opened',
  Posted = 'Posted',
  Postponed = 'Postponed',
  Reset = 'Reset'
}

export type AuditUser = {
  __typename?: 'AuditUser'
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName: Maybe<Scalars['String']>
  username: Scalars['String']
}

export type AvailabilityRequest = {
  email: InputMaybe<Scalars['String']>
  employeeId: InputMaybe<Scalars['ID']>
  employeeNumber: InputMaybe<Scalars['Int']>
  posAccessCode: InputMaybe<Scalars['Int']>
  ssn: InputMaybe<Scalars['String']>
}

export type AvailabilityResponse = {
  __typename?: 'AvailabilityResponse'
  email: Maybe<Scalars['Boolean']>
  emailTakenBy: Maybe<EmploymentStatusType>
  employeeNumber: Maybe<Scalars['Boolean']>
  posAccessCode: Maybe<Scalars['Boolean']>
  ssn: Maybe<Scalars['Boolean']>
}

export type BankAccount = {
  __typename?: 'BankAccount'
  lastFour: Scalars['String']
  name: Scalars['String']
  signatureFileName: Maybe<Scalars['String']>
  uuid: Scalars['String']
}

export type BankAccountConsentInfo = {
  __typename?: 'BankAccountConsentInfo'
  bankAccountUuid: Scalars['ID']
  bankName: Scalars['String']
  consents: Maybe<Array<AchConsent>>
  hasSignature: Scalars['Boolean']
  lastFourAccountNumber: Maybe<Scalars['String']>
  payGroupUuid: Scalars['ID']
}

export type BankConsentEmployee = {
  __typename?: 'BankConsentEmployee'
  employeeNumber: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
}

export type Benefit = {
  __typename?: 'Benefit'
  key: Scalars['String']
  label: Scalars['String']
  options: Array<BenefitOption>
}

export type BenefitOption = {
  __typename?: 'BenefitOption'
  label: Scalars['String']
  value: Scalars['ID']
}

export type Benefits = {
  __typename?: 'Benefits'
  acaPlan: Maybe<Scalars['String']>
  benefitPlan: Maybe<Scalars['String']>
  tafwPolicy: Maybe<Scalars['String']>
  washingtonLaI: Maybe<Scalars['String']>
  workersComp: Maybe<Scalars['String']>
}

export type BenefitsInput = {
  acaPlan: InputMaybe<Scalars['String']>
  benefitPlan: InputMaybe<Scalars['String']>
  tafwPolicy: InputMaybe<Scalars['String']>
  washingtonLaI: InputMaybe<Scalars['String']>
  workersComp: InputMaybe<Scalars['String']>
}

export type BenefitsThirdPartyDataSharingConsent = {
  __typename?: 'BenefitsThirdPartyDataSharingConsent'
  version: Scalars['Float']
}

export type BringBackResponse = ResponseError | ResponseSuccess

export type BulkAddEmployeeResponse = {
  __typename?: 'BulkAddEmployeeResponse'
  status: Scalars['String']
  uuid: Scalars['String']
}

export type BulkAddResponse = {
  __typename?: 'BulkAddResponse'
  employees: Maybe<Array<BulkAddEmployeeResponse>>
}

export type BulkDeductionMutation = {
  amount: Scalars['Float']
  deductionCodeUuid: Scalars['String']
  employeeUuids: InputMaybe<Array<Scalars['String']>>
  isPercentage: Scalars['Boolean']
}

export type BulkEarningMutation = {
  amount: Scalars['Float']
  checkCodeUuid: Scalars['String']
  earningCodeUuid: Scalars['String']
  employeeUuids: Array<Scalars['String']>
  hours: Scalars['Float']
  week: Scalars['Int']
}

export type BulkNotifyResponse = {
  __typename?: 'BulkNotifyResponse'
  employee: Scalars['ID']
  status: DirectDepositNotificationStatus
}

export type CannotChangeLinkedUsername = {
  __typename?: 'CannotChangeLinkedUsername'
  userId: Scalars['ID']
}

export type CannotChangeUnlinkedUsername = {
  __typename?: 'CannotChangeUnlinkedUsername'
  userId: Scalars['ID']
}

export type CashRequirement = {
  __typename?: 'CashRequirement'
  totalEERequirement: EmployeeRequirement
  totalTaxes: Array<PayCheckTaxes>
  vendorNetPays: Array<VendorNetPays>
}

export type ChangeLinkedUsernameResult =
  | CannotChangeUnlinkedUsername
  | InvalidUsername
  | PayrollUser
  | ToastVersionIgnored
  | UserNotFound
  | UsernameExists

export type ChangePayInput = {
  effectiveDate: Scalars['Date']
  rate: MoneyInput
}

export type ChangeSalaryAllocationInput = {
  /** Date the new rate should be applied. If more than 1 SalaryAllocation is being updated, effectiveDate must be today's date */
  effectiveDate: Scalars['Date']
  /** Id of the salary allocation */
  id: Scalars['ID']
  /** New annual rate for this salary allocation */
  newRate: MoneyInput
}

export type ChangeSalaryResponse =
  | EmployeeNotFoundError
  | EmptySalaryAllocationsError
  | InvalidEffectiveDateError
  | Salary
  | SalaryAllocationNotFoundError
  | SalaryNotFoundError

export type ChangeUnlinkedUsernameResult =
  | CannotChangeLinkedUsername
  | InvalidUsername
  | PayrollUser
  | UserNotFound
  | UsernameExists

export type CheckCode = {
  __typename?: 'CheckCode'
  label: Scalars['String']
  value: Scalars['String']
}

export type CheckCodesConfigQuerySuccess = QuerySuccess & {
  __typename?: 'CheckCodesConfigQuerySuccess'
  checkCodes: Array<CheckCode>
  status: ResponseStatus
}

export type CheckCodesConfigResponse =
  | CheckCodesConfigQuerySuccess
  | QueryErrorResponse

export type CheckPackage = {
  __typename?: 'CheckPackage'
  numberOfChecks: Scalars['Int']
  shippingAddress: ShippingAddress
  workTaxLocations: Array<WorkTaxLocation>
}

export type ChecklistState = {
  __typename?: 'ChecklistState'
  customerUuid: Scalars['ID']
  items: Array<Item>
}

export type CompanyProfile = {
  __typename?: 'CompanyProfile'
  allowedToPost: Scalars['Boolean']
  customerStatus: CustomerStatus
  feins: Array<CustomerFein>
  firstPayrollDate: Scalars['SpaDate']
  numActiveEmployees: Scalars['Int']
  numFeins: Scalars['Int']
  numPayGroups: Scalars['Int']
  numRestaurants: Scalars['Int']
}

export type CompanySummary = {
  __typename?: 'CompanySummary'
  companyProfile: CompanyProfile
  integrationSettings: IntegrationSettings
  payGroups: Array<PayGroupSetting>
  payrollSettings: PayrollSettings
}

export type CompleteDirectDepositEmployee = {
  __typename?: 'CompleteDirectDepositEmployee'
  allocations: Array<DirectDepositAllocation>
  email: Scalars['String']
  id: Scalars['ID']
  name: PersonName
}

export type CompleteDirectDepositsResponse = {
  __typename?: 'CompleteDirectDepositsResponse'
  employees: Array<CompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  totalCount: Scalars['Int']
}

export type ConfirmVerificationEmailError =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type ConfirmVerificationEmailInput = {
  confirmationKey: Scalars['String']
}

export type ConfirmVerificationEmailResult =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailConfirmed
  | VerificationEmailNotSent

export type ConfirmationExpired = {
  __typename?: 'ConfirmationExpired'
  message: Maybe<Scalars['String']>
}

export type ConfirmedEvent = {
  __typename?: 'ConfirmedEvent'
  emailHash: Scalars['String']
  eventTime: Scalars['DateTime']
  sequence: Scalars['Int']
  toastIdentityGuid: Scalars['String']
}

export enum Connection {
  ALTERNATE = 'ALTERNATE',
  DEFAULT = 'DEFAULT'
}

export type ContactTelephoneNumber = {
  __typename?: 'ContactTelephoneNumber'
  isMobile: Scalars['Boolean']
  telephoneNumber: Scalars['String']
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']
  displayName: Scalars['String']
  isoCode: Scalars['String']
}

export type CreateJobTagAssignmentInput = {
  createdByUserGuid: Scalars['ID']
  jobTagId: Scalars['ID']
  managementSetId: Scalars['ID']
  restaurantId: Scalars['ID']
  restaurantJobId: Scalars['ID']
  selectedJobTagValue: Scalars['String']
}

export type CreateJobTagInput = {
  createdByUserGuid: Scalars['ID']
  name: Scalars['String']
  values: Array<Scalars['String']>
}

/** An `ISO-4217` compliant Currency. */
export enum Currency {
  /** Canadian Dollars (CAD). */
  CAD = 'CAD',
  /** Euro (EUR). */
  EUR = 'EUR',
  /** United Kingdom Pounds (GBP). */
  GBP = 'GBP',
  /** United States Dollars (USD). */
  USD = 'USD'
}

export type CustomDocumentFieldInput = {
  fieldId: Scalars['Int']
  id: Scalars['ID']
  value: Scalars['String']
}

export type CustomDocumentFields = {
  __typename?: 'CustomDocumentFields'
  documentId: Scalars['ID']
  fieldId: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
}

export type CustomDocumentFieldsV2 = {
  __typename?: 'CustomDocumentFieldsV2'
  documentIds: Array<Scalars['ID']>
  fieldId: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
}

export type Customer = {
  __typename?: 'Customer'
  customerId: Scalars['String']
  /** application features as defined in ToastWeb, related to entitlements */
  enabledFeatures: Array<Scalars['String']>
  feins: Array<PayrollFein>
  id: Scalars['ID']
  locations: Array<Location>
  name: Scalars['String']
  status: CustomerStatus
  /** @deprecated Field no longer supported */
  uuid: Scalars['ID']
}

export type CustomerConfiguration = {
  __typename?: 'CustomerConfiguration'
  customerPositions: Array<CustomerPosition>
  defaultEarningsIds: Maybe<DefaultEarningsId>
  /** customerUuid */
  id: Scalars['ID']
  locations: Array<PayrollLocation>
  payGroups: Array<PayGroupWithFrequency>
  payGroupsV2: Array<PayGroup>
  payrollSecurityRoles: Array<PayrollSecurityRole>
  workTaxLocations: Array<PayrollWorkTaxLocation>
}

export type CustomerFein = {
  __typename?: 'CustomerFein'
  dayOfWeek: Scalars['String']
  isActive: Scalars['Boolean']
  isPayrollPayor: IsPayrollPayor
  name: Scalars['String']
  taxFilingEndDate: Maybe<Scalars['SpaDate']>
  taxFilingStartDate: Maybe<Scalars['SpaDate']>
  tin: Scalars['String']
  uuid: Scalars['String']
}

export type CustomerInput = {
  locations: Array<LocationInput>
  uuid: Scalars['ID']
}

export type CustomerPosition = {
  __typename?: 'CustomerPosition'
  checkCode: PayrollCheckCode
  fein: PayrollFein
  id: Scalars['ID']
  isExempt: Scalars['Boolean']
  name: Scalars['String']
  payGroup: PayGroup
  salaryType: Scalars['String']
}

export type CustomerSetting = {
  __typename?: 'CustomerSetting'
  id: Scalars['Int']
  value: Scalars['String']
}

export type CustomerSettings = {
  __typename?: 'CustomerSettings'
  BLENDED_OT: Scalars['Boolean']
  CHECK_PRINTING_PAPER_CHECKS_TYPE: Scalars['Int']
  CHECK_PRINTING_SUFFIX: Scalars['String']
  CREDIT_CARD_TIPS_EARNING_CODE: Scalars['Int']
  DEADLINE_ENFORCEMENT: Scalars['Boolean']
  DECLARED_EARNINGS_TIPS_EARNINGCODE: Scalars['Int']
  EEO_ENABLED_IN_NEW_HIRE_ONBOARDING: Scalars['Boolean']
  ENFORCE_TWO_FACTOR_AUTHENTICATION: Scalars['String']
  HAS_PREVIOUS_PAYROLL_PROVIDER: Scalars['Boolean']
  PAYROLL_POST_DEADLINE: Scalars['Int']
  SECONDARY_RATES_CODE_LEVEL: Scalars['Int']
  SECONDARY_RATES_CONSIDER_UNIQUE_LEVELS_SEQUENCE: Scalars['Boolean']
  SECONDARY_RATES_PER_LEVEL: Scalars['Boolean']
  SINGLE_SIGN_ON: Scalars['Boolean']
  TIP_POOLING_CASH_EARNING_CODE: Scalars['Int']
  TIP_POOLING_CASH_GRATUITY_EARNING_CODE: Scalars['Int']
  TIP_POOLING_NON_CASH_EARNING_CODE: Scalars['Int']
  TIP_POOLING_NON_CASH_GRATUITY_EARNING_CODE: Scalars['Int']
}

export enum CustomerStatus {
  active = 'active',
  conversion = 'conversion',
  demo_company = 'demo_company',
  inactive = 'inactive',
  new_client_clean_quarter = 'new_client_clean_quarter',
  new_client_mid_quarter = 'new_client_mid_quarter',
  none = 'none',
  prospect = 'prospect',
  suspect = 'suspect',
  unverified = 'unverified'
}

export type CustomerW2Settings = {
  __typename?: 'CustomerW2Settings'
  feins: Array<W2Fein>
  printingStatus: W2PrintingStatus
  /** shippingAddress may be null if the current printing status is SELF_PRINTED */
  shippingAddress: Maybe<W2ShippingAddress>
  toastPrintedCost: Scalars['Float']
  /** Total number of feins containing employees with W2s, feins field will include those you have access to */
  totalFeins: Scalars['Int']
}

export type DataExceptionError = {
  __typename?: 'DataExceptionError'
  message: Maybe<Scalars['String']>
}

export type DateRange = {
  __typename?: 'DateRange'
  endDate: Maybe<Scalars['Date']>
  startDate: Scalars['Date']
}

export type DefaultEarningsId = {
  __typename?: 'DefaultEarningsId'
  contractorHourly: Maybe<Scalars['ID']>
  contractorSalary: Maybe<Scalars['ID']>
  hourly: Scalars['ID']
  salary: Scalars['ID']
}

export type DeletedEmploymentStatus = EmploymentStatus & {
  __typename?: 'DeletedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export enum DeliveryMethod {
  Invalid = 'Invalid',
  NotRecorded = 'NotRecorded',
  PayYourOwnWay = 'PayYourOwnWay',
  SelfPrinted = 'SelfPrinted',
  ToastPrinted = 'ToastPrinted'
}

export type DemoEmploymentStatus = EmploymentStatus & {
  __typename?: 'DemoEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export enum DepositAccountType {
  CHECKING = 'CHECKING',
  MONEYMARKET = 'MONEYMARKET',
  PAYCARD = 'PAYCARD',
  SAVING = 'SAVING'
}

export type DirectDepositAllocation = {
  __typename?: 'DirectDepositAllocation'
  amount: Scalars['Float']
  depositAccountType: DepositAccountType
  sequence: Scalars['Int']
  splitDepositType: SplitDepositType
}

export type DirectDepositBulkEmailResponse = {
  __typename?: 'DirectDepositBulkEmailResponse'
  id: Scalars['ID']
  status: DirectDepositNotificationStatus
}

export type DirectDepositNotification = {
  __typename?: 'DirectDepositNotification'
  email: Maybe<Scalars['String']>
  lastContactedUtc: Maybe<Scalars['DateTime']>
  status: Maybe<DirectDepositNotificationStatus>
}

export enum DirectDepositNotificationStatus {
  FAILURE_TO_SEND = 'FAILURE_TO_SEND',
  SUCCESSFULLY_SENT = 'SUCCESSFULLY_SENT'
}

export type DirectDepositPaginationInput = {
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export enum DisabilityStatus {
  DOES_NOT_WISH_TO_ANSWER = 'DOES_NOT_WISH_TO_ANSWER',
  NO = 'NO',
  YES = 'YES'
}

export type DriversLicense = {
  __typename?: 'DriversLicense'
  expirationDate: Scalars['Date']
  number: Scalars['String']
  state: StateInUnitedStates
}

export type DuplicateRequest = {
  __typename?: 'DuplicateRequest'
  message: Maybe<Scalars['String']>
}

export type EarningCode = {
  __typename?: 'EarningCode'
  isHourly: Scalars['Boolean']
  isOT: Scalars['Boolean']
  label: Scalars['String']
  value: Scalars['String']
}

export type EarningListEmptyError = {
  __typename?: 'EarningListEmptyError'
  message: Maybe<Scalars['String']>
}

export type EcPayrollResponse = ResponseError | ResponseSuccess

export type EeoClassification = {
  __typename?: 'EeoClassification'
  id: Scalars['Int']
  name: Scalars['String']
}

export type EeoClassificationInput = {
  id: Scalars['Int']
  name: Scalars['String']
}

export type Ein = {
  __typename?: 'Ein'
  effectiveDate: Scalars['Date']
  ein: Scalars['String']
  frequency: Maybe<EinPaymentFrequency>
  id: Scalars['ID']
  sutaRates: Maybe<Array<SutaRate>>
  taxName: Scalars['String']
  type: Scalars['String']
}

export type EinPaymentFrequency = {
  __typename?: 'EinPaymentFrequency'
  name: Scalars['String']
}

export type EinState = {
  __typename?: 'EinState'
  code: Scalars['String']
  eins: Array<Ein>
  id: Scalars['Int']
  name: Scalars['String']
}

export type EmailAlreadyVerified = {
  __typename?: 'EmailAlreadyVerified'
  message: Maybe<Scalars['String']>
}

export type EmailChangedEvent = {
  __typename?: 'EmailChangedEvent'
  emailHash: Scalars['String']
  eventTime: Scalars['DateTime']
  sequence: Scalars['Int']
  toastIdentityGuid: Scalars['String']
}

export type EmailHasBeenUpdated = {
  __typename?: 'EmailHasBeenUpdated'
  message: Maybe<Scalars['String']>
}

export type EmailInvalidError = {
  __typename?: 'EmailInvalidError'
  message: Maybe<Scalars['String']>
}

export type EmailNotFound = {
  __typename?: 'EmailNotFound'
  message: Maybe<Scalars['String']>
}

export type EmailSendingError = {
  __typename?: 'EmailSendingError'
  message: Maybe<Scalars['String']>
}

export type EmailVerification = {
  __typename?: 'EmailVerification'
  isVerified: Maybe<Scalars['Boolean']>
  toastIdentityGuid: Scalars['ID']
  verifiedTime: Maybe<Scalars['DateTime']>
}

export type EmailVerificationError =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type EmailVerificationEvent =
  | ConfirmedEvent
  | EmailChangedEvent
  | EmailVerificationFailed
  | VerificationEmailSentEvent

export type EmailVerificationEventsInput = {
  toastIdentityGuid: Scalars['String']
}

export type EmailVerificationFailed = {
  __typename?: 'EmailVerificationFailed'
  message: Maybe<Scalars['String']>
}

export type EmailVerificationMutation = {
  __typename?: 'EmailVerificationMutation'
  confirmVerificationEmail: ConfirmVerificationEmailResult
  sendVerificationEmail: SendVerificationEmailResult
}

export type EmailVerificationMutationConfirmVerificationEmailArgs = {
  input: InputMaybe<ConfirmVerificationEmailInput>
}

export type EmailVerificationMutationSendVerificationEmailArgs = {
  input: InputMaybe<SendVerificationEmailInput>
}

export type EmailVerificationQuery = {
  __typename?: 'EmailVerificationQuery'
  events: Array<EmailVerificationEvent>
  verification: Maybe<EmailVerificationResult>
}

export type EmailVerificationQueryEventsArgs = {
  input: EmailVerificationEventsInput
}

export type EmailVerificationResult =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerification
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export enum EmailVerificationSourceType {
  PAYROLL = 'PAYROLL',
  SCHEDULING = 'SCHEDULING',
  TOASTWEB = 'TOASTWEB'
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  email: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  isPrimary: Maybe<Scalars['Boolean']>
  lastName: Scalars['String']
  relation: Scalars['String']
  telephoneNumber: Maybe<Scalars['String']>
}

export type Employee = {
  __typename?: 'Employee'
  benefits: Maybe<Benefits>
  companyCode: Maybe<Scalars['String']>
  contact: EmployeeContact
  demographics: EmployeeDemographics
  emergencyContacts: Array<EmergencyContact>
  employment: Maybe<Employment>
  hasSsn: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  identification: EmployeeIdentification
  isEmployeeZero: Scalars['Boolean']
  jobAssignmentPayChangeErrors: Array<JobAssignment>
  jobAssignments: Array<JobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  payHistory: EmployeePayHistory
  payrollSecurityRole: Maybe<PayrollSecurityRole>
  salary: Maybe<Salary>
  salaryChangeErrors: Maybe<Salary>
  supervisors: Array<EmployeeSupervisor>
  user: Maybe<User>
  /** @deprecated Please use user */
  userUuid: Scalars['ID']
}

export type EmployeeAtLocation = {
  __typename?: 'EmployeeAtLocation'
  jobs: Array<EmployeeJob>
  mappingStatus: MappingCondition
  payrollLocationId: Maybe<LevelId>
  payrollLocationName: Maybe<Scalars['String']>
  posEmail: Maybe<Scalars['String']>
  posLocationGuid: Maybe<Scalars['ID']>
  posMostRecentClockInDate: Maybe<Scalars['Date']>
  posRestaurantUserGuid: Maybe<Scalars['ID']>
  posStatus: Maybe<Scalars['String']>
  posUserName: Maybe<PersonName>
}

export type EmployeeComparison = {
  __typename?: 'EmployeeComparison'
  added: Array<PayrollEmployee>
  removed: Array<PayrollEmployee>
}

export type EmployeeContact = {
  __typename?: 'EmployeeContact'
  homeAddress: Maybe<PostalAddress>
  mailingAddress: Maybe<PostalAddress>
  telephoneNumber: Maybe<ContactTelephoneNumber>
}

export type EmployeeDemographics = {
  __typename?: 'EmployeeDemographics'
  dateOfBirth: Maybe<Scalars['Date']>
  disability: Maybe<DisabilityStatus>
  ethnicity: Maybe<Ethnicity>
  gender: Maybe<Gender>
  maritalStatus: Maybe<MaritalStatus>
  veteranStatus: Array<VeteranStatus>
}

export type EmployeeDocumentUploadRequest = {
  fileName: Scalars['String']
}

export type EmployeeEarning = {
  __typename?: 'EmployeeEarning'
  amount: Scalars['Decimal']
  employeeUuid: Scalars['String']
  quarter: Scalars['Int']
  year: Scalars['Int']
}

export type EmployeeIdentification = {
  __typename?: 'EmployeeIdentification'
  driversLicense: Maybe<DriversLicense>
  hasSocialSecurityNumber: Scalars['Boolean']
}

export type EmployeeJob = {
  __typename?: 'EmployeeJob'
  mappingStatus: MappingCondition
  payrollId: Maybe<LevelId>
  payrollRateOfPay: Maybe<Money>
  payrollTitle: Maybe<Scalars['String']>
  posGuid: Maybe<Scalars['ID']>
  posRateOfPay: Maybe<Money>
  posTitle: Maybe<Scalars['String']>
}

export type EmployeeMappingStatus = {
  __typename?: 'EmployeeMappingStatus'
  email: Maybe<Scalars['String']>
  id: Scalars['ID']
  issues: Array<MappingIssue>
  locations: Array<EmployeeAtLocation>
  name: PersonName
  status: EmploymentStatusType
}

export type EmployeeNetPays = {
  __typename?: 'EmployeeNetPays'
  amt: Scalars['Float']
  uuid: Scalars['ID']
}

export type EmployeeNewHireDocument = {
  __typename?: 'EmployeeNewHireDocument'
  fileName: Scalars['String']
  filePath: Scalars['String']
  uploadUrl: Scalars['String']
}

export type EmployeeNotFoundError = {
  __typename?: 'EmployeeNotFoundError'
  message: Maybe<Scalars['String']>
}

export type EmployeePaginationInput = {
  from: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  locationIds: InputMaybe<Array<Scalars['Int']>>
  offset: Scalars['Int']
  positionIds: InputMaybe<Array<Scalars['Int']>>
  statusIds: InputMaybe<Array<Scalars['Int']>>
  until: InputMaybe<Scalars['String']>
}

export type EmployeePaginationResponse = {
  __typename?: 'EmployeePaginationResponse'
  employees: Array<Employee>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  totalCount: Scalars['Int']
}

export type EmployeePay = {
  __typename?: 'EmployeePay'
  mostRecentPaycheck: Maybe<Scalars['String']>
}

export type EmployeePayHistory = {
  __typename?: 'EmployeePayHistory'
  mostRecentPaycheck: Maybe<Scalars['String']>
}

export type EmployeePaymentMethodEmailResponse = {
  __typename?: 'EmployeePaymentMethodEmailResponse'
  messageId: Maybe<Scalars['String']>
}

export type EmployeeRequirement = {
  __typename?: 'EmployeeRequirement'
  employeesPay: Array<EmployeeNetPays>
  invoiceItems: Array<InvoiceItem>
  voidNetPayment: Array<PayCheck>
}

export type EmployeeStatusTypeCount = {
  __typename?: 'EmployeeStatusTypeCount'
  active: Maybe<Scalars['Int']>
  applicant: Maybe<Scalars['Int']>
  deleted: Maybe<Scalars['Int']>
  demo: Maybe<Scalars['Int']>
  dormant: Maybe<Scalars['Int']>
  implementation: Maybe<Scalars['Int']>
  leaveOfAbsence: Maybe<Scalars['Int']>
  neverEmployed: Maybe<Scalars['Int']>
  notSpecified: Maybe<Scalars['Int']>
  pendingActive: Maybe<Scalars['Int']>
  terminated: Maybe<Scalars['Int']>
}

export type EmployeeSupervisor = {
  __typename?: 'EmployeeSupervisor'
  id: Scalars['ID']
  job: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type EmployeeTaxes = {
  __typename?: 'EmployeeTaxes'
  amount: Scalars['Decimal']
  employeeUuid: Scalars['String']
  quarter: Scalars['Int']
  year: Scalars['Int']
}

export type Employment = {
  __typename?: 'Employment'
  eeoClassification: EeoClassification
  employeeNumber: Maybe<Scalars['String']>
  employmentStatus: AllEmploymentStatuses
  employmentType: Maybe<Scalars['String']>
  id: Scalars['ID']
  overtimeEligible: Maybe<Scalars['Boolean']>
  totalLengthOfService: Maybe<DateRange>
}

export type EmploymentDetails = {
  __typename?: 'EmploymentDetails'
  employeeNumber: Scalars['Int']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']
}

export type EmploymentDetailsInput = {
  defaultEarningsCode: Scalars['ID']
  eeoClassification: InputMaybe<EeoClassificationInput>
  employeeNumber: Scalars['Int']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']
}

export type EmploymentId = {
  __typename?: 'EmploymentId'
  customerId: Scalars['ID']
  employeeId: Scalars['ID']
}

export type EmploymentStatus = {
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export enum EmploymentStatusType {
  ACTIVE = 'ACTIVE',
  APPLICANT = 'APPLICANT',
  DELETED = 'DELETED',
  DEMO = 'DEMO',
  IMPLEMENTATION = 'IMPLEMENTATION',
  LEAVE_OF_ABSENCE = 'LEAVE_OF_ABSENCE',
  NEVER_EMPLOYED = 'NEVER_EMPLOYED',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  PENDING_ACTIVE = 'PENDING_ACTIVE',
  SEASONAL = 'SEASONAL',
  TERMINATED = 'TERMINATED'
}

export enum EmploymentTaxType {
  K1 = 'K1',
  UNKNOWN = 'UNKNOWN',
  W2 = 'W2',
  _1099 = '_1099'
}

export type EmptySalaryAllocationsError = {
  __typename?: 'EmptySalaryAllocationsError'
  message: Maybe<Scalars['String']>
}

export enum EnumAmountType {
  Currency = 'Currency',
  Percent = 'Percent',
  Undefined = 'Undefined',
  Value = 'Value'
}

export type Error = {
  __typename?: 'Error'
  message: Maybe<Scalars['String']>
}

export enum Ethnicity {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  DO_NOT_WISH_TO_IDENTIFY = 'DO_NOT_WISH_TO_IDENTIFY',
  HAWAIIAN_OR_OTHER_PACIFIC_ISLAND = 'HAWAIIAN_OR_OTHER_PACIFIC_ISLAND',
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  TWO_OR_MORE_RACES = 'TWO_OR_MORE_RACES',
  WHITE = 'WHITE'
}

export type ExpenseReport = {
  __typename?: 'ExpenseReport'
  approveDateTime: Maybe<Scalars['SpaDateTime']>
  customerUuid: Scalars['String']
  employeeFirstName: Scalars['String']
  employeeLastName: Scalars['String']
  employeeUuid: Scalars['String']
  payPeriodUuid: Maybe<Scalars['String']>
  totalAmount: Scalars['Float']
}

export type Fein = {
  __typename?: 'Fein'
  address: FeinAddress
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  name: Scalars['String']
  states: Array<EinState>
  taxFilingEndDate: Maybe<Scalars['Date']>
  taxFilingStartDate: Maybe<Scalars['Date']>
  tin: Scalars['String']
  workTaxLocationIds: Array<Scalars['String']>
}

export type FeinAddress = {
  __typename?: 'FeinAddress'
  city: Scalars['String']
  country: Scalars['String']
  phoneNumber: Scalars['String']
  state: Scalars['String']
  streetLine1: Scalars['String']
  streetLine2: Scalars['String']
  zipCode: Scalars['String']
}

export type FeinMissingSsnEmployee = {
  __typename?: 'FeinMissingSsnEmployee'
  /** The employees with missing SSN that the current user has access to in this specific FEIN, doesn't necessarily matching the total field */
  employees: Array<MissingSsnEmployee>
  feinUuid: Scalars['String']
  /** The total number of employees with missing SSN in this specific FEIN  */
  total: Scalars['Int']
}

export type FeinOption = {
  __typename?: 'FeinOption'
  id: Scalars['LegacyId']
  name: Scalars['String']
}

export type FindUserEventsByUserIdInput = {
  fromVersion: InputMaybe<Scalars['Long']>
  maxNumResults: InputMaybe<Scalars['Int']>
  userId: Scalars['ID']
}

export type Form8655 = {
  __typename?: 'Form8655'
  dateSigned: Maybe<Scalars['Date']>
  feinUuid: Scalars['String']
  form8655Details: Maybe<Scalars['String']>
  id: Maybe<Scalars['String']>
  s3Filename: Maybe<Scalars['String']>
}

export type Form8655Error = MutationError & {
  __typename?: 'Form8655Error'
  errors: Array<Error>
  status: ResponseStatus
}

export type Form8655Input = {
  contact: Scalars['String']
  fax: InputMaybe<Scalars['String']>
  phone: Scalars['String']
  signature: Scalars['String']
  title: Scalars['String']
}

export type Form8655Response = Form8655Error | Form8655Success

export type Form8655Success = MutationSuccess & {
  __typename?: 'Form8655Success'
  result: Scalars['ID']
  status: ResponseStatus
}

export enum Gender {
  DOES_NOT_WISH_TO_IDENTIFY = 'DOES_NOT_WISH_TO_IDENTIFY',
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export type GenericResponse = {
  __typename?: 'GenericResponse'
  message: Scalars['String']
}

export type HourlyEarningInput = {
  earningCodeId: Scalars['ID']
  hours: Scalars['Decimal']
  rate: MoneyInput
}

export type HourlyRetroPay = {
  __typename?: 'HourlyRetroPay'
  hoursWorked: Scalars['Decimal']
  payChangeDifference: AmountWithPayInterval
  retroPay: Money
}

export type HourlyRetroPayInput = {
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
  jobAssignmentId: Scalars['ID']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']
}

export type HourlyRetroPayResponse =
  | HourlyRetroPay
  | InvalidStartDateError
  | JobAssignmentNotFoundError
  | TimesheetRetrievalError

export enum HumanResourcesPermission {
  CHANGE_UNVERIFIED_USER_EMAIL = 'CHANGE_UNVERIFIED_USER_EMAIL',
  CHANGE_VERIFIED_USER_EMAIL = 'CHANGE_VERIFIED_USER_EMAIL',
  CUSTOMER_CONFIGURATION_EDIT = 'CUSTOMER_CONFIGURATION_EDIT',
  CUSTOMER_CONFIGURATION_VIEW = 'CUSTOMER_CONFIGURATION_VIEW',
  CUSTOMER_JOB_MAPPING_ADMIN_EDIT = 'CUSTOMER_JOB_MAPPING_ADMIN_EDIT',
  CUSTOMER_JOB_MAPPING_ADMIN_VIEW = 'CUSTOMER_JOB_MAPPING_ADMIN_VIEW',
  /** @deprecated replace with EMPLOYEE_EDIT */
  EDIT_MY_PROFILE = 'EDIT_MY_PROFILE',
  EMPLOYEE_ACCESS_ALL = 'EMPLOYEE_ACCESS_ALL',
  EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER = 'EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER',
  EMPLOYEE_EDIT = 'EMPLOYEE_EDIT',
  EMPLOYEE_MAPPING_EDIT = 'EMPLOYEE_MAPPING_EDIT',
  EMPLOYEE_VIEW = 'EMPLOYEE_VIEW',
  EMPLOYMENT_EDIT = 'EMPLOYMENT_EDIT',
  EMPLOYMENT_STATUS_EDIT = 'EMPLOYMENT_STATUS_EDIT',
  EMPLOYMENT_STATUS_VIEW = 'EMPLOYMENT_STATUS_VIEW',
  EMPLOYMENT_VIEW = 'EMPLOYMENT_VIEW',
  JOB_ASSIGNMENT_EDIT = 'JOB_ASSIGNMENT_EDIT',
  JOB_ASSIGNMENT_VIEW = 'JOB_ASSIGNMENT_VIEW',
  MIGRATE_USERS = 'MIGRATE_USERS',
  PAY_CARD_AUDIT_VIEW = 'PAY_CARD_AUDIT_VIEW',
  PAY_CHANGE_HISTORY_VIEW = 'PAY_CHANGE_HISTORY_VIEW',
  PAY_EDIT = 'PAY_EDIT',
  PAY_HISTORY_VIEW = 'PAY_HISTORY_VIEW',
  SSN_EDIT = 'SSN_EDIT',
  SSN_EXISTS_VIEW = 'SSN_EXISTS_VIEW',
  /** @deprecated replace with EMPLOYEE_VIEW */
  VIEW_MY_PROFILE = 'VIEW_MY_PROFILE',
  /** @deprecated removing */
  VIEW_PERMISSIONS = 'VIEW_PERMISSIONS'
}

export type ImplementationEmploymentStatus = EmploymentStatus & {
  __typename?: 'ImplementationEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type IncompleteDirectDepositEmployee = {
  __typename?: 'IncompleteDirectDepositEmployee'
  email: Scalars['String']
  id: Scalars['ID']
  name: PersonName
  notification: Maybe<DirectDepositNotification>
}

export type IncompleteDirectDepositInput = {
  direction: SortDirection
  limit: Scalars['Int']
  offset: Scalars['Int']
  sort: IncompleteDirectDepositSortColumn
}

export enum IncompleteDirectDepositSortColumn {
  LAST_CONTACTED = 'LAST_CONTACTED',
  NAME = 'NAME'
}

export type IncompleteDirectDepositsResponse = {
  __typename?: 'IncompleteDirectDepositsResponse'
  employees: Array<IncompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  totalCount: Scalars['Int']
}

export type InputCannotBeEmpty = {
  __typename?: 'InputCannotBeEmpty'
  message: Maybe<Scalars['String']>
}

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings'
  active: Scalars['Boolean']
  syncEmployees: Scalars['Boolean']
  tipsType: Maybe<TipsType>
}

export enum IntercomClientType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB'
}

export type IntercomSessionDataResponse = {
  __typename?: 'IntercomSessionDataResponse'
  intercomUserHash: Maybe<Scalars['String']>
  user: Maybe<User>
}

export type InvalidEffectiveDateError = {
  __typename?: 'InvalidEffectiveDateError'
  message: Maybe<Scalars['String']>
}

export type InvalidPayChangeLogTokenError = {
  __typename?: 'InvalidPayChangeLogTokenError'
  message: Maybe<Scalars['String']>
}

export type InvalidStartDateError = {
  __typename?: 'InvalidStartDateError'
  message: Maybe<Scalars['String']>
}

export type InvalidUsername = {
  __typename?: 'InvalidUsername'
  username: Scalars['String']
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem'
  caption: Maybe<Scalars['String']>
  employeeCount: Maybe<Scalars['Int']>
  invoiceType: Maybe<Scalars['String']>
  total: Scalars['Float']
  uuid: Scalars['ID']
}

export enum IsPayrollPayor {
  no = 'no',
  yesall = 'yesall',
  yesnameonly = 'yesnameonly'
}

export enum IssueSeverity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN'
}

export enum IssueType {
  ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS = 'ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS',
  JOB_GUID_IS_MALFORMED = 'JOB_GUID_IS_MALFORMED',
  JOB_IS_MISSING_IN_POS = 'JOB_IS_MISSING_IN_POS',
  JOB_NOT_MAPPED = 'JOB_NOT_MAPPED',
  LOCATION_GUID_IS_MALFORMED = 'LOCATION_GUID_IS_MALFORMED',
  LOCATION_IS_MISSING_IN_POS = 'LOCATION_IS_MISSING_IN_POS',
  LOCATION_NOT_MAPPED = 'LOCATION_NOT_MAPPED',
  MAPPED_INTO_DIFFERENT_TOAST_IDENTITY = 'MAPPED_INTO_DIFFERENT_TOAST_IDENTITY',
  PAYROLL_JOBS_MISMATCH_POS_JOBS = 'PAYROLL_JOBS_MISMATCH_POS_JOBS',
  POS_EE_MAPPING_IGNORED = 'POS_EE_MAPPING_IGNORED',
  POS_EE_MAPPING_UNKNOWN_LOCATION_GUID = 'POS_EE_MAPPING_UNKNOWN_LOCATION_GUID',
  RU_COUNTERPART_IS_MISSING_IN_POS = 'RU_COUNTERPART_IS_MISSING_IN_POS',
  UNMAPPED_PAYROLL_SPECIALTY = 'UNMAPPED_PAYROLL_SPECIALTY',
  UNMAPPED_POS_RU = 'UNMAPPED_POS_RU'
}

export type Item = {
  __typename?: 'Item'
  assignedTo: Assignee
  key: Scalars['ID']
  status: TaskStatus
  subItems: Array<Item>
  title: Scalars['String']
}

export type Job = {
  __typename?: 'Job'
  exportId: Maybe<Scalars['ID']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type JobAssignment = {
  __typename?: 'JobAssignment'
  activeHourlyRateOfPay: Maybe<Money>
  employmentTaxType: Maybe<EmploymentTaxType>
  id: Scalars['ID']
  isPrimary: Scalars['Boolean']
  jobId: Scalars['ID']
  locationId: Scalars['ID']
  locationName: Scalars['String']
  name: Scalars['String']
  payGroupId: Maybe<Scalars['ID']>
  pendingPayChange: Maybe<PendingPayChange>
}

export type JobAssignmentNotFoundError = {
  __typename?: 'JobAssignmentNotFoundError'
  message: Maybe<Scalars['String']>
}

export type JobInput = {
  restaurantJobGuid: InputMaybe<Scalars['ID']>
  uuid: Scalars['ID']
}

export type JobMapping = {
  __typename?: 'JobMapping'
  payrollJob: PayrollJob
  restaurantJob: RestaurantJob
}

export type JobTag = {
  __typename?: 'JobTag'
  createdByUserGuid: Scalars['ID']
  createdDate: Scalars['Date']
  deletedDate: Maybe<Scalars['Date']>
  id: Scalars['ID']
  modifiedByUserGuid: Maybe<Scalars['ID']>
  modifiedDate: Maybe<Scalars['Date']>
  name: Scalars['String']
  values: Array<Scalars['String']>
}

export type JobTagAssignment = {
  __typename?: 'JobTagAssignment'
  createdByUserGuid: Scalars['ID']
  createdDate: Scalars['Date']
  deletedDate: Maybe<Scalars['Date']>
  id: Scalars['ID']
  jobTagId: Scalars['ID']
  jobTagName: Scalars['String']
  managementSetId: Scalars['ID']
  modifiedByUserGuid: Maybe<Scalars['ID']>
  modifiedDate: Maybe<Scalars['Date']>
  restaurantId: Scalars['ID']
  restaurantJobId: Scalars['ID']
  selectedJobTagValue: Scalars['String']
}

export type JobType = {
  __typename?: 'JobType'
  isOrphan: Maybe<Scalars['Boolean']>
  name: Maybe<Scalars['String']>
  uuid: Scalars['ID']
}

export type KeyDoesNotMatch = {
  __typename?: 'KeyDoesNotMatch'
  message: Maybe<Scalars['String']>
}

export type LeaveOfAbsenceEmploymentStatus = EmploymentStatus & {
  __typename?: 'LeaveOfAbsenceEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
  leaveType: Maybe<Scalars['String']>
  scheduledLeave: Maybe<DateRange>
}

export type LevelConfig = {
  __typename?: 'LevelConfig'
  name: Scalars['String']
  sequence: Scalars['Int']
  type: Scalars['String']
}

export type LevelId = {
  __typename?: 'LevelId'
  databaseId: Scalars['Int']
  sequenceId: Scalars['Int']
}

export type LevelIdInput = {
  databaseId: Scalars['Int']
  sequenceId: Scalars['Int']
}

export type LevelOption = {
  __typename?: 'LevelOption'
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type LevelUuidSequence = {
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type LinkIfMappedResult =
  | LinkingNotAvailable
  | MappedUserMismatchedUsername
  | PayrollUser
  | UserAlreadyLinked
  | UserNotMapped

export type LinkUserResult = PayrollUser | UserNotFound

export type LinkingNotAvailable = {
  __typename?: 'LinkingNotAvailable'
  toastIdentityGuid: Scalars['ID']
}

export type LocalizableMessage = {
  __typename?: 'LocalizableMessage'
  key: Scalars['String']
  message: Scalars['String']
}

export type Location = {
  __typename?: 'Location'
  name: Maybe<Scalars['String']>
  restaurant: Maybe<Restaurant>
  status: LocationMappingStatus
  uuid: Scalars['ID']
}

export type LocationInput = {
  restaurantGuid: InputMaybe<Scalars['ID']>
  uuid: Scalars['ID']
}

export type LocationMappingStatus = {
  __typename?: 'LocationMappingStatus'
  code: LocationMappingStatusCode
  message: Scalars['String']
}

export enum LocationMappingStatusCode {
  NoStatus = 'NoStatus',
  RestaurantGuidConnected = 'RestaurantGuidConnected',
  RestaurantGuidDoubleMapped = 'RestaurantGuidDoubleMapped',
  RestaurantGuidEmpty = 'RestaurantGuidEmpty',
  RestaurantGuidInvalid = 'RestaurantGuidInvalid'
}

export type LumpSumEarningInput = {
  amount: MoneyInput
  earningCodeId: Scalars['ID']
}

export type MappedUserMismatchedUsername = {
  __typename?: 'MappedUserMismatchedUsername'
  username: Scalars['String']
}

export type MappingAssignment = {
  locationGuid: Scalars['ID']
  restaurantUserGuid: Scalars['ID']
}

export enum MappingCondition {
  MAPPED_INVALID_GUID = 'MAPPED_INVALID_GUID',
  MAPPED_NO_POS_COUNTERPART = 'MAPPED_NO_POS_COUNTERPART',
  MAPPED_USER_MAPPED = 'MAPPED_USER_MAPPED',
  MAPPED_USER_MAPPING_IGNORED = 'MAPPED_USER_MAPPING_IGNORED',
  MAPPED_USER_NOT_MAPPED = 'MAPPED_USER_NOT_MAPPED',
  NOT_MAPPED = 'NOT_MAPPED',
  POS_ONLY = 'POS_ONLY'
}

export type MappingIssue = {
  __typename?: 'MappingIssue'
  severity: IssueSeverity
  type: IssueType
}

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  HEAD_OF_HOUSEHOLD = 'HEAD_OF_HOUSEHOLD',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  WIDOW = 'WIDOW'
}

export type Message = {
  __typename?: 'Message'
  action: MessageActionType
  amount: Scalars['Float']
  amountType: EnumAmountType
  author: Scalars['String']
  datePosted: Scalars['SpaDateTime']
  name: Scalars['String']
  whom: Scalars['String']
}

export enum MessageActionType {
  approved = 'approved',
  recorded = 'recorded',
  scheduled = 'scheduled'
}

export type MissingPayGroupError = {
  __typename?: 'MissingPayGroupError'
  message: Maybe<Scalars['String']>
}

export type MissingSsnEmployee = {
  __typename?: 'MissingSsnEmployee'
  employeeNumber: Scalars['String']
  firstName: Scalars['String']
  firstPayrollDate: Maybe<Scalars['Date']>
  isSsnDeclined: Scalars['Boolean']
  lastName: Scalars['String']
  ssnDeclinedAt: Maybe<Scalars['DateTime']>
  uuid: Scalars['String']
}

/** A monetary value with currency. */
export type Money = {
  __typename?: 'Money'
  /** The amount of money */
  amount: Scalars['Decimal']
  /** The currency the money is paid in */
  currency: Currency
}

export type MoneyInput = {
  amount: Scalars['Decimal']
  currency: Currency
}

export type Mutation = {
  __typename?: 'Mutation'
  applyMapping: Scalars['String']
  archive: ArchiveResponse
  archivePayPeriod: Maybe<Scalars['String']>
  automationResetPayroll: ResponseStatus
  bringBackPayroll: BringBackResponse
  /** @deprecated Please use directDepositBulkEmail */
  bulkNotify: Array<BulkNotifyResponse>
  changeHelloWorldResponse: Maybe<Response>
  changeHourlyPay: Maybe<JobAssignment>
  /** Change salary pay: provide a list of the updated SalaryAllocations and their new annual pay rate */
  changeSalaryAllocations: ChangeSalaryResponse
  contactOnboarding: Maybe<GenericResponse>
  createBulkDeductions: Maybe<BulkAddResponse>
  createBulkEarnings: Maybe<BulkAddResponse>
  createJobTag: Maybe<JobTag>
  createJobTagAssignment: Maybe<JobTagAssignment>
  /** @deprecated please use updatePendingPayChangeStatuses */
  deletePendingPayChange: Maybe<Scalars['ID']>
  directDepositBulkEmail: Array<DirectDepositBulkEmailResponse>
  emailVerification: Maybe<EmailVerificationMutation>
  ignorePosUserInPayroll: Maybe<Scalars['Boolean']>
  open: OpenResponse
  payrollImport: PayrollImportResponse
  payrollUser: Maybe<PayrollUserMutations>
  post: PostResponse
  reactivateAdminUser: Scalars['Boolean']
  rehireEmployeeNoChanges: Scalars['ID']
  rehireEmployeeWithChanges: Scalars['ID']
  reopenTasks: TasksReopenResponse
  reset: Maybe<Scalars['String']>
  resyncTasks: ChecklistState
  resyncTaxPrepChecklist: TaxPrepChecklistState
  rolloverPayrolls: EcPayrollResponse
  saveAdditionalEarnings: Maybe<AdditionalEarningResponse>
  saveDeduction: SaveDeductionResponse
  saveEarning: SaveEarningResponse
  saveEmployeeSsn: SaveEmployeeSsnResponse
  saveForm8655: Form8655Response
  saveNewEmployee: Maybe<SaveNewEmployeeResponse>
  savePayrollSetting: EcPayrollResponse
  saveTaxFilingEndDate: EcPayrollResponse
  sendJobMappingRequest: Maybe<Scalars['String']>
  sendUpdateEmployeePaymentMethodEmail: Maybe<UpdateEmployeePaymentMethodEmailResponse>
  setHasPreviousPayrollProvider: GenericResponse
  updateBenefitsConsent: BenefitsThirdPartyDataSharingConsent
  updateCustomer: Customer
  updateJobMappings: Array<PayrollJob>
  updatePayCardCustomerSettings: UpdatePayCardCustomerSettingsResponse
  updatePendingPayChangeStatuses: Maybe<UpdatePendingPayChangeStatusResponse>
  updateTask: ChecklistState
  updateTaxPrepTask: TaxPrepChecklistState
  updateW2Settings: W2PrintingStatusResponse
  userMigration: Maybe<UserMigrationMutation>
}

export type MutationApplyMappingArgs = {
  locationId: Scalars['ID']
  payrollEmployeeId: Scalars['ID']
  restaurantUserId: InputMaybe<Scalars['ID']>
}

export type MutationArchiveArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationArchivePayPeriodArgs = {
  payrollUuid: Scalars['ID']
}

export type MutationAutomationResetPayrollArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationBringBackPayrollArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationBulkNotifyArgs = {
  employees: Array<Scalars['ID']>
}

export type MutationChangeHelloWorldResponseArgs = {
  response: Scalars['String']
}

export type MutationChangeHourlyPayArgs = {
  changePayInput: ChangePayInput
  employeeId: Scalars['ID']
  jobAssignmentId: Scalars['ID']
}

export type MutationChangeSalaryAllocationsArgs = {
  changeSalaryAllocations: Array<ChangeSalaryAllocationInput>
  employeeId: Scalars['ID']
}

export type MutationContactOnboardingArgs = {
  emailContent: InputMaybe<Scalars['String']>
}

export type MutationCreateBulkDeductionsArgs = {
  input: BulkDeductionMutation
  payrollUuid: Scalars['ID']
}

export type MutationCreateBulkEarningsArgs = {
  input: BulkEarningMutation
  payrollUuid: Scalars['ID']
}

export type MutationCreateJobTagArgs = {
  jobTagInput: CreateJobTagInput
}

export type MutationCreateJobTagAssignmentArgs = {
  jobTagAssignmentInput: CreateJobTagAssignmentInput
}

export type MutationDeletePendingPayChangeArgs = {
  employeeId: Scalars['ID']
  pendingPayChangeId: Scalars['ID']
}

export type MutationDirectDepositBulkEmailArgs = {
  employees: Array<Scalars['ID']>
}

export type MutationIgnorePosUserInPayrollArgs = {
  userGuid: Scalars['ID']
}

export type MutationOpenArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationPayrollImportArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationPostArgs = {
  consentBankAccountUuids: InputMaybe<Array<Scalars['String']>>
  payPeriodUuid: Scalars['String']
}

export type MutationReactivateAdminUserArgs = {
  email: Scalars['String']
}

export type MutationRehireEmployeeNoChangesArgs = {
  request: RehireEmployeeNoChangesInput
}

export type MutationRehireEmployeeWithChangesArgs = {
  employeeId: Scalars['ID']
  rehireDate: Scalars['Date']
  request: NewEmployeeInput
}

export type MutationReopenTasksArgs = {
  tasksRows: Array<TasksRow>
}

export type MutationResetArgs = {
  payPeriodUuid: Scalars['ID']
}

export type MutationRolloverPayrollsArgs = {
  payPeriodUuid: Scalars['String']
}

export type MutationSaveAdditionalEarningsArgs = {
  employeeId: Scalars['ID']
  hourlyEarnings?: Array<HourlyEarningInput>
  jobAssignmentId: Scalars['ID']
  lumpSumEarnings?: Array<LumpSumEarningInput>
}

export type MutationSaveDeductionArgs = {
  deduction: SaveDeduction
  deductionUuid: InputMaybe<Scalars['String']>
  payPeriodUuid: Scalars['String']
}

export type MutationSaveEarningArgs = {
  earning: SaveEarning
  earningUuid: InputMaybe<Scalars['String']>
  payPeriodUuid: Scalars['String']
}

export type MutationSaveEmployeeSsnArgs = {
  employeeUuid: Scalars['String']
  ssn: Scalars['String']
}

export type MutationSaveForm8655Args = {
  feinUuid: Scalars['String']
  input: Form8655Input
}

export type MutationSaveNewEmployeeArgs = {
  request: NewEmployeeInput
}

export type MutationSavePayrollSettingArgs = {
  type: PayrollSetting
  value: Scalars['Boolean']
}

export type MutationSaveTaxFilingEndDateArgs = {
  customerFeinUuid: Scalars['String']
  taxFilingEndDate: InputMaybe<Scalars['Date']>
}

export type MutationSendJobMappingRequestArgs = {
  attachedNote: InputMaybe<Scalars['String']>
  employeeUuid: Scalars['ID']
  payrollJobId: InputMaybe<LevelIdInput>
  payrollLocationId: InputMaybe<LevelIdInput>
}

export type MutationSendUpdateEmployeePaymentMethodEmailArgs = {
  employeeId: Scalars['ID']
}

export type MutationSetHasPreviousPayrollProviderArgs = {
  hasPreviousPayrollProvider: Scalars['Boolean']
}

export type MutationUpdateBenefitsConsentArgs = {
  version: Scalars['Float']
}

export type MutationUpdateCustomerArgs = {
  customerInput: CustomerInput
}

export type MutationUpdateJobMappingsArgs = {
  jobs: Array<JobInput>
  locationId: Scalars['ID']
}

export type MutationUpdatePayCardCustomerSettingsArgs = {
  input: UpdatePayCardCustomerSettingsInput
}

export type MutationUpdatePendingPayChangeStatusesArgs = {
  employeeId: Scalars['ID']
  updatePendingPayChangeStatusInput?: Array<PendingPayChangeStatusInput>
}

export type MutationUpdateTaskArgs = {
  status: TaskStatus
  taskKey: Scalars['String']
}

export type MutationUpdateTaxPrepTaskArgs = {
  status: TaskStatus
  taskKey: Scalars['String']
}

export type MutationUpdateW2SettingsArgs = {
  w2Settings: W2SettingsInput
}

export type MutationError = {
  errors: Array<Error>
  status: ResponseStatus
}

export type MutationSuccess = {
  status: ResponseStatus
}

export type NavigableTaxAccount = {
  __typename?: 'NavigableTaxAccount'
  companyCode: Scalars['String']
  legalName: Scalars['String']
  shardName: Scalars['String']
  tin: Scalars['String']
}

export type NavigableTaxAccountResult = {
  __typename?: 'NavigableTaxAccountResult'
  nextPageNumber: Maybe<Scalars['Int']>
  taxAccounts: Array<NavigableTaxAccount>
}

export type NavigableTaxAccountsInput = {
  pageNumber: InputMaybe<Scalars['Int']>
  pageSize: InputMaybe<Scalars['Int']>
  search: InputMaybe<Scalars['String']>
}

export type Navigation = {
  __typename?: 'Navigation'
  children: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']
  labelNew: LocalizableMessage
  matchUrls: Array<Scalars['String']>
  name: Scalars['String']
  pageType: Maybe<PageType>
  path: Scalars['String']
  url: Scalars['String']
}

export type NavigationError = {
  __typename?: 'NavigationError'
  message: Scalars['String']
  type: Scalars['String']
}

export type NavigationErrors = {
  __typename?: 'NavigationErrors'
  errors: Array<NavigationError>
  path: Scalars['String']
}

export type NavigationInput = {
  params: InputMaybe<Array<NavigationParam>>
  path: InputMaybe<Scalars['String']>
}

export type NavigationParam = {
  key: Scalars['String']
  value: Scalars['String']
}

export type NavigationResult = {
  __typename?: 'NavigationResult'
  errors: Array<NavigationErrors>
  result: Maybe<Navigation>
}

export type NeverEmployedEmploymentStatus = EmploymentStatus & {
  __typename?: 'NeverEmployedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type NewEmployeeInput = {
  additionalDocuments: Array<AdditionalDocumentInput>
  benefits: BenefitsInput
  customDocumentFields: Array<CustomDocumentFieldInput>
  documents: Array<Scalars['ID']>
  employment: EmploymentDetailsInput
  jobsAndPay: NewJobsAndPayInput
  overtimeEligible: Scalars['Boolean']
  person: PersonInput
  securityRoleId: Scalars['ID']
}

export type NewHireDocument = {
  __typename?: 'NewHireDocument'
  assignedByPosition: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
}

export type NewJobAssignment = {
  __typename?: 'NewJobAssignment'
  hourlyRate: Maybe<Money>
  jobId: Scalars['ID']
  locationId: Scalars['ID']
}

export type NewJobAssignmentInput = {
  hourlyRate: InputMaybe<MoneyInput>
  jobId: Scalars['ID']
  locationId: Scalars['ID']
}

export type NewJobsAndPay = {
  __typename?: 'NewJobsAndPay'
  annualSalary: Maybe<Money>
  jobs: Array<NewJobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  salaryPerPayPeriod: Maybe<Money>
  salaryRateOfPay: Maybe<Money>
}

export type NewJobsAndPayInput = {
  annualSalary: InputMaybe<MoneyInput>
  jobs: Array<NewJobAssignmentInput>
  mappings: Array<MappingAssignment>
  payGroupAssignments: Array<PayGroupAssignmentInput>
  salaryPerPayPeriod: InputMaybe<MoneyInput>
  salaryRateOfPay: InputMaybe<MoneyInput>
}

export type NextPayPeriodNotFoundError = {
  __typename?: 'NextPayPeriodNotFoundError'
  message: Maybe<Scalars['String']>
}

export type NextPaycheckDateNotFoundError = {
  __typename?: 'NextPaycheckDateNotFoundError'
  message: Maybe<Scalars['String']>
}

export type NonCashRequirement = {
  __typename?: 'NonCashRequirement'
  employeesManualPay: Array<EmployeeNetPays>
  vendorChecksNetPays: Array<VendorNetPays>
  voidNetPayment: Array<PayCheck>
}

export type NotAToastUser = {
  __typename?: 'NotAToastUser'
  message: Maybe<Scalars['String']>
}

export enum OnboardingCheckListPermission {
  EDIT_PAYROLL_ONBOARDING = 'EDIT_PAYROLL_ONBOARDING',
  VIEW_PAYROLL_ONBOARDING = 'VIEW_PAYROLL_ONBOARDING'
}

export type OpenError = MutationError & {
  __typename?: 'OpenError'
  errors: Array<Error>
  status: ResponseStatus
}

export type OpenResponse = OpenError | OpenSuccess

export type OpenSuccess = MutationSuccess & {
  __typename?: 'OpenSuccess'
  status: ResponseStatus
}

export enum PageType {
  HEADER_ONLY = 'HEADER_ONLY',
  LINK_ONLY = 'LINK_ONLY'
}

export type Paged = {
  __typename?: 'Paged'
  pageCount: Scalars['Int']
  pageIndex: Scalars['Int']
  total: Scalars['Int']
}

export type PagedResult = {
  pageSize: Scalars['Int']
  /**  the next page token, useful for infinite scroll or show more based UX  */
  pageToken: Scalars['String']
  totalRecords: Scalars['Int']
}

export type Parallel = {
  __typename?: 'Parallel'
  checkDate: Scalars['Date']
  endDate: Scalars['Date']
  feinName: Scalars['String']
  feinTin: Scalars['String']
  feinUuid: Scalars['String']
  startDate: Scalars['Date']
  totalAmount: Scalars['Decimal']
}

export type PastPayroll = {
  __typename?: 'PastPayroll'
  dueDate: Maybe<Scalars['DateTime']>
  endDate: Scalars['Date']
  id: Scalars['ID']
  isArchived: Scalars['Boolean']
  isManual: Scalars['Boolean']
  name: Scalars['String']
  payDate: Maybe<Scalars['Date']>
  payGroups: Array<PastPayrollPayGroup>
  postByUser: Maybe<PastPayrollUser>
  postDate: Maybe<Scalars['DateTime']>
  startDate: Scalars['Date']
  status: PayrollStatusV2
  total: Money
}

export type PastPayrollPagedResult = PagedResult & {
  __typename?: 'PastPayrollPagedResult'
  pageIndex: Maybe<Scalars['Int']>
  pageSize: Scalars['Int']
  pageToken: Scalars['String']
  records: Array<PastPayroll>
  totalRecords: Scalars['Int']
}

export type PastPayrollPayGroup = {
  __typename?: 'PastPayrollPayGroup'
  id: Scalars['ID']
  name: Scalars['String']
}

export type PastPayrollUser = {
  __typename?: 'PastPayrollUser'
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName: Maybe<Scalars['String']>
  username: Scalars['String']
}

export type PayCardAdminSettings = {
  __typename?: 'PayCardAdminSettings'
  payCardAdminPayoutsLocked: Maybe<Scalars['Boolean']>
}

export enum PayCardBooleanAccessFactor {
  FALSE = 'FALSE',
  TRUE = 'TRUE',
  UNKNOWN = 'UNKNOWN'
}

export type PayCardBusinessAccess = {
  __typename?: 'PayCardBusinessAccess'
  businessId: Maybe<Scalars['ID']>
  existsInPayroll: Scalars['Boolean']
  fein: Scalars['String']
  isContractComplete: Scalars['Boolean']
  restaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccess = {
  __typename?: 'PayCardCustomerAccess'
  businesses: Array<PayCardBusinessAccess>
  customerUuid: Scalars['ID']
  detachedRestaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccessInput = {
  skipCache: Scalars['Boolean']
}

export type PayCardCustomerSettings = {
  __typename?: 'PayCardCustomerSettings'
  payCardAdminSettings: Maybe<PayCardAdminSettings>
  payCardPayoutConfiguration: Maybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled: Maybe<Scalars['Boolean']>
  payCardTipsConfiguration: Maybe<PayCardTipsConfiguration>
}

/** Represents one atomic change to customer settings. */
export type PayCardCustomerSettingsAuditEntry = {
  __typename?: 'PayCardCustomerSettingsAuditEntry'
  change: PayCardCustomerSettingsAuditHistoryChangeContent
  /** The timestamp in which the change was made */
  datetime: Scalars['DateTime']
  /** User that made the change */
  user: PayCardCustomerSettingsAuditUser
}

/**
 * Content that represents what the change was that occurred.
 * Note we only return a message key here not the actual string.
 * The front end will need to resolve the actual localized
 * string.
 */
export type PayCardCustomerSettingsAuditHistoryChangeContent = {
  __typename?: 'PayCardCustomerSettingsAuditHistoryChangeContent'
  messageKey: Scalars['String']
}

export type PayCardCustomerSettingsAuditUser = {
  __typename?: 'PayCardCustomerSettingsAuditUser'
  displayName: Scalars['String']
}

export type PayCardEmployeeAccess = {
  __typename?: 'PayCardEmployeeAccess'
  accessFactors: PayCardEmployeeAccessFactors
  accessLevel: PayCardEmployeeAccessLevel
  employeeUuid: Scalars['ID']
}

export type PayCardEmployeeAccessFactors = {
  __typename?: 'PayCardEmployeeAccessFactors'
  cardActivated: Maybe<PayCardBooleanAccessFactor>
  highestRestaurantAccessLevel: Maybe<PayCardRestaurantAccessLevel>
  isEmployed: Maybe<Scalars['Boolean']>
  isLocEnabled: Maybe<PayCardBooleanAccessFactor>
  isWorkTaxLocationStateEligible: Maybe<Scalars['Boolean']>
}

export type PayCardEmployeeAccessInput = {
  employeeUuid: Scalars['ID']
  skipCache: Scalars['Boolean']
}

export enum PayCardEmployeeAccessLevel {
  NONE = 'NONE',
  PAYCARD = 'PAYCARD',
  PAYOUTS = 'PAYOUTS',
  SIGNUP = 'SIGNUP'
}

export enum PayCardEmployerAccessFactors {
  ENROLLMENT_FEATURE_FLAG = 'ENROLLMENT_FEATURE_FLAG',
  FEIN_IN_PAYROLL = 'FEIN_IN_PAYROLL',
  HAS_COMPLETED_CONTRACT = 'HAS_COMPLETED_CONTRACT',
  PAYOUT_ENTITLEMENT = 'PAYOUT_ENTITLEMENT',
  PAY_CARD_ENTITLEMENT = 'PAY_CARD_ENTITLEMENT',
  SDP_FEATURE_FLAG = 'SDP_FEATURE_FLAG'
}

export enum PayCardEmployerAccessLevel {
  EMPLOYEE_PAYOUTS = 'EMPLOYEE_PAYOUTS',
  EMPLOYEE_SIGNUP = 'EMPLOYEE_SIGNUP',
  NONE = 'NONE',
  ONBOARDING = 'ONBOARDING'
}

export enum PayCardPayoutConfiguration {
  TIPS_ONLY = 'TIPS_ONLY',
  WAGES_AND_TIPS = 'WAGES_AND_TIPS',
  WAGES_ONLY = 'WAGES_ONLY'
}

export type PayCardRestaurantAccess = {
  __typename?: 'PayCardRestaurantAccess'
  accessFactors: Array<PayCardEmployerAccessFactors>
  accessLevel: PayCardEmployerAccessLevel
  restaurantGuid: Scalars['ID']
}

export enum PayCardRestaurantAccessLevel {
  NONE = 'NONE',
  PAYOUTS = 'PAYOUTS',
  SIGNUP = 'SIGNUP'
}

export enum PayCardTipsConfiguration {
  TIPS_ALL = 'TIPS_ALL',
  TIPS_WITH_WITHHOLDING = 'TIPS_WITH_WITHHOLDING'
}

export type PayChangeEvent = {
  __typename?: 'PayChangeEvent'
  /** Information about the actor that initiated the pay change event. If null, the actor is unknown. */
  actor: Maybe<Actor>
  /** The date and time the pay change event was initiated. If null, the date is unknown. */
  createdAt: Maybe<Scalars['DateTime']>
  /** The current pay rate for the job to which the pay change event is referencing. */
  currentRate: AmountWithPayInterval
  /** The date and time the pay change did / will take effect. */
  effectiveAt: Maybe<Scalars['DateTime']>
  /** The ID of the pay change event */
  id: Scalars['ID']
  /** The job name to which the pay change event is referencing */
  jobName: Scalars['String']
  /** The location name for the job to which the pay change event is referencing */
  locationName: Scalars['String']
  /**
   * The previous pay rate for the job to which the pay change event is referencing.
   * If null, this pay change event is not tied to a previous pay, i.e. for new jobs
   */
  previousRate: Maybe<AmountWithPayInterval>
  /** The current status of the pay change even. */
  status: PayChangeEventStatus
}

export enum PayChangeEventStatus {
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  ERROR_ACKNOWLEDGED = 'ERROR_ACKNOWLEDGED',
  NEW_JOB = 'NEW_JOB',
  SCHEDULED = 'SCHEDULED',
  UPDATED = 'UPDATED'
}

export type PayChangeLog = {
  __typename?: 'PayChangeLog'
  /**
   * Page token used to retrieve the current page of pay change events. Passing this value through as the pageToken in
   * subsequent requests will result in the same pay change events being retrieved.
   */
  currentPageToken: Maybe<Scalars['String']>
  /** The employee's pay change events, default sort is via createdDate newest -> oldest */
  data: Maybe<Array<PayChangeEvent>>
  /**
   * Page token used to retrieve the next page of pay change events. When this field is null that indicates that
   * [currentPageToken] was the last page in the result set.
   */
  nextPageToken: Maybe<Scalars['String']>
  /**
   * Page token used to retrieve the previous page of pay change events. When the field is null that indicates that indicates
   * that the [currentPageToken] was the first page in the result set.
   */
  previousPageToken: Maybe<Scalars['String']>
  /** The total count of this employee's pay change events */
  totalResultCount: Scalars['Int']
}

export type PayChangeLogResponse =
  | AbsentPayChangeLogTokenError
  | InvalidPayChangeLogTokenError
  | PayChangeLog

export enum PayChangeLogSortField {
  CREATE_DATE = 'CREATE_DATE'
}

export type PayChangeLogSortInput = {
  /** Whether to sort in ascending or descending order for this field */
  direction: SortDirection
  /** The field by which to sort the pay change log events */
  sortField: PayChangeLogSortField
}

export type PayCheck = {
  __typename?: 'PayCheck'
  netEarnings: Scalars['Float']
}

export type PayCheckDeduction = {
  __typename?: 'PayCheckDeduction'
  amount: Scalars['Float']
  deductionCodeUuid: Scalars['String']
  isPercentage: Scalars['Boolean']
  isReadOnly: Scalars['Boolean']
  isSimplyInsured: Maybe<Scalars['Boolean']>
  legacyDeductionCodeId: Scalars['String']
  legacyId: Scalars['ID']
  name: Scalars['String']
  uuid: Scalars['String']
}

export type PayCheckDeliverySettings = {
  __typename?: 'PayCheckDeliverySettings'
  method: DeliveryMethod
  needsPdfFile: Scalars['Boolean']
  usingToastCheckStock: Scalars['Boolean']
}

export type PayCheckEarning = {
  __typename?: 'PayCheckEarning'
  amount: Scalars['Float']
  baseRate: Scalars['Float']
  checkCode: CheckCode
  earningCode: EarningCode
  hours: Scalars['Float']
  isFirstWeek: Scalars['Boolean']
  job: Scalars['String']
  legacyEarningCodeId: Scalars['String']
  legacyId: Scalars['ID']
  location: Scalars['String']
  name: Scalars['String']
  rate: Scalars['Float']
  uuid: Scalars['String']
}

export type PayCheckTaxes = {
  __typename?: 'PayCheckTaxes'
  amount: Scalars['Float']
  isCollectedByToast: Scalars['Boolean']
  name: Scalars['String']
}

export type PayCheckV2 = {
  __typename?: 'PayCheckV2'
  checkCode: CheckCode
  deductions: Array<PayCheckDeduction>
  earnings: Array<PayCheckEarning>
  isManual: Scalars['Boolean']
  isReadOnly: Scalars['Boolean']
  isVoid: Scalars['Boolean']
  legacyId: Maybe<Scalars['ID']>
  number: Scalars['Int']
  paidByCheck: Scalars['Boolean']
  uuid: Scalars['String']
}

export type PayDetails = {
  __typename?: 'PayDetails'
  checkDate: Maybe<Scalars['SpaDate']>
  gross: Scalars['Float']
  net: Scalars['Float']
  payStubs: Array<PayStub>
  taxes: Scalars['Float']
}

export type PayFrequencies = {
  __typename?: 'PayFrequencies'
  active: Scalars['Boolean']
  calcMethod: Scalars['String']
  default: Scalars['Boolean']
  frequency: Scalars['String']
  name: Scalars['String']
  uuid: Scalars['String']
}

export enum PayFrequency {
  ANNUAL = 'ANNUAL',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  NONE = 'NONE',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUAL = 'SEMIANNUAL',
  SEMIMONTHLY = 'SEMIMONTHLY',
  WEEKLY = 'WEEKLY'
}

export type PayGroup = {
  __typename?: 'PayGroup'
  id: Scalars['ID']
  name: Scalars['String']
}

export type PayGroupAssignment = {
  __typename?: 'PayGroupAssignment'
  checkCodeId: Scalars['ID']
  locationIds: Array<Scalars['ID']>
  payGroupId: Scalars['ID']
  positionId: Scalars['ID']
  workTaxLocationId: Scalars['ID']
}

export type PayGroupAssignmentInput = {
  checkCodeId: Scalars['ID']
  locationIds: Array<Scalars['ID']>
  payGroupId: Scalars['ID']
  positionId: InputMaybe<Scalars['ID']>
  workTaxLocationId: Scalars['ID']
}

export type PayGroupCalendar = {
  __typename?: 'PayGroupCalendar'
  payGroupId: Scalars['String']
  payPeriods: Array<PayPeriodCalendar>
}

export type PayGroupCalendarResult = PayGroupCalendar | Unauthorized

export type PayGroupSetting = {
  __typename?: 'PayGroupSetting'
  bankAccount: Maybe<BankAccount>
  name: Scalars['String']
  payFrequencies: Array<PayFrequencies>
  weekStartDay: Maybe<Scalars['String']>
}

export type PayGroupWithFrequency = {
  __typename?: 'PayGroupWithFrequency'
  checkCodeId: Scalars['ID']
  id: Scalars['String']
  name: Scalars['String']
  payGroupId: Scalars['ID']
  periodsPerYear: Scalars['Int']
}

export type PayHistoryAudit = {
  __typename?: 'PayHistoryAudit'
  paging: Paged
  records: Maybe<Array<PayHistoryAuditRecord>>
}

export type PayHistoryAuditRecord = {
  __typename?: 'PayHistoryAuditRecord'
  action: Scalars['String']
  modifiedBy: Scalars['String']
  modifiedDate: Scalars['SpaDateTime']
  payPeriod: Scalars['String']
}

export type PayHistoryFilters = {
  feinId: InputMaybe<Scalars['Int']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
}

export type PayHistoryPage = {
  __typename?: 'PayHistoryPage'
  FeinList: Array<FeinOption>
  employeeUuid: Scalars['ID']
  latest: Maybe<PayDetails>
  paging: Paged
  payHistory: Maybe<Array<PayDetails>>
}

export enum PayHistoryReportType {
  DETAIL = 'DETAIL',
  SUMMARY = 'SUMMARY'
}

export enum PayInterval {
  ANNUAL = 'ANNUAL',
  HOUR = 'HOUR'
}

export type PayPeriod = {
  __typename?: 'PayPeriod'
  adjustmentUrl: Scalars['String']
  checkCode: CheckCode
  checkCodeName: Scalars['String']
  checkCodeUuid: Scalars['String']
  checkDate: Scalars['SpaDate']
  checkDateOverride: Maybe<Scalars['SpaDate']>
  checkPackages: Maybe<Array<Maybe<CheckPackage>>>
  dashboardUrl: Scalars['String']
  datePosted: Maybe<Scalars['SpaDate']>
  debitDate: Maybe<Scalars['SpaDate']>
  deliveryMethod: DeliveryMethod
  dueDate: Maybe<Scalars['SpaDateTime']>
  employeesSpreadsheetUrl: Scalars['String']
  endDate: Scalars['SpaDate']
  finalCheckDate: Maybe<Scalars['SpaDate']>
  forceLiveChecks: Scalars['Boolean']
  isManual: Scalars['Boolean']
  isReadOnly: Maybe<Scalars['Boolean']>
  listUrl: Scalars['String']
  messages: Array<Message>
  name: Scalars['String']
  paperChecksSettingsUrl: Scalars['String']
  payGroupUuids: Array<Scalars['String']>
  /** @deprecated Replaced by combination of dueDate and postDeadline. */
  payrollDate: Maybe<Scalars['SpaDateTime']>
  postDeadline: Scalars['Int']
  postDeadlineText: PostDeadline
  preflightUrl: Scalars['String']
  previewUrl: Scalars['String']
  previousPayPeriodEndDate: Maybe<Scalars['SpaDate']>
  previousPayPeriodStartDate: Maybe<Scalars['SpaDate']>
  previousPayPeriodUuid: Maybe<Scalars['String']>
  previousProviderPayrollType: Maybe<PreviousProviderPayrollType>
  reviewUrl: Scalars['String']
  startDate: Scalars['SpaDate']
  status: Scalars['Int']
  taxDebitDate: Scalars['SpaDate']
  taxesImportUrl: Scalars['String']
  timesheetsImportUrl: Scalars['String']
  timesheetsImportUrlAloha: Maybe<Scalars['String']>
  timesheetsUrl: Scalars['String']
  toastPrintedChecks: Maybe<ToastPrinted>
  uuid: Scalars['String']
}

export type PayPeriodAlert = {
  __typename?: 'PayPeriodAlert'
  count: Scalars['Int']
  type: PayPeriodAlertType
}

export enum PayPeriodAlertType {
  UNAPPROVED_TIME = 'UNAPPROVED_TIME',
  UNRECEIVED_TIPS = 'UNRECEIVED_TIPS'
}

export type PayPeriodCalendar = {
  __typename?: 'PayPeriodCalendar'
  checkDate: Scalars['Date']
  isArchived: Scalars['Boolean']
  startDate: Scalars['Date']
  status: PayrollStatusV2
}

export type PayStub = {
  __typename?: 'PayStub'
  /** @deprecated This function causes contention on the ec-web servers. Use the downloadUrl and pdfDownloadUrl instead */
  DownloadURL: Scalars['String']
  business: Maybe<Scalars['String']>
  checkDate: Scalars['SpaDate']
  checkFile: Scalars['String']
  downloadUrl: Scalars['String']
  employeeUuid: Scalars['ID']
  /** @deprecated if you want the name, use business. If you want fein number, use feinNumber */
  fein: Maybe<Scalars['String']>
  feinNumber: Maybe<Scalars['String']>
  payPeriod: Scalars['String']
  payPeriodId: Scalars['LegacyId']
  payStubNumber: Scalars['Int']
  pdfDownloadUrl: Scalars['String']
}

export type PayStubDownloadUrlArgs = {
  isPDF: InputMaybe<Scalars['Boolean']>
}

export type Paycheck = {
  __typename?: 'Paycheck'
  date: Maybe<Scalars['Date']>
}

export type PaymentFrequencyAudit = {
  __typename?: 'PaymentFrequencyAudit'
  createdAt: Scalars['DateTime']
  description: Scalars['String']
  effectiveDate: Scalars['Date']
  email: Maybe<Scalars['String']>
  name: Scalars['String']
  uuid: Scalars['String']
}

export type Payroll = {
  __typename?: 'Payroll'
  dueDate: Maybe<Scalars['DateTime']>
  endDate: Scalars['Date']
  id: Scalars['ID']
  name: Scalars['String']
  payGroups: Array<PayrollPayGroup>
  startDate: Scalars['Date']
  status: PayrollStatusV2
}

export type PayrollCheckCode = {
  __typename?: 'PayrollCheckCode'
  id: Scalars['ID']
  name: Scalars['String']
  periodsPerYear: Scalars['Int']
}

export type PayrollCustomerUser = {
  __typename?: 'PayrollCustomerUser'
  companyCode: Scalars['String']
  customerId: Scalars['ID']
  customerName: Scalars['String']
  email: Scalars['String']
  id: Scalars['ID']
  shardName: Scalars['String']
  toastIdentityGuid: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export enum PayrollCustomerUserSource {
  DB = 'DB',
  DEX = 'DEX'
}

export type PayrollCustomerUsersInput = {
  source: PayrollCustomerUserSource
  toastIdentityGuid: InputMaybe<Scalars['ID']>
  userId: InputMaybe<Scalars['ID']>
  username: InputMaybe<Scalars['String']>
}

/**
 * Represents employees tied to a payroll with a paycheck in a payperiod.
 * Corresponds to PayrollEmployeeSummary type in ec-api.
 */
export type PayrollEmployee = {
  __typename?: 'PayrollEmployee'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']
}

export type PayrollEmployeeMapping = {
  __typename?: 'PayrollEmployeeMapping'
  name: PersonName
  payrollEmployeeNumber: Maybe<Scalars['String']>
}

export type PayrollEmployeePosition = {
  __typename?: 'PayrollEmployeePosition'
  isPrimary: Scalars['Boolean']
  levels: Array<Maybe<PayrollEmployeePositionLevel>>
  uuid: Scalars['String']
}

export type PayrollEmployeePositionLevel = {
  __typename?: 'PayrollEmployeePositionLevel'
  sequenceId: Scalars['Int']
  uuid: Maybe<Scalars['String']>
}

/**
 * Used to represent data for all salaried or hourly employees, regardless of whether they will really appear in paycheck.
 * The type is only used internally, and should be converted to PayrollEmployee if being served publicly.
 */
export type PayrollEmployeeScheduled = {
  __typename?: 'PayrollEmployeeScheduled'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  salaryType: Maybe<SalaryType>
  timesheetTiedToPayrollSetting: Maybe<TimeSheetTiedToPayroll>
  validSsn: Scalars['Boolean']
}

export type PayrollEmployeeV2 = {
  __typename?: 'PayrollEmployeeV2'
  employeeNumber: Scalars['String']
  employeeUuid: Scalars['String']
  legacyEmployeeId: Scalars['String']
  legacyId: Scalars['ID']
  name: Scalars['String']
  payPeriodUuid: Scalars['String']
  paystubs: Array<PayCheckV2>
}

export enum PayrollEmploymentStatus {
  ACTIVE = 'ACTIVE',
  LEAVE_OF_ABSENCE = 'LEAVE_OF_ABSENCE',
  OTHER = 'OTHER',
  TERMINATED = 'TERMINATED'
}

export type PayrollFein = {
  __typename?: 'PayrollFein'
  benefits: Array<ThirdPartyBenefit>
  fein: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type PayrollImportResponse = ResponseError | ResponseSuccess

export type PayrollJob = {
  __typename?: 'PayrollJob'
  name: Scalars['String']
  uuid: Scalars['ID']
}

export type PayrollLevelOption = {
  __typename?: 'PayrollLevelOption'
  isOrphan: Scalars['Boolean']
  name: Scalars['String']
  sequenceId: Scalars['Int']
  uuid: Scalars['String']
}

export type PayrollLocation = {
  __typename?: 'PayrollLocation'
  exportId: Maybe<Scalars['ID']>
  id: Scalars['ID']
  jobs: Array<Job>
  name: Scalars['String']
}

export type PayrollPayGroup = {
  __typename?: 'PayrollPayGroup'
  id: Scalars['ID']
  name: Scalars['String']
}

export enum PayrollPermission {
  EDIT_PAYROLL = 'EDIT_PAYROLL',
  EDIT_TAX_CENTER = 'EDIT_TAX_CENTER',
  VIEW_PAYROLL = 'VIEW_PAYROLL',
  VIEW_TAX_CENTER = 'VIEW_TAX_CENTER'
}

export type PayrollRequirement = {
  __typename?: 'PayrollRequirement'
  cashRequirement: CashRequirement
  nonCashRequirement: NonCashRequirement
}

export type PayrollSecurityRole = {
  __typename?: 'PayrollSecurityRole'
  id: Scalars['ID']
  name: Scalars['String']
}

export enum PayrollSetting {
  AUTO_APPROVE_TIMESHEETS = 'AUTO_APPROVE_TIMESHEETS',
  DEADLINE_ENFORCEMENT = 'DEADLINE_ENFORCEMENT',
  IMPORT_TIMESHEET_EARNING_BY_WEEK = 'IMPORT_TIMESHEET_EARNING_BY_WEEK',
  MATCH_TIMESHEET_TO_PAYROLL = 'MATCH_TIMESHEET_TO_PAYROLL',
  PAYROLL_PAYOR = 'PAYROLL_PAYOR',
  POST_DEADLINE = 'POST_DEADLINE',
  USE_BLENDED_OT = 'USE_BLENDED_OT'
}

export type PayrollSettings = {
  __typename?: 'PayrollSettings'
  autoApproveTimesheets: Maybe<Scalars['Boolean']>
  /** @deprecated shouldn't be used in payroll setting domain only customer */
  customerPrintsW2: Maybe<Scalars['Boolean']>
  deadlineEnforcement: Maybe<Scalars['Boolean']>
  importTimesheetEarningsByWeek: Maybe<Scalars['Boolean']>
  matchTimesheetToPayperiod: Maybe<Scalars['Boolean']>
  payrollPayor: Maybe<IsPayrollPayor>
  payrollPostDeadline: PostDeadline
  useBlendedOT: Maybe<Scalars['Boolean']>
}

export type PayrollSettingsAudit = {
  __typename?: 'PayrollSettingsAudit'
  by: AuditUser
  byStamp: Scalars['String']
  current: Scalars['Boolean']
  id: Scalars['ID']
  on: Scalars['DateTime']
  previous: Scalars['Boolean']
}

export enum PayrollStatus {
  calculated = 'calculated',
  created = 'created',
  editing = 'editing',
  generated_ach = 'generated_ach',
  import = 'import',
  opened = 'opened',
  posted = 'posted',
  posting = 'posting',
  processing = 'processing',
  scheduled = 'scheduled',
  scheduling = 'scheduling',
  unknown = 'unknown',
  verified = 'verified'
}

export enum PayrollStatusV2 {
  CALCULATED = 'CALCULATED',
  CREATED = 'CREATED',
  EDITING = 'EDITING',
  EMPLOYEE_MAINTENANCE = 'EMPLOYEE_MAINTENANCE',
  G2N = 'G2N',
  GENERATED_ACH = 'GENERATED_ACH',
  IMPORT = 'IMPORT',
  OPENED = 'OPENED',
  POSTED = 'POSTED',
  POSTING = 'POSTING',
  POST_PAYROLL = 'POST_PAYROLL',
  PROCESSING = 'PROCESSING',
  PR_MAINTENANCE = 'PR_MAINTENANCE',
  REPORTS = 'REPORTS',
  SCHEDULED = 'SCHEDULED',
  SCHEDULING = 'SCHEDULING',
  SPREADSHEET = 'SPREADSHEET',
  STATUS_CHANGES = 'STATUS_CHANGES',
  UNKNOWN = 'UNKNOWN',
  VACATION = 'VACATION',
  VERIFIED = 'VERIFIED'
}

export type PayrollTotalRequirements = {
  __typename?: 'PayrollTotalRequirements'
  cashRequirement: Scalars['Float']
  nonCashRequirement: Scalars['Float']
  nonDebitedTaxes: Scalars['Float']
}

export type PayrollUser = {
  __typename?: 'PayrollUser'
  customerId: Scalars['ID']
  email: Maybe<Scalars['String']>
  employeeId: Maybe<Scalars['ID']>
  id: Scalars['ID']
  toastIdentityGuid: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export type PayrollUserMutations = {
  __typename?: 'PayrollUserMutations'
  changeLinkedUsername: Array<ChangeLinkedUsernameResult>
  changeUnlinkedUsername: ChangeUnlinkedUsernameResult
  link: LinkUserResult
  linkIfMapped: Array<LinkIfMappedResult>
  register: UserRegistrationStatusResult
  unlink: UnlinkUserResult
}

export type PayrollUserMutationsChangeLinkedUsernameArgs = {
  toastIdentityGuid: Scalars['ID']
  username: Scalars['String']
}

export type PayrollUserMutationsChangeUnlinkedUsernameArgs = {
  customerId: Scalars['ID']
  userId: Scalars['ID']
  username: Scalars['String']
}

export type PayrollUserMutationsLinkArgs = {
  customerId: Scalars['ID']
  toastIdentityGuid: Scalars['ID']
  userId: Scalars['ID']
}

export type PayrollUserMutationsRegisterArgs = {
  companyCode: Scalars['String']
  password: InputMaybe<Scalars['String']>
  registrationId: Scalars['ID']
}

export type PayrollUserMutationsUnlinkArgs = {
  customerId: Scalars['ID']
  userId: Scalars['ID']
}

export type PayrollUserQuery = {
  __typename?: 'PayrollUserQuery'
  userRegistrationStatus: UserRegistrationStatusResult
}

export type PayrollUserQueryUserRegistrationStatusArgs = {
  companyCode: Scalars['String']
  registrationId: Scalars['ID']
}

export type PayrollWorkTaxLocation = {
  __typename?: 'PayrollWorkTaxLocation'
  address: PayrollWorkTaxLocationAddress
  fein: Scalars['Int']
  id: Scalars['ID']
  name: Scalars['String']
}

export type PayrollWorkTaxLocationAddress = {
  __typename?: 'PayrollWorkTaxLocationAddress'
  city: Maybe<Scalars['String']>
  country: Maybe<Scalars['String']>
  gnisCode: Maybe<Scalars['String']>
  line1: Maybe<Scalars['String']>
  line2: Maybe<Scalars['String']>
  line3: Maybe<Scalars['String']>
  state: Maybe<Scalars['String']>
  zipCode: Maybe<Scalars['String']>
}

export type PendingEmploymentStatus = EmploymentStatus & {
  __typename?: 'PendingEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type PendingPayChange = {
  __typename?: 'PendingPayChange'
  effectiveDate: Scalars['Date']
  id: Scalars['ID']
  rate: Money
  status: PendingPayChangeStatus
}

export type PendingPayChangeNotFoundError = {
  __typename?: 'PendingPayChangeNotFoundError'
  message: Maybe<Scalars['String']>
}

export type PendingPayChangeNotFoundErrors = {
  __typename?: 'PendingPayChangeNotFoundErrors'
  pendingPayChangeNotFoundErrors: Array<PendingPayChangeNotFoundError>
}

export enum PendingPayChangeStatus {
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  ERROR_ACKNOWLEDGED = 'ERROR_ACKNOWLEDGED',
  PENDING = 'PENDING'
}

export type PendingPayChangeStatusInput = {
  pendingPayChangeId: Scalars['ID']
  pendingPayChangeStatus: PendingPayChangeStatus
}

export type PendingPayChangeStatusResponse = {
  __typename?: 'PendingPayChangeStatusResponse'
  pendingPayChanges: Array<PendingPayChange>
}

export type Person = {
  __typename?: 'Person'
  chosenName: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber: Maybe<Scalars['String']>
  posAccessCode: Scalars['Int']
  ssn: Maybe<Scalars['String']>
}

export type PersonInput = {
  chosenName: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber: InputMaybe<Scalars['String']>
  posAccessCode: InputMaybe<Scalars['Int']>
  ssn: InputMaybe<Scalars['String']>
}

export type PersonName = {
  __typename?: 'PersonName'
  chosen: Maybe<Scalars['String']>
  first: Maybe<Scalars['String']>
  formatted: Maybe<Scalars['String']>
  last: Maybe<Scalars['String']>
  middle: Maybe<Scalars['String']>
}

export type PinOnlyUserMergeFailed = {
  __typename?: 'PinOnlyUserMergeFailed'
  message: Scalars['String']
}

export type PinOnlyUserMergeResult =
  | PinOnlyUserMergeFailed
  | PinOnlyUserMergeSuccess

export type PinOnlyUserMergeSuccess = {
  __typename?: 'PinOnlyUserMergeSuccess'
  toastIdentityGuid: Array<Scalars['ID']>
}

export type PosAvailabilityRequest = {
  exportIds: Array<Scalars['ID']>
  posAccessCode: Scalars['Int']
}

export type PosEmployee = {
  __typename?: 'PosEmployee'
  archived: Scalars['Boolean']
  email: Scalars['String']
  externalEmployeeId: Maybe<Scalars['String']>
  mappedToEmployee: Maybe<PayrollEmployeeMapping>
  mostRecentClockInDate: Maybe<Scalars['Date']>
  restaurantUserGuid: Scalars['ID']
  userName: PersonName
}

export type PosRawEmployee = {
  __typename?: 'PosRawEmployee'
  archived: Scalars['Boolean']
  disabled: Scalars['Boolean']
  email: Scalars['String']
  locationGuid: Scalars['ID']
  payrollLocationId: Scalars['Int']
  restaurantUserGuid: Scalars['ID']
  userGuid: Scalars['ID']
  userName: PersonName
}

export type PosUser = {
  __typename?: 'PosUser'
  email: Scalars['String']
  restaurantUsers: Array<RestaurantUser>
  userGuid: Scalars['ID']
  userName: PersonName
}

export enum PostDeadline {
  DayOfPayDate = 'DayOfPayDate',
  FiveDaysBefore = 'FiveDaysBefore',
  FourDaysBefore = 'FourDaysBefore',
  NotAllowedToPost = 'NotAllowedToPost',
  OneDayBefore = 'OneDayBefore',
  ThreeDaysBefore = 'ThreeDaysBefore',
  TwoDaysBefore = 'TwoDaysBefore'
}

export type PostError = MutationError & {
  __typename?: 'PostError'
  errors: Array<Error>
  status: ResponseStatus
}

export type PostResponse = PostError | PostSuccess

export type PostSuccess = MutationSuccess & {
  __typename?: 'PostSuccess'
  status: ResponseStatus
}

export type PostalAddress = {
  __typename?: 'PostalAddress'
  administrativeArea: Scalars['String']
  country: Country
  locality: Scalars['String']
  postalCode: Scalars['String']
  streetAddress: Scalars['String']
  streetAddress2: Maybe<Scalars['String']>
}

export type PreviousPayrollProviderData = {
  __typename?: 'PreviousPayrollProviderData'
  parallels: Array<Parallel>
  priors: Array<Priors>
}

export enum PreviousProviderPayrollType {
  current_quarter_prior = 'current_quarter_prior',
  none = 'none',
  parallel = 'parallel'
}

export type PreviousYearUnprocessedPayrolls = {
  __typename?: 'PreviousYearUnprocessedPayrolls'
  canWriteToAllPayrolls: Scalars['Boolean']
  totalCount: Scalars['Int']
  unprocessedPayrolls: Maybe<Array<UnprocessedPayroll>>
}

export type PreviousYearUnprocessedPayrollsQuerySuccess = QuerySuccess & {
  __typename?: 'PreviousYearUnprocessedPayrollsQuerySuccess'
  payrolls: PreviousYearUnprocessedPayrolls
  status: ResponseStatus
}

export type PreviousYearUnprocessedPayrollsResponse =
  | PreviousYearUnprocessedPayrollsQuerySuccess
  | QueryErrorResponse

export type Priors = {
  __typename?: 'Priors'
  earnings: Array<EmployeeEarning>
  feinName: Scalars['String']
  feinTin: Scalars['String']
  feinUuid: Scalars['String']
  taxes: Array<EmployeeTaxes>
}

export type Query = {
  __typename?: 'Query'
  PayHistory: PayHistoryPage
  PayHistoryAudit: PayHistoryAudit
  PayHistoryReport: Scalars['String']
  PayHistoryReportPDF: Scalars['String']
  accessibleCustomers: AccessibleCustomersResult
  activeEarningCodes: Array<EarningCode>
  actorPermissionsForEmployees: Array<ActorPermissionsForEmployee>
  aggregatedInvoices: AggregateInvoiceResult
  audit: Array<Audit>
  bankConsentStatus: Maybe<Array<BankAccountConsentInfo>>
  checkAvailability: AvailabilityResponse
  checkCodes: Array<CheckCode>
  checkCodesConfig: CheckCodesConfigResponse
  checkPosAvailability: Maybe<Scalars['Boolean']>
  companySummary: CompanySummary
  completeDirectDeposits: CompleteDirectDepositsResponse
  customer: Customer
  customerBenefits: Maybe<Array<Maybe<Benefit>>>
  customerConfiguration: Maybe<CustomerConfiguration>
  customerSettings: CustomerSettings
  directDepositEmailTemplate: Maybe<Scalars['String']>
  downloadForm8655: Form8655Response
  downloadForm8655ByDateRange: Form8655Response
  downloadForm8655ByFeins: Form8655Response
  earningCodes: Array<EarningCode>
  emailVerification: Maybe<EmailVerificationQuery>
  /** @deprecated Please use findEmployeeById */
  employee: Employee
  employeeDocumentUploadUrl: EmployeeNewHireDocument
  employeePositions: Array<PayrollEmployeePosition>
  employeeStatusTypeCount: EmployeeStatusTypeCount
  enforceDeadline: Scalars['Boolean']
  estimatedWithdrawal: PayrollRequirement
  feins: Array<Fein>
  findCustomerSettings: Array<CustomerSetting>
  findEmployeeById: Maybe<Employee>
  findEmployeeForRestaurantByToastIdentityGuid: Maybe<Employee>
  /** @deprecated Please use findEmployeeById */
  findEmployeePay: Maybe<EmployeePay>
  findEmployeesByCustomerUuid: EmployeePaginationResponse
  findJobMappingByJobId: Maybe<JobMapping>
  findJobMappingsByLocationId: Array<JobMapping>
  findPayrollEarningLevels: Array<LevelOption>
  findPayrollJobsByLocationId: Array<PayrollJob>
  findRestaurantJobsByRestaurantId: Array<RestaurantJob>
  findUserByEmployeeId: Maybe<User>
  findUserById: Maybe<User>
  findUserEventsByUserId: Array<UserEvent>
  /** @deprecated Please use findUserById */
  findUsers: Maybe<Users>
  firstPayrollDate: Maybe<Scalars['Date']>
  getAllForm8655Data: Array<Form8655>
  getApprovedExpenseReports: Array<ExpenseReport>
  getEmployeeComparison: EmployeeComparison
  getEmployeeMappingStatus: EmployeeMappingStatus
  getLevel: Array<PayrollLevelOption>
  /**
   * ALL hourly and salaried associated with a payroll
   * Will only include salaried employees; and hourly employees with a timesheet in the pay period
   * Should NOT be used for periods prior to the latest/currently open, as those will not properly incorporate employees from timesheets
   * See getEmployeeComparison for that use case instead
   */
  getPayrollEmployees: Array<PayrollEmployee>
  getPayrollEmployeesPosted: Array<PayrollEmployee>
  getPosMappingOptions: Array<PosEmployee>
  getPosUser: Array<PosRawEmployee>
  getPosUserV2: Maybe<PosUser>
  getUnmappedPosUsers: Array<PosRawEmployee>
  helloFromBenefits: Scalars['String']
  helloWorld: Response
  incompleteDirectDeposits: IncompleteDirectDepositsResponse
  intercomSessionData: Maybe<IntercomSessionDataResponse>
  isW2Ready: Maybe<Scalars['Boolean']>
  jobAssignmentsById: Array<JobAssignment>
  jobs: Array<JobType>
  levelsConfig: Array<LevelConfig>
  loadTaxChecklist: TaxPrepChecklistState
  missingSsnEmployees: Array<FeinMissingSsnEmployee>
  navigableTaxAccounts: NavigableTaxAccountResult
  navigation: NavigationResult
  newHireRequiredDocumentsForAssignedFields: Array<NewHireDocument>
  nextAvailableEmployeeNumber: Maybe<Scalars['Int']>
  numRunPayrollsInThePastYear: Maybe<Scalars['Int']>
  pastPayrolls: PastPayrollPagedResult
  payCardCustomerAccess: PayCardCustomerAccess
  payCardCustomerSettings: PayCardCustomerSettings
  payCardCustomerSettingsAuditHistory: Array<PayCardCustomerSettingsAuditEntry>
  payCardCustomerSettingsLastEvent: Maybe<PayCardCustomerSettingsAuditEntry>
  payCardEmployeeAccess: PayCardEmployeeAccess
  /** Pay change log for the specified employee. */
  payChangeLog: Maybe<PayChangeLogResponse>
  payGroupCalendarByDateRange: PayGroupCalendarResult
  payPeriodAlerts: Array<PayPeriodAlert>
  /** retrieves the paycheck shipping settings for a customer */
  paycheckDeliverySettings: PayCheckDeliverySettings
  paymentFrequencyAudit: Array<PaymentFrequencyAudit>
  payperiod: PayPeriod
  payrollCustomer: Customer
  payrollCustomerUsers: Array<PayrollCustomerUser>
  payrollEmployeeList: Array<PayrollEmployeeV2>
  payrollOnboardingPermissions: Array<OnboardingCheckListPermission>
  payrollPermissions: Array<PayrollPermission>
  payrollRequirement: PayrollTotalRequirements
  payrollSettings: PayrollSettings
  payrollSettingsAudit: Array<PayrollSettingsAudit>
  payrollUser: PayrollUserQuery
  /** @deprecated Use payrollsByYearQuarter instead */
  payrollsByQuarter: Array<Payroll>
  payrollsByYearQuarter: Array<Payroll>
  previousPayrollProviderData: Maybe<PreviousPayrollProviderData>
  previousYearUnprocessedPayrolls: PreviousYearUnprocessedPayrollsResponse
  requiredDocumentationCustomFields: Array<CustomDocumentFields>
  requiredDocumentationCustomFieldsV2: Array<CustomDocumentFieldsV2>
  restaurant: Maybe<Restaurant>
  retroPayForHourlyPayChange: Maybe<HourlyRetroPayResponse>
  retroPayForSalaryChange: Maybe<SalaryRetroPayResponse>
  sections: SectionResult
  sutaRateAudit: Array<SutaRateAudit>
  taxPackages: Array<TaxPackage>
  timesheetAggregates: TimesheetAggregateResponse
  /**
   * Gets the timesheet data for a payperiod.
   * NOTE: The consumer is responsible for any necessary filtering
   * (e.g. checking if timesheets have been imported when considering previous payrolls)
   */
  timesheets: Array<TimesheetByPayrollAggregate>
  tipsDetails: Maybe<Array<TipsPerDay>>
  tipsIntegrationSettings: TipsIntegrationSettings
  /** @deprecated Use upcomingPayrolls instead */
  upcoming: Array<UpcomingPayroll>
  upcomingPayrolls: Array<UpcomingPayroll>
  userMigration: Maybe<UserMigrationQuery>
  w2DownloadUrl: Scalars['String']
  w2Packets: Array<W2Packet>
  w2Settings: CustomerW2Settings
}

export type QueryPayHistoryArgs = {
  employeeUuid: Scalars['ID']
  filters: InputMaybe<PayHistoryFilters>
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
}

export type QueryPayHistoryAuditArgs = {
  employeeUuid: Scalars['ID']
  first: InputMaybe<Scalars['Int']>
  offset: InputMaybe<Scalars['Int']>
}

export type QueryPayHistoryReportArgs = {
  employeeUuid: Scalars['ID']
  feinId: InputMaybe<Scalars['LegacyId']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
  type: PayHistoryReportType
}

export type QueryPayHistoryReportPdfArgs = {
  employeeUuid: Scalars['ID']
  feinId: InputMaybe<Scalars['LegacyId']>
  from: InputMaybe<Scalars['SpaDateTime']>
  to: InputMaybe<Scalars['SpaDateTime']>
  type: PayHistoryReportType
}

export type QueryAccessibleCustomersArgs = {
  input: InputMaybe<AccessibleCustomersInput>
}

export type QueryActiveEarningCodesArgs = {
  employmentTaxType: InputMaybe<EmploymentTaxType>
}

export type QueryActorPermissionsForEmployeesArgs = {
  employeeIds: Array<Scalars['ID']>
}

export type QueryAggregatedInvoicesArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryAuditArgs = {
  payrollId: Scalars['ID']
}

export type QueryBankConsentStatusArgs = {
  payPeriodId: InputMaybe<Scalars['ID']>
}

export type QueryCheckAvailabilityArgs = {
  request: AvailabilityRequest
}

export type QueryCheckPosAvailabilityArgs = {
  request: PosAvailabilityRequest
}

export type QueryCompleteDirectDepositsArgs = {
  completeDirectDepositInput: DirectDepositPaginationInput
}

export type QueryDownloadForm8655ByDateRangeArgs = {
  endDate: Scalars['Date']
  startDate: Scalars['Date']
}

export type QueryDownloadForm8655ByFeinsArgs = {
  feinUuids: Array<Scalars['String']>
}

export type QueryEmployeeArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryEmployeeDocumentUploadUrlArgs = {
  request: EmployeeDocumentUploadRequest
}

export type QueryEmployeePositionsArgs = {
  employeeUuid: Scalars['String']
  payPeriodUuid: Scalars['String']
}

export type QueryEnforceDeadlineArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryEstimatedWithdrawalArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryFindCustomerSettingsArgs = {
  settings: Array<Scalars['Int']>
}

export type QueryFindEmployeeByIdArgs = {
  employeeId: Scalars['ID']
}

export type QueryFindEmployeeForRestaurantByToastIdentityGuidArgs = {
  toastIdentityGuid: Scalars['ID']
  toastRestaurantGuid: Scalars['ID']
}

export type QueryFindEmployeePayArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryFindEmployeesByCustomerUuidArgs = {
  employeeInput: EmployeePaginationInput
}

export type QueryFindJobMappingByJobIdArgs = {
  jobId: Scalars['ID']
}

export type QueryFindJobMappingsByLocationIdArgs = {
  locationId: Scalars['ID']
}

export type QueryFindPayrollEarningLevelsArgs = {
  payCheckEarningUuid: Scalars['String']
  payPeriodUuid: Scalars['String']
}

export type QueryFindPayrollJobsByLocationIdArgs = {
  locationId: Scalars['ID']
}

export type QueryFindRestaurantJobsByRestaurantIdArgs = {
  restaurantId: Scalars['ID']
}

export type QueryFindUserByEmployeeIdArgs = {
  employeeId: Scalars['ID']
}

export type QueryFindUserByIdArgs = {
  userId: Scalars['ID']
}

export type QueryFindUserEventsByUserIdArgs = {
  input: FindUserEventsByUserIdInput
}

export type QueryFindUsersArgs = {
  companyCode: Scalars['ID']
  employeeUuid: Scalars['ID']
}

export type QueryFirstPayrollDateArgs = {
  feinUuid: Scalars['String']
}

export type QueryGetApprovedExpenseReportsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetEmployeeComparisonArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetEmployeeMappingStatusArgs = {
  employeeUuid: Scalars['ID']
}

export type QueryGetLevelArgs = {
  levelUuid: Scalars['String']
  sequenceId: Scalars['Int']
}

export type QueryGetPayrollEmployeesArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetPayrollEmployeesPostedArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryGetPosMappingOptionsArgs = {
  employeeUuid: Scalars['ID']
  locationUuid: Scalars['ID']
}

export type QueryGetPosUserArgs = {
  userGuid: Scalars['ID']
}

export type QueryGetPosUserV2Args = {
  userGuid: Scalars['ID']
}

export type QueryIncompleteDirectDepositsArgs = {
  incompleteDirectDepositInput: IncompleteDirectDepositInput
}

export type QueryIntercomSessionDataArgs = {
  intercomClientType: IntercomClientType
}

export type QueryIsW2ReadyArgs = {
  year: InputMaybe<Scalars['Int']>
}

export type QueryJobAssignmentsByIdArgs = {
  employeeId: Scalars['ID']
  jobAssignmentIds?: InputMaybe<Array<Scalars['ID']>>
}

export type QueryJobsArgs = {
  parentLevelUuid: InputMaybe<Scalars['String']>
}

export type QueryLoadTaxChecklistArgs = {
  datetime: InputMaybe<Scalars['String']>
}

export type QueryMissingSsnEmployeesArgs = {
  year: InputMaybe<Scalars['Int']>
}

export type QueryNavigableTaxAccountsArgs = {
  input: InputMaybe<NavigableTaxAccountsInput>
}

export type QueryNavigationArgs = {
  input: InputMaybe<NavigationInput>
}

export type QueryNewHireRequiredDocumentsForAssignedFieldsArgs = {
  request: RequiredDocumentsRequest
}

export type QueryPastPayrollsArgs = {
  pageIndex: InputMaybe<Scalars['Int']>
  pageSize: Scalars['Int']
  pageToken: InputMaybe<Scalars['String']>
}

export type QueryPayCardCustomerAccessArgs = {
  input: PayCardCustomerAccessInput
}

export type QueryPayCardEmployeeAccessArgs = {
  input: PayCardEmployeeAccessInput
}

export type QueryPayChangeLogArgs = {
  employeeId: Scalars['ID']
  pageNumber?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  pageToken?: InputMaybe<Scalars['String']>
  sort?: InputMaybe<Array<PayChangeLogSortInput>>
}

export type QueryPayGroupCalendarByDateRangeArgs = {
  endDate: InputMaybe<Scalars['Date']>
  payGroupId: Scalars['ID']
  startDate: InputMaybe<Scalars['Date']>
}

export type QueryPayPeriodAlertsArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryPaymentFrequencyAuditArgs = {
  einUuid: Scalars['String']
}

export type QueryPayperiodArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryPayrollCustomerUsersArgs = {
  input: InputMaybe<PayrollCustomerUsersInput>
}

export type QueryPayrollEmployeeListArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryPayrollRequirementArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryPayrollSettingsAuditArgs = {
  type: PayrollSetting
}

export type QueryPayrollsByQuarterArgs = {
  quarter: Scalars['Int']
}

export type QueryPayrollsByYearQuarterArgs = {
  quarter: Scalars['Int']
  year: Scalars['Int']
}

export type QueryPreviousPayrollProviderDataArgs = {
  year: InputMaybe<Scalars['Int']>
}

export type QueryPreviousYearUnprocessedPayrollsArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryRestaurantArgs = {
  restaurantGuid: Scalars['ID']
}

export type QueryRetroPayForHourlyPayChangeArgs = {
  hourlyRetroPayInput: HourlyRetroPayInput
}

export type QueryRetroPayForSalaryChangeArgs = {
  salaryRetroPayInput: SalaryRetroPayInput
}

export type QuerySectionsArgs = {
  input: InputMaybe<SectionsInput>
}

export type QuerySutaRateAuditArgs = {
  einUuid: Scalars['String']
  taxCode: Scalars['String']
}

export type QueryTaxPackagesArgs = {
  quarter: InputMaybe<Scalars['Int']>
  year: InputMaybe<Scalars['Int']>
}

export type QueryTimesheetAggregatesArgs = {
  payPeriodUuid: Scalars['String']
}

export type QueryTimesheetsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryTipsDetailsArgs = {
  payPeriodUuid: Scalars['ID']
}

export type QueryW2DownloadUrlArgs = {
  w2PacketUuid: Scalars['String']
}

export type QueryW2PacketsArgs = {
  year: Scalars['Int']
}

export type QueryW2SettingsArgs = {
  year: InputMaybe<Scalars['Int']>
}

export type QueryError = {
  errors: Array<Error>
  status: ResponseStatus
}

export type QueryErrorResponse = QueryError & {
  __typename?: 'QueryErrorResponse'
  errors: Array<Error>
  status: ResponseStatus
}

export type QuerySuccess = {
  status: ResponseStatus
}

export type RehireEmployeeNoChangesInput = {
  employeeId: Scalars['ID']
  rehireDate: Scalars['Date']
}

export type RehiredEmploymentStatus = EmploymentStatus & {
  __typename?: 'RehiredEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  dismissalPeriod: Maybe<DateRange>
  hireDate: Maybe<Scalars['String']>
  rehireDate: Maybe<Scalars['String']>
}

export type RequiredDocumentsRequest = {
  hireDate: Scalars['Date']
  securityRoleId: Scalars['ID']
  workTaxLocationIds: Array<Scalars['ID']>
}

export type Response = {
  __typename?: 'Response'
  response: Maybe<Scalars['String']>
}

export type ResponseError = MutationError & {
  __typename?: 'ResponseError'
  errors: Array<Error>
  status: ResponseStatus
}

export enum ResponseStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export type ResponseSuccess = MutationSuccess & {
  __typename?: 'ResponseSuccess'
  status: ResponseStatus
}

export type Restaurant = {
  __typename?: 'Restaurant'
  guid: Scalars['ID']
  name: Maybe<Scalars['String']>
}

export type RestaurantJob = {
  __typename?: 'RestaurantJob'
  guid: Scalars['ID']
  name: Scalars['String']
  status: RestaurantJobStatusCode
}

export enum RestaurantJobStatusCode {
  Available = 'Available',
  /** Restaurant job does not exist */
  Deleted = 'Deleted'
}

export type RestaurantUser = {
  __typename?: 'RestaurantUser'
  archived: Scalars['Boolean']
  jobs: Array<Scalars['ID']>
  locationGuid: Scalars['ID']
  restaurantUserGuid: Scalars['ID']
}

export type Salary = {
  __typename?: 'Salary'
  activeSalaryRateOfPay: Maybe<Money>
  employmentTaxType: Maybe<EmploymentTaxType>
  id: Scalars['ID']
  payFrequency: Maybe<PayFrequency>
  pendingPayChange: Maybe<PendingPayChange>
  salaryAllocations: Array<SalaryAllocation>
}

export type SalaryAllocation = {
  __typename?: 'SalaryAllocation'
  id: Scalars['ID']
  jobAssignment: JobAssignment
  rate: Money
}

export type SalaryAllocationNotFoundError = {
  __typename?: 'SalaryAllocationNotFoundError'
  message: Maybe<Scalars['String']>
}

export type SalaryNotFoundError = {
  __typename?: 'SalaryNotFoundError'
  message: Maybe<Scalars['String']>
}

export type SalaryPayFrequencyDoesNotExistError = {
  __typename?: 'SalaryPayFrequencyDoesNotExistError'
  message: Maybe<Scalars['String']>
}

export type SalaryPayFrequencyNoneError = {
  __typename?: 'SalaryPayFrequencyNoneError'
  message: Maybe<Scalars['String']>
}

export type SalaryRetroPay = {
  __typename?: 'SalaryRetroPay'
  payChangeDifference: AmountWithPayInterval
  payFrequency: PayFrequency
  payPeriodEarnings: Money
  retroPay: Money
}

export type SalaryRetroPayInput = {
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']
}

export type SalaryRetroPayResponse =
  | InvalidStartDateError
  | SalaryPayFrequencyDoesNotExistError
  | SalaryPayFrequencyNoneError
  | SalaryRetroPay
  | TimesheetRetrievalError

export enum SalaryType {
  commission = 'commission',
  draw = 'draw',
  hourly = 'hourly',
  salary = 'salary',
  ten99 = 'ten99',
  unknown = 'unknown',
  w2 = 'w2'
}

export type SaveDeduction = {
  amount: Scalars['Float']
  checkCodeUuid: Scalars['String']
  deductionCodeUuid: Scalars['String']
  employeeUuid: Scalars['String']
  isPercentage: Scalars['Boolean']
  paystub: Scalars['Int']
}

export type SaveDeductionError = MutationError & {
  __typename?: 'SaveDeductionError'
  errors: Array<Error>
  status: ResponseStatus
}

export type SaveDeductionResponse = SaveDeductionError | SaveDeductionSuccess

export type SaveDeductionSuccess = MutationSuccess & {
  __typename?: 'SaveDeductionSuccess'
  result: PayrollEmployeeV2
  status: ResponseStatus
}

export type SaveEarning = {
  amount: Scalars['Float']
  baseRate: Scalars['Float']
  checkCodeUuid: Scalars['String']
  earningCodeUuid: Scalars['String']
  employeeUuid: Scalars['String']
  hours: Scalars['Float']
  levels: InputMaybe<Array<LevelUuidSequence>>
  paystub: Scalars['Int']
  positionUuid: Scalars['String']
  rate: Scalars['Float']
  week: Scalars['Int']
}

export type SaveEarningError = MutationError & {
  __typename?: 'SaveEarningError'
  errors: Array<Error>
  status: ResponseStatus
}

export type SaveEarningResponse = SaveEarningError | SaveEarningSuccess

export type SaveEarningSuccess = MutationSuccess & {
  __typename?: 'SaveEarningSuccess'
  result: PayrollEmployeeV2
  status: ResponseStatus
}

export type SaveEmployeeSsnResponse = ResponseError | ResponseSuccess

export type SaveNewEmployeeResponse = {
  __typename?: 'SaveNewEmployeeResponse'
  employeeId: Scalars['ID']
}

export type SeasonalEmploymentStatus = EmploymentStatus & {
  __typename?: 'SeasonalEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
}

export type Section = {
  __typename?: 'Section'
  items: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']
  labelNew: LocalizableMessage
  name: Scalars['String']
}

export type SectionResult = {
  __typename?: 'SectionResult'
  errors: Array<NavigationErrors>
  result: Array<Section>
}

export type SectionsInput = {
  name: InputMaybe<Scalars['String']>
  params: InputMaybe<Array<NavigationParam>>
}

export enum SecurityRole {
  APPLICANT = 'APPLICANT',
  CUSTOM = 'CUSTOM',
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
  FIRED = 'FIRED',
  HR_EXECUTIVE = 'HR_EXECUTIVE',
  HR_PLUS = 'HR_PLUS',
  MANAGER = 'MANAGER',
  NEW_HIRE = 'NEW_HIRE',
  PAYROLL = 'PAYROLL',
  PAYROLL_OPERATIONS = 'PAYROLL_OPERATIONS',
  SUPERUSER = 'SUPERUSER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPER_ADMIN_LIGHT = 'SUPER_ADMIN_LIGHT'
}

export type SendEmailVerificationError =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch

export type SendVerificationEmailInput = {
  idempotencyKey: Scalars['String']
  source: InputMaybe<EmailVerificationSourceType>
}

export type SendVerificationEmailResult =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailSent

export type ShippingAddress = {
  __typename?: 'ShippingAddress'
  addressLine1: Scalars['String']
  addressLine2: Scalars['String']
  attention: Scalars['String']
  city: Scalars['String']
  recipientCompany: Scalars['String']
  shippingAddressUuid: Scalars['String']
  state: Scalars['String']
  stateName: Scalars['String']
  trackingNumber: Maybe<Scalars['String']>
  zipCode: Scalars['String']
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SplitDepositType {
  DOLLAR = 'DOLLAR',
  PERCENTAGE = 'PERCENTAGE'
}

export type StateInUnitedStates = {
  __typename?: 'StateInUnitedStates'
  abbreviation: Scalars['String']
  fipsCode: Scalars['Int']
  fullName: Scalars['String']
}

export type Supervisor = {
  __typename?: 'Supervisor'
  id: EmploymentId
  job: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type Supervisors = {
  __typename?: 'Supervisors'
  supervisors: Array<Supervisor>
}

export type SutaRate = {
  __typename?: 'SutaRate'
  code: Scalars['String']
  effectiveDate: Maybe<Scalars['Date']>
  excludeFromCombinedRate: Scalars['Boolean']
  isExempt: Scalars['Boolean']
  isMajor: Scalars['Boolean']
  name: Scalars['String']
  rate: Maybe<Scalars['Float']>
  updatedAt: Maybe<Scalars['DateTime']>
}

export type SutaRateAudit = {
  __typename?: 'SutaRateAudit'
  createdAt: Maybe<Scalars['DateTime']>
  effectiveDate: Scalars['Date']
  email: Maybe<Scalars['String']>
  id: Scalars['String']
  isExempt: Maybe<Scalars['Boolean']>
  name: Maybe<Scalars['String']>
  rate: Maybe<Scalars['Float']>
}

export enum TaskReopenColumn {
  Account = 'Account',
  Fein = 'Fein',
  State = 'State'
}

export type TaskReopenErrorDetails = {
  __typename?: 'TaskReopenErrorDetails'
  column: Maybe<TaskReopenColumn>
  details: TaskReopenErrorType
}

export enum TaskReopenErrorType {
  Invalid = 'Invalid',
  Missing = 'Missing',
  NotFound = 'NotFound',
  Other = 'Other'
}

export enum TaskStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  LOCKED = 'LOCKED',
  NOT_STARTED = 'NOT_STARTED',
  ON_HOLD = 'ON_HOLD',
  SKIPPED = 'SKIPPED'
}

export type TasksReopenError = {
  __typename?: 'TasksReopenError'
  account: Scalars['String']
  errors: Maybe<Array<TaskReopenErrorDetails>>
  key: Scalars['String']
  state: Scalars['String']
  tin: Scalars['String']
}

export type TasksReopenResponse = {
  __typename?: 'TasksReopenResponse'
  errors: Maybe<Array<TasksReopenError>>
  status: ResponseStatus
}

export type TasksRow = {
  account: Scalars['String']
  key: Scalars['String']
  state: Scalars['String']
  tin: Scalars['String']
}

export type TaxPackage = {
  __typename?: 'TaxPackage'
  fein: Scalars['String']
  fileName: Scalars['String']
  presignedLink: Scalars['String']
  tin: Scalars['String']
}

export type TaxPrepChecklistState = {
  __typename?: 'TaxPrepChecklistState'
  feins: Maybe<Array<TaxPrepFein>>
  quarter: Maybe<TaxPrepQuarter>
  status: TaxPrepChecklistStatus
  year: Maybe<Scalars['Int']>
}

export enum TaxPrepChecklistStatus {
  /**  Tax Status is in progress based one the internal tasks completion, relevant when users do not have access to see status across all tasks  */
  IN_PROGRESS = 'IN_PROGRESS',
  /**  Loading state, background-job running for tasks creation considerations  */
  LOADING = 'LOADING',
  /**  Tax Status is COMPLETED based one the internal tasks completion, relevant when users do not have access to see status across all tasks  */
  READY = 'READY',
  /**  Server is not available, race conditions events, etc  */
  UNAVAILABLE = 'UNAVAILABLE'
}

export type TaxPrepFein = {
  __typename?: 'TaxPrepFein'
  id: Scalars['ID']
  /** Returns false if filing start date is null or in future quarter, or filing end date is in past quarter  */
  isActive: Scalars['Boolean']
  /** Derived permissions from Pay Group will rule this field  */
  isRestricted: Scalars['Boolean']
  name: Scalars['String']
  /** Tasks by State requiring completion. */
  states: Array<TaxPrepState>
  /** Tasks directly associated to this FEIN requiring completion. Tasks that are security restricted will be filtered out of this list. Use tasksCount for the unrestricted total. */
  tasks: Array<TaxPrepTask>
  /** Number of total tasks directly associated to this FEIN Accurate regardless of security restrictions. It will ignore tasks derived by State, check states field for that */
  tasksCount: Scalars['Int']
  tin: Scalars['String']
}

export enum TaxPrepQuarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type TaxPrepState = {
  __typename?: 'TaxPrepState'
  code: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  /** Tasks directly associated to this State requiring completion. Tasks that are security restricted will be filtered out of this list. Use tasksCount for the unrestricted total. */
  tasks: Array<TaxPrepTask>
  /** Number of total tasks directly associated to this State in this FEIN Accurate regardless of security restrictions. */
  tasksCount: Scalars['Int']
}

export type TaxPrepTask = {
  __typename?: 'TaxPrepTask'
  isReopened: Scalars['Boolean']
  key: Scalars['String']
  status: TaskStatus
  type: TaxTaskType
}

export enum TaxTaskType {
  TAXCENTER_FORM_8655 = 'TAXCENTER_FORM_8655',
  TAXCENTER_MISSING_SSN = 'TAXCENTER_MISSING_SSN',
  TAXCENTER_PREVIOUS_PROVIDER = 'TAXCENTER_PREVIOUS_PROVIDER',
  TAXCENTER_STATE_PFML_TPA = 'TAXCENTER_STATE_PFML_TPA',
  TAXCENTER_STATE_SIT_ACCOUNT = 'TAXCENTER_STATE_SIT_ACCOUNT',
  TAXCENTER_STATE_SIT_FREQUENCY = 'TAXCENTER_STATE_SIT_FREQUENCY',
  TAXCENTER_STATE_SIT_TPA = 'TAXCENTER_STATE_SIT_TPA',
  TAXCENTER_STATE_SUTA_ACCOUNT = 'TAXCENTER_STATE_SUTA_ACCOUNT',
  TAXCENTER_STATE_SUTA_RATE = 'TAXCENTER_STATE_SUTA_RATE',
  TAXCENTER_STATE_SUTA_TPA = 'TAXCENTER_STATE_SUTA_TPA'
}

export type TerminatedEmploymentStatus = EmploymentStatus & {
  __typename?: 'TerminatedEmploymentStatus'
  adjustedHireDate: Maybe<Scalars['String']>
  hireDate: Maybe<Scalars['String']>
  lastDayOfEmployment: Maybe<Scalars['String']>
  lastDayToReceiveBenefits: Maybe<Scalars['String']>
}

export type ThirdPartyBenefit = {
  __typename?: 'ThirdPartyBenefit'
  benefitType: ThirdPartyBenefitType
}

export enum ThirdPartyBenefitType {
  DENTAL = 'DENTAL',
  LIFE = 'LIFE',
  MEDICAL = 'MEDICAL',
  VISION = 'VISION'
}

export enum TimeSheetStatus {
  approved = 'approved',
  deleted = 'deleted',
  denied = 'denied',
  open = 'open',
  pending_approval = 'pending_approval',
  returned = 'returned',
  unknown = 'unknown'
}

export enum TimeSheetStatusV2 {
  approved = 'approved',
  deleted = 'deleted',
  denied = 'denied',
  open = 'open',
  pending_approval = 'pending_approval',
  returned = 'returned',
  unknown = 'unknown'
}

export enum TimeSheetTiedToPayroll {
  all = 'all',
  no = 'no',
  ot = 'ot',
  tafwot = 'tafwot'
}

export type TimesheetAggregateError = QueryError & {
  __typename?: 'TimesheetAggregateError'
  errors: Array<Error>
  status: ResponseStatus
}

export type TimesheetAggregateResponse =
  | TimesheetAggregateError
  | TimesheetAggregateSuccess

export type TimesheetAggregateSuccess = QuerySuccess & {
  __typename?: 'TimesheetAggregateSuccess'
  status: ResponseStatus
  timesheetAggregates: Array<TimesheetByPayrollAggregateV2>
}

export type TimesheetByPayrollAggregate = {
  __typename?: 'TimesheetByPayrollAggregate'
  earningEntries: Array<TimesheetEarningEntry>
  employeeUuid: Scalars['String']
  isImported: Scalars['Boolean']
  status: Maybe<TimeSheetStatus>
}

export type TimesheetByPayrollAggregateV2 = {
  __typename?: 'TimesheetByPayrollAggregateV2'
  earningEntries: Array<TimesheetEarningEntryV2>
  employeeUuid: Scalars['String']
  isImported: Scalars['Boolean']
  status: Maybe<TimeSheetStatusV2>
}

export type TimesheetEarningEntry = {
  __typename?: 'TimesheetEarningEntry'
  amount: Maybe<Scalars['Float']>
  date: Maybe<Scalars['SpaDate']>
  earningCode: TimesheetEntryEarningCode
  hoursWorked: Maybe<Scalars['Float']>
  isOvertime: Scalars['Boolean']
}

export type TimesheetEarningEntryV2 = {
  __typename?: 'TimesheetEarningEntryV2'
  amount: Maybe<Scalars['Float']>
  date: Maybe<Scalars['Date']>
  earningCode: TimesheetEntryEarningCodeV2
  hoursWorked: Maybe<Scalars['Float']>
  isOvertime: Scalars['Boolean']
  uuid: Maybe<Scalars['String']>
}

export type TimesheetEntryEarningCode = {
  __typename?: 'TimesheetEntryEarningCode'
  isHourly: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  timeAwaySetupId: Scalars['Int']
  uuid: Scalars['ID']
}

export type TimesheetEntryEarningCodeV2 = {
  __typename?: 'TimesheetEntryEarningCodeV2'
  isHourly: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  timeAwaySetupId: Scalars['Int']
  uuid: Scalars['ID']
}

export type TimesheetRetrievalError = {
  __typename?: 'TimesheetRetrievalError'
  message: Maybe<Scalars['String']>
}

export enum TipsIntegrationSettings {
  NONE = 'NONE',
  NORMAL = 'NORMAL',
  POOLED = 'POOLED'
}

export type TipsPerDay = {
  __typename?: 'TipsPerDay'
  amount: Scalars['Float']
  date: Scalars['Date']
  tipsReadyStatus: TipsReadyStatus
}

export enum TipsReadyStatus {
  inProgress = 'inProgress',
  noTips = 'noTips',
  notMissingTips = 'notMissingTips',
  ready = 'ready'
}

export enum TipsType {
  none = 'none',
  normal = 'normal',
  pooled = 'pooled'
}

export type ToastPrinted = {
  __typename?: 'ToastPrinted'
  totalChecks: Scalars['Int']
  totalEmployees: Scalars['Int']
}

export type ToastVersionIgnored = {
  __typename?: 'ToastVersionIgnored'
  userId: Scalars['ID']
  version: Scalars['Long']
}

export type Unauthorized = {
  __typename?: 'Unauthorized'
  message: Scalars['String']
}

export type UnlinkUserResult = PayrollUser | UserNotFound | UserNotLinked

export type UnprocessedPayroll = {
  __typename?: 'UnprocessedPayroll'
  checkDate: Scalars['Date']
  endDate: Scalars['Date']
  id: Scalars['ID']
  name: Scalars['String']
  payFrequency: PayFrequency
  startDate: Scalars['Date']
}

export type UpcomingPayroll = {
  __typename?: 'UpcomingPayroll'
  dueDate: Maybe<Scalars['DateTime']>
  endDate: Scalars['Date']
  hasTips: Scalars['Boolean']
  id: Scalars['ID']
  manual: Scalars['Boolean']
  name: Scalars['String']
  payDate: Scalars['Date']
  startDate: Scalars['Date']
  status: PayrollStatusV2
  timesheetsCount: Scalars['Int']
  unApprovedTimesheetsCount: Scalars['Int']
  unReceivedTips: Scalars['Int']
}

export type UpdateEmployeePaymentMethodEmailResponse =
  | EmailInvalidError
  | EmailSendingError
  | EmployeeNotFoundError
  | EmployeePaymentMethodEmailResponse

export type UpdatePayCardAdminSettingsInput = {
  payCardAdminPayoutsLocked: InputMaybe<Scalars['Boolean']>
}

export type UpdatePayCardCustomerSettingsInput = {
  payCardAdminSettings: InputMaybe<UpdatePayCardAdminSettingsInput>
  payCardPayoutConfiguration: InputMaybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled: InputMaybe<Scalars['Boolean']>
  payCardTipsConfiguration: InputMaybe<PayCardTipsConfiguration>
}

export type UpdatePayCardCustomerSettingsResponse = {
  __typename?: 'UpdatePayCardCustomerSettingsResponse'
  payCardCustomerSettings: PayCardCustomerSettings
}

export type UpdatePendingPayChangeStatusResponse =
  | EmployeeNotFoundError
  | PendingPayChangeNotFoundErrors
  | PendingPayChangeStatusResponse

export type User = {
  __typename?: 'User'
  email: Maybe<Scalars['String']>
  employeeUuid: Maybe<Scalars['ID']>
  id: Scalars['ID']
  isEmailVerified: Scalars['Boolean']
  name: PersonName
  toastIdentityGuid: Maybe<Scalars['ID']>
  username: Scalars['String']
}

export type UserAlreadyLinked = {
  __typename?: 'UserAlreadyLinked'
  username: Scalars['String']
}

export type UserEvent =
  | UserLinkChanged
  | UserLinked
  | UserRegistered
  | UserRoleAdded
  | UserRoleRemoved
  | UserUnlinked
  | UsernameChanged
  | UsernameChangedWithConflict

export type UserLinkChanged = {
  __typename?: 'UserLinkChanged'
  actorId: Scalars['ID']
  customerId: Scalars['ID']
  newToastIdentityGuid: Scalars['ID']
  oldToastIdentityGuid: Scalars['ID']
  timestamp: Scalars['DateTime']
  userId: Scalars['ID']
  version: Scalars['Long']
}

export type UserLinked = {
  __typename?: 'UserLinked'
  actorId: Scalars['ID']
  customerId: Scalars['ID']
  timestamp: Scalars['DateTime']
  toastIdentityGuid: Scalars['ID']
  userId: Scalars['ID']
  version: Scalars['Long']
}

export type UserMigrationChooseAnotherUsername = {
  __typename?: 'UserMigrationChooseAnotherUsername'
  message: Scalars['String']
}

export type UserMigrationConfirmAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailConfirmationFailed
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export enum UserMigrationDefaultEmailSource {
  NEW = 'NEW',
  PAYROLL = 'PAYROLL',
  TOAST = 'TOAST'
}

export type UserMigrationDefaults = {
  __typename?: 'UserMigrationDefaults'
  canChangeEmail: Scalars['Boolean']
  email: Maybe<Scalars['String']>
  source: UserMigrationDefaultEmailSource
}

export type UserMigrationDefaultsResult =
  | UserMigrationDefaults
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationEmailConfirmationFailed = {
  __typename?: 'UserMigrationEmailConfirmationFailed'
  error: ConfirmVerificationEmailError
}

export type UserMigrationEmailExistsInCustomer = {
  __typename?: 'UserMigrationEmailExistsInCustomer'
  customerId: Scalars['ID']
}

export type UserMigrationEmailNotVerified = {
  __typename?: 'UserMigrationEmailNotVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']
}

export type UserMigrationEmailVerified = {
  __typename?: 'UserMigrationEmailVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']
  verifiedTime: Scalars['DateTime']
}

export type UserMigrationFailed = {
  __typename?: 'UserMigrationFailed'
  message: Scalars['String']
}

export type UserMigrationMigrateResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSuccess
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationMutation = {
  __typename?: 'UserMigrationMutation'
  confirmAccountVerificationEmail: UserMigrationConfirmAccountVerificationEmailResult
  mergePinOnlyUsers: PinOnlyUserMergeResult
  migrate: UserMigrationMigrateResult
  sendAccountVerificationEmail: UserMigrationSendAccountVerificationEmailResult
}

export type UserMigrationMutationConfirmAccountVerificationEmailArgs = {
  confirmationKey: Scalars['String']
  email: Scalars['String']
}

export type UserMigrationMutationMigrateArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type UserMigrationMutationSendAccountVerificationEmailArgs = {
  email: Scalars['String']
  idempotencyKey: Scalars['String']
  source: InputMaybe<EmailVerificationSourceType>
}

export type UserMigrationNotEnabled = {
  __typename?: 'UserMigrationNotEnabled'
  message: Scalars['String']
}

export type UserMigrationQuery = {
  __typename?: 'UserMigrationQuery'
  defaults: UserMigrationDefaultsResult
  settings: UserMigrationSettingsResult
  verifyAccountEmail: UserMigrationVerifyAccountEmailResult
}

export type UserMigrationQueryVerifyAccountEmailArgs = {
  email: Scalars['String']
}

export type UserMigrationSendAccountVerificationEmailFailed = {
  __typename?: 'UserMigrationSendAccountVerificationEmailFailed'
  error: Maybe<SendEmailVerificationError>
}

export type UserMigrationSendAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSendAccountVerificationEmailFailed
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound
  | UserMigrationVerificationEmailSent

export type UserMigrationSettings = {
  __typename?: 'UserMigrationSettings'
  canDefer: Scalars['Boolean']
}

export type UserMigrationSettingsResult =
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSettings

export type UserMigrationSuccess = {
  __typename?: 'UserMigrationSuccess'
  companyCodes: Array<Scalars['String']>
  email: Scalars['String']
  toastIdentityGuid: Scalars['ID']
  userId: Scalars['ID']
}

export enum UserMigrationToastAccountType {
  CREATED = 'CREATED',
  EXISTING = 'EXISTING',
  MAPPED = 'MAPPED'
}

export type UserMigrationToastUserHasCredentials = {
  __typename?: 'UserMigrationToastUserHasCredentials'
  toastIdentityGuid: Scalars['ID']
}

export type UserMigrationUserAlreadyMigrated = {
  __typename?: 'UserMigrationUserAlreadyMigrated'
  toastIdentityGuid: Scalars['ID']
  userId: Scalars['ID']
}

export type UserMigrationUserNotFound = {
  __typename?: 'UserMigrationUserNotFound'
  userId: Scalars['ID']
}

export type UserMigrationVerificationEmailSent = {
  __typename?: 'UserMigrationVerificationEmailSent'
  email: Scalars['String']
  eventTime: Scalars['DateTime']
  expirationTime: Scalars['DateTime']
  idempotencyKey: Scalars['ID']
}

export type UserMigrationVerifyAccountEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserNotFound = {
  __typename?: 'UserNotFound'
  userId: Scalars['ID']
}

export type UserNotLinked = {
  __typename?: 'UserNotLinked'
  userId: Scalars['ID']
}

export type UserNotMapped = {
  __typename?: 'UserNotMapped'
  toastIdentityGuid: Scalars['ID']
}

export type UserRegistered = {
  __typename?: 'UserRegistered'
  registeredAt: Scalars['DateTime']
  toastIdentityGuid: Scalars['ID']
  userId: Scalars['ID']
}

export type UserRegistrationCheckFailed = {
  __typename?: 'UserRegistrationCheckFailed'
  message: Scalars['String']
  registrationId: Scalars['ID']
}

export type UserRegistrationEmailNotVerified = {
  __typename?: 'UserRegistrationEmailNotVerified'
  email: Scalars['String']
  message: Scalars['String']
  registrationId: Scalars['ID']
}

export type UserRegistrationExpired = {
  __typename?: 'UserRegistrationExpired'
  registrationId: Scalars['ID']
}

export type UserRegistrationIdNotFound = {
  __typename?: 'UserRegistrationIdNotFound'
  registrationId: Scalars['ID']
}

export type UserRegistrationMutationFailed = {
  __typename?: 'UserRegistrationMutationFailed'
  message: Scalars['String']
  registrationId: Scalars['ID']
}

export type UserRegistrationPasswordNotProvided = {
  __typename?: 'UserRegistrationPasswordNotProvided'
  registrationId: Scalars['ID']
}

export type UserRegistrationPayrollUserNotFound = {
  __typename?: 'UserRegistrationPayrollUserNotFound'
  registrationId: Scalars['ID']
}

export type UserRegistrationStatus = {
  __typename?: 'UserRegistrationStatus'
  email: Scalars['String']
  requiresPassword: Scalars['Boolean']
}

export type UserRegistrationStatusResult =
  | UserMigrationFailed
  | UserRegistered
  | UserRegistrationCheckFailed
  | UserRegistrationEmailNotVerified
  | UserRegistrationExpired
  | UserRegistrationIdNotFound
  | UserRegistrationMutationFailed
  | UserRegistrationPasswordNotProvided
  | UserRegistrationPayrollUserNotFound
  | UserRegistrationStatus
  | UserRegistrationUserAlreadyRegistered
  | UserRegistrationUserNotActive

export type UserRegistrationUserAlreadyRegistered = {
  __typename?: 'UserRegistrationUserAlreadyRegistered'
  email: Scalars['String']
  registrationId: Scalars['ID']
}

export type UserRegistrationUserNotActive = {
  __typename?: 'UserRegistrationUserNotActive'
  registrationId: Scalars['ID']
}

export type UserRoleAdded = {
  __typename?: 'UserRoleAdded'
  actorId: Scalars['ID']
  customerId: Scalars['ID']
  role: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']
  userId: Scalars['ID']
  version: Scalars['Long']
}

export type UserRoleRemoved = {
  __typename?: 'UserRoleRemoved'
  actorId: Scalars['ID']
  customerId: Scalars['ID']
  role: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']
  userId: Scalars['ID']
  version: Scalars['Long']
}

export enum UserType {
  admin = 'admin',
  customer = 'customer'
}

export type UserUnlinked = {
  __typename?: 'UserUnlinked'
  actorId: Scalars['ID']
  customerId: Scalars['ID']
  timestamp: Scalars['DateTime']
  toastIdentityGuid: Scalars['ID']
  userId: Scalars['ID']
  version: Scalars['Long']
}

export type UsernameChanged = {
  __typename?: 'UsernameChanged'
  actorId: Scalars['ID']
  customerId: Scalars['ID']
  fromUsername: Maybe<Scalars['String']>
  timestamp: Scalars['DateTime']
  toUsername: Maybe<Scalars['String']>
  toastIdentityGuid: Maybe<Scalars['ID']>
  userId: Scalars['ID']
  version: Scalars['Long']
}

export type UsernameChangedWithConflict = {
  __typename?: 'UsernameChangedWithConflict'
  actorId: Scalars['ID']
  conflictingUserId: Scalars['ID']
  customerId: Scalars['ID']
  fromUsername: Maybe<Scalars['String']>
  timestamp: Scalars['DateTime']
  toUsername: Maybe<Scalars['String']>
  toastIdentityGuid: Maybe<Scalars['ID']>
  userId: Scalars['ID']
  version: Scalars['Long']
}

export type UsernameExists = {
  __typename?: 'UsernameExists'
  username: Scalars['String']
}

export type Users = {
  __typename?: 'Users'
  list: Array<Maybe<User>>
}

export type VendorNetPays = {
  __typename?: 'VendorNetPays'
  amount: Scalars['Float']
}

export type VerificationEmailConfirmed = {
  __typename?: 'VerificationEmailConfirmed'
  email: Scalars['String']
  eventTime: Scalars['DateTime']
}

export type VerificationEmailNotSent = {
  __typename?: 'VerificationEmailNotSent'
  message: Maybe<Scalars['String']>
}

export type VerificationEmailSent = {
  __typename?: 'VerificationEmailSent'
  email: Scalars['String']
  eventTime: Scalars['DateTime']
  expirationTime: Scalars['DateTime']
  idempotencyKey: Scalars['ID']
}

export type VerificationEmailSentEvent = {
  __typename?: 'VerificationEmailSentEvent'
  emailHash: Scalars['String']
  eventTime: Scalars['DateTime']
  expirationTime: Scalars['DateTime']
  idempotencyKey: Scalars['ID']
  sequence: Scalars['Int']
  toastIdentityGuid: Scalars['String']
}

export enum VeteranStatus {
  ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN = 'ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN',
  ARMED_FORCES_SERVICE_MEDAL_VETERAN = 'ARMED_FORCES_SERVICE_MEDAL_VETERAN',
  DISABLED_VETERAN = 'DISABLED_VETERAN',
  NOT_A_VETERAN = 'NOT_A_VETERAN',
  RECENTLY_SEPARATED_VETERAN = 'RECENTLY_SEPARATED_VETERAN',
  SPECIAL_DISABLED_VETERAN = 'SPECIAL_DISABLED_VETERAN',
  VETERAN = 'VETERAN',
  VIETNAM_ERA_VETERAN = 'VIETNAM_ERA_VETERAN'
}

export type W2Employee = {
  __typename?: 'W2Employee'
  employmentStatus: PayrollEmploymentStatus
  firstName: Scalars['String']
  id: Scalars['String']
  lastName: Scalars['String']
  printSetting: W2EmployeeSetting
  user: Maybe<W2EmployeeUser>
}

export enum W2EmployeeSetting {
  ELECTRONIC = 'ELECTRONIC',
  PAPER = 'PAPER',
  UNKNOWN = 'UNKNOWN'
}

export type W2EmployeeUser = {
  __typename?: 'W2EmployeeUser'
  email: Scalars['String']
  hasLoggedIn: Scalars['Boolean']
  id: Scalars['String']
  isEmailVerified: Scalars['Boolean']
  username: Scalars['String']
}

export type W2Fein = {
  __typename?: 'W2Fein'
  employees: Array<W2Employee>
  id: Scalars['String']
  name: Scalars['String']
  tin: Scalars['String']
  /** This represents the total number of employees receiving W2s, the employees field may vary because will expose those you have access to. */
  totalEmployees: Scalars['Int']
}

export type W2Packet = {
  __typename?: 'W2Packet'
  feinName: Scalars['String']
  feinUuid: Scalars['String']
  legal: Array<W2PacketData>
  letter: Array<W2PacketData>
}

export type W2PacketData = {
  __typename?: 'W2PacketData'
  filename: Scalars['String']
  type: W2EmployeeSetting
  uuid: Scalars['String']
}

export enum W2PrintingStatus {
  SELF_PRINTED = 'SELF_PRINTED',
  TOAST_PRINTED = 'TOAST_PRINTED'
}

export type W2PrintingStatusError = MutationError & {
  __typename?: 'W2PrintingStatusError'
  errors: Array<Error>
  status: ResponseStatus
}

export type W2PrintingStatusResponse =
  | W2PrintingStatusError
  | W2PrintingStatusSuccess

export type W2PrintingStatusSuccess = MutationSuccess & {
  __typename?: 'W2PrintingStatusSuccess'
  result: W2PrintingStatus
  status: ResponseStatus
}

export type W2SettingsInput = {
  printingStatus: W2PrintingStatus
  shippingAddress: InputMaybe<W2ShippingAddressInput>
}

export type W2ShippingAddress = {
  __typename?: 'W2ShippingAddress'
  businessName: Scalars['String']
  city: Scalars['String']
  country: Scalars['String']
  id: Scalars['String']
  line1: Scalars['String']
  line2: Scalars['String']
  recipientName: Scalars['String']
  state: Scalars['String']
  zipCode: Scalars['String']
}

export type W2ShippingAddressInput = {
  businessName: InputMaybe<Scalars['String']>
  city: Scalars['String']
  country: InputMaybe<Scalars['String']>
  line1: Scalars['String']
  line2: InputMaybe<Scalars['String']>
  recipientName: InputMaybe<Scalars['String']>
  state: Scalars['String']
  zipCode: Scalars['String']
}

export type WorkTaxLocation = {
  __typename?: 'WorkTaxLocation'
  customerUuid: Scalars['String']
  name: Scalars['String']
  shippingAddressUuid: Scalars['String']
}

export enum Join__Graph {
  CUSTOMER = 'CUSTOMER',
  EC_HUMAN_RESOURCES = 'EC_HUMAN_RESOURCES',
  EC_JOBS = 'EC_JOBS',
  EC_PAYROLL = 'EC_PAYROLL',
  EC_PAYROLL_ONBOARDING = 'EC_PAYROLL_ONBOARDING',
  EC_PAYROLL_OPERATIONS = 'EC_PAYROLL_OPERATIONS',
  EC_WEB = 'EC_WEB',
  NAVIGATION = 'NAVIGATION',
  PAYROLL = 'PAYROLL',
  PAYROLL_BENEFITS = 'PAYROLL_BENEFITS',
  SDP = 'SDP'
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  EXECUTION = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  SECURITY = 'SECURITY'
}

export type GetCustomerSettingQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerSettingQuery = {
  __typename?: 'Query'
  customerSettings: { __typename?: 'CustomerSettings'; BLENDED_OT: boolean }
}

export type FindEmployeePositionsQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
  employeeUuid: Scalars['String']
}>

export type FindEmployeePositionsQuery = {
  __typename?: 'Query'
  employeePositions: Array<{
    __typename?: 'PayrollEmployeePosition'
    uuid: string
    isPrimary: boolean
    levels: Array<{
      __typename?: 'PayrollEmployeePositionLevel'
      uuid: string | null
      sequenceId: number
    } | null>
  }>
}

export type PayrollEmployeeListQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type PayrollEmployeeListQuery = {
  __typename?: 'Query'
  payrollEmployeeList: Array<{
    __typename?: 'PayrollEmployeeV2'
    legacyId: string
    legacyEmployeeId: string
    employeeUuid: string
    employeeNumber: string
    name: string
    payPeriodUuid: string
    paystubs: Array<{
      __typename?: 'PayCheckV2'
      legacyId: string | null
      uuid: string
      isManual: boolean
      isReadOnly: boolean
      isVoid: boolean
      paidByCheck: boolean
      number: number
      checkCode: { __typename?: 'CheckCode'; value: string; label: string }
      deductions: Array<{
        __typename?: 'PayCheckDeduction'
        legacyId: string
        uuid: string
        legacyDeductionCodeId: string
        deductionCodeUuid: string
        name: string
        amount: number
        isReadOnly: boolean
        isPercentage: boolean
        isSimplyInsured: boolean | null
      }>
      earnings: Array<{
        __typename?: 'PayCheckEarning'
        legacyId: string
        legacyEarningCodeId: string
        uuid: string
        amount: number
        hours: number
        baseRate: number
        isFirstWeek: boolean
        job: string
        location: string
        name: string
        rate: number
        earningCode: {
          __typename?: 'EarningCode'
          value: string
          label: string
          isHourly: boolean
          isOT: boolean
        }
        checkCode: { __typename?: 'CheckCode'; value: string; label: string }
      }>
    }>
  }>
}

export type EditDeductionMutationVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type EditDeductionMutation = {
  __typename?: 'Mutation'
  open:
    | { __typename?: 'OpenError' }
    | { __typename?: 'OpenSuccess'; status: ResponseStatus }
}

export type SaveEarningMutationVariables = Exact<{
  payPeriodUuid: Scalars['String']
  earningUuid: InputMaybe<Scalars['String']>
  earning: SaveEarning
}>

export type SaveEarningMutation = {
  __typename?: 'Mutation'
  saveEarning:
    | {
        __typename?: 'SaveEarningError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'SaveEarningSuccess'; status: ResponseStatus }
}

export type AggregatedInvoicesQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type AggregatedInvoicesQuery = {
  __typename?: 'Query'
  aggregatedInvoices:
    | {
        __typename?: 'AggregatePayrollInvoiceError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | {
        __typename?: 'AggregatePayrollInvoiceSuccess'
        invoices: Array<{
          __typename?: 'AggregatePayrollInvoice'
          caption: string
          totalEmployeeCount: number
          totalCharge: any
          invoiceTypeId: number
        }>
      }
}

export type AllCheckCodesQueryVariables = Exact<{ [key: string]: never }>

export type AllCheckCodesQuery = {
  __typename?: 'Query'
  checkCodes: Array<{ __typename?: 'CheckCode'; label: string; value: string }>
}

export type CheckCodesConfigQueryVariables = Exact<{ [key: string]: never }>

export type CheckCodesConfigQuery = {
  __typename?: 'Query'
  checkCodesConfig:
    | {
        __typename?: 'CheckCodesConfigQuerySuccess'
        status: ResponseStatus
        checkCodes: Array<{
          __typename?: 'CheckCode'
          label: string
          value: string
        }>
      }
    | {
        __typename?: 'QueryErrorResponse'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
}

export type GetLevelByEarningQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
  payCheckEarningUuid: Scalars['String']
}>

export type GetLevelByEarningQuery = {
  __typename?: 'Query'
  findPayrollEarningLevels: Array<{
    __typename?: 'LevelOption'
    uuid: string
    sequenceId: number
  }>
}

export type GetPayPeriodByUuidQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetPayPeriodByUuidQuery = {
  __typename?: 'Query'
  payperiod: {
    __typename?: 'PayPeriod'
    uuid: string
    name: string
    status: number
    startDate: any
    endDate: any
    checkDate: any
    checkDateOverride: any | null
    forceLiveChecks: boolean
    taxDebitDate: any
    dueDate: any | null
    postDeadlineText: PostDeadline
    datePosted: any | null
    isReadOnly: boolean | null
    debitDate: any | null
    finalCheckDate: any | null
    deliveryMethod: DeliveryMethod
    adjustmentUrl: string
    timesheetsImportUrlAloha: string | null
    checkCodeUuid: string
    checkCodeName: string
    previousPayPeriodUuid: string | null
    previousPayPeriodStartDate: any | null
    previousPayPeriodEndDate: any | null
    payGroupUuids: Array<string>
    isManual: boolean
    previousProviderPayrollType: PreviousProviderPayrollType | null
    checkCode: { __typename?: 'CheckCode'; value: string; label: string }
  }
}

export type GetPayPeriodStatusByUuidQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetPayPeriodStatusByUuidQuery = {
  __typename?: 'Query'
  payperiod: { __typename?: 'PayPeriod'; status: number }
}

export type GetRecentActivityQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type GetRecentActivityQuery = {
  __typename?: 'Query'
  getApprovedExpenseReports: Array<{
    __typename?: 'ExpenseReport'
    approveDateTime: any | null
    totalAmount: number
    employeeFirstName: string
    employeeLastName: string
    payPeriodUuid: string | null
    employeeUuid: string
    customerUuid: string
  }>
  payperiod: {
    __typename?: 'PayPeriod'
    messages: Array<{
      __typename?: 'Message'
      name: string
      datePosted: any
      action: MessageActionType
      amountType: EnumAmountType
      whom: string
      author: string
      amount: number
    }>
  }
}

export type ResetMutationVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type ResetMutation = { __typename?: 'Mutation'; reset: string | null }

export type UseEmployeeComparisonQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type UseEmployeeComparisonQuery = {
  __typename?: 'Query'
  getEmployeeComparison: {
    __typename?: 'EmployeeComparison'
    added: Array<{
      __typename?: 'PayrollEmployee'
      firstName: string
      lastName: string
      employeeNumber: string
    }>
    removed: Array<{
      __typename?: 'PayrollEmployee'
      firstName: string
      lastName: string
      employeeNumber: string
    }>
  }
}

export type PayPeriodAlertsQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type PayPeriodAlertsQuery = {
  __typename?: 'Query'
  payPeriodAlerts: Array<{
    __typename?: 'PayPeriodAlert'
    type: PayPeriodAlertType
    count: number
  }>
}

export type GetPayrollEmployeesQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetPayrollEmployeesQuery = {
  __typename?: 'Query'
  getPayrollEmployees: Array<{
    __typename?: 'PayrollEmployee'
    firstName: string
    lastName: string
    employeeNumber: string
  }>
}

export type GetPayrollEmployeesWithComparisonQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetPayrollEmployeesWithComparisonQuery = {
  __typename?: 'Query'
  getPayrollEmployees: Array<{
    __typename?: 'PayrollEmployee'
    salaryType: SalaryType | null
    firstName: string
    lastName: string
    employeeNumber: string
    employeeUuid: string
    timesheetTiedToPayrollSetting: TimeSheetTiedToPayroll | null
  }>
  getEmployeeComparison: {
    __typename?: 'EmployeeComparison'
    added: Array<{
      __typename?: 'PayrollEmployee'
      salaryType: SalaryType | null
      firstName: string
      lastName: string
      employeeNumber: string
      employeeUuid: string
      timesheetTiedToPayrollSetting: TimeSheetTiedToPayroll | null
    }>
    removed: Array<{
      __typename?: 'PayrollEmployee'
      salaryType: SalaryType | null
      firstName: string
      lastName: string
      employeeNumber: string
      employeeUuid: string
      timesheetTiedToPayrollSetting: TimeSheetTiedToPayroll | null
    }>
  }
}

export type GetPayrollEmployeesSocialsQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetPayrollEmployeesSocialsQuery = {
  __typename?: 'Query'
  getPayrollEmployeesPosted: Array<{
    __typename?: 'PayrollEmployee'
    employeeUuid: string
    validSsn: boolean
  }>
}

export type PayrollImportMutationVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type PayrollImportMutation = {
  __typename?: 'Mutation'
  payrollImport:
    | {
        __typename?: 'ResponseError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'ResponseSuccess'; status: ResponseStatus }
}

export type LoadTaxChecklistQueryVariables = Exact<{
  datetime: InputMaybe<Scalars['String']>
}>

export type LoadTaxChecklistQuery = {
  __typename?: 'Query'
  loadTaxChecklist: {
    __typename?: 'TaxPrepChecklistState'
    status: TaxPrepChecklistStatus
    quarter: TaxPrepQuarter | null
    year: number | null
  }
}

export type PayrollsByYearQuarterQueryVariables = Exact<{
  quarter: Scalars['Int']
  year: Scalars['Int']
}>

export type PayrollsByYearQuarterQuery = {
  __typename?: 'Query'
  payrollsByYearQuarter: Array<{
    __typename?: 'Payroll'
    id: string
    name: string
    startDate: any
    endDate: any
    dueDate: any | null
    status: PayrollStatusV2
    payGroups: Array<{ __typename?: 'PayrollPayGroup'; id: string }>
  }>
}

export type GetTimesheetsQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetTimesheetsQuery = {
  __typename?: 'Query'
  timesheets: Array<{
    __typename?: 'TimesheetByPayrollAggregate'
    status: TimeSheetStatus | null
    employeeUuid: string
    isImported: boolean
    earningEntries: Array<{
      __typename?: 'TimesheetEarningEntry'
      hoursWorked: number | null
      isOvertime: boolean
      amount: number | null
      date: any | null
      earningCode: {
        __typename?: 'TimesheetEntryEarningCode'
        uuid: string
        name: string
        timeAwaySetupId: number
        isHourly: boolean | null
      }
    }>
  }>
}

export type GetTimesheetsWithPreviousQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
  payPeriodUuidPrevious: Scalars['ID']
}>

export type GetTimesheetsWithPreviousQuery = {
  __typename?: 'Query'
  timesheets: Array<{
    __typename?: 'TimesheetByPayrollAggregate'
    status: TimeSheetStatus | null
    employeeUuid: string
    isImported: boolean
    earningEntries: Array<{
      __typename?: 'TimesheetEarningEntry'
      hoursWorked: number | null
      isOvertime: boolean
      amount: number | null
      date: any | null
      earningCode: {
        __typename?: 'TimesheetEntryEarningCode'
        uuid: string
        name: string
        timeAwaySetupId: number
        isHourly: boolean | null
      }
    }>
  }>
  timesheetsPrevious: Array<{
    __typename?: 'TimesheetByPayrollAggregate'
    status: TimeSheetStatus | null
    employeeUuid: string
    isImported: boolean
    earningEntries: Array<{
      __typename?: 'TimesheetEarningEntry'
      hoursWorked: number | null
      isOvertime: boolean
      amount: number | null
      date: any | null
      earningCode: {
        __typename?: 'TimesheetEntryEarningCode'
        uuid: string
        name: string
        timeAwaySetupId: number
        isHourly: boolean | null
      }
    }>
  }>
}

export type GetTipsDetailsQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetTipsDetailsQuery = {
  __typename?: 'Query'
  tipsDetails: Array<{
    __typename?: 'TipsPerDay'
    amount: number
    date: any
    tipsReadyStatus: TipsReadyStatus
  }> | null
}

export type GetTipsIntegrationSettingsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetTipsIntegrationSettingsQuery = {
  __typename?: 'Query'
  tipsIntegrationSettings: TipsIntegrationSettings
}

export type AchConsentWorkflowQueryVariables = Exact<{
  payPeriodId: Scalars['ID']
}>

export type AchConsentWorkflowQuery = {
  __typename?: 'Query'
  bankConsentStatus: Array<{
    __typename?: 'BankAccountConsentInfo'
    bankAccountUuid: string
    payGroupUuid: string
    lastFourAccountNumber: string | null
    bankName: string
    consents: Array<{
      __typename?: 'ACHConsent'
      timestamp: any
      needsConsent: boolean
    }> | null
  }> | null
}

export type PayrollCustomerQueryVariables = Exact<{ [key: string]: never }>

export type PayrollCustomerQuery = {
  __typename?: 'Query'
  payrollCustomer: {
    __typename?: 'Customer'
    id: string
    status: CustomerStatus
  }
}

export type EnforceDeadlineQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type EnforceDeadlineQuery = {
  __typename?: 'Query'
  enforceDeadline: boolean
}

export type PostMutationVariables = Exact<{
  payPeriodUuid: Scalars['String']
  consentBankAccountUuids: InputMaybe<
    Array<Scalars['String']> | Scalars['String']
  >
}>

export type PostMutation = {
  __typename?: 'Mutation'
  post:
    | {
        __typename?: 'PostError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'PostSuccess'; status: ResponseStatus }
}

export type GetPreviousProviderPayrollTypeQueryVariables = Exact<{
  payPeriodUuid: Scalars['ID']
}>

export type GetPreviousProviderPayrollTypeQuery = {
  __typename?: 'Query'
  payperiod: {
    __typename?: 'PayPeriod'
    previousProviderPayrollType: PreviousProviderPayrollType | null
  }
}

export type SignatureDetailsQueryVariables = Exact<{
  payPeriodId: Scalars['ID']
}>

export type SignatureDetailsQuery = {
  __typename?: 'Query'
  signatureDetails: {
    __typename?: 'CompanySummary'
    payrollSettings: {
      __typename?: 'PayrollSettings'
      payrollPayor: IsPayrollPayor | null
    }
  }
  bankDetails: Array<{
    __typename?: 'BankAccountConsentInfo'
    bankAccountUuid: string
    payGroupUuid: string
    lastFourAccountNumber: string | null
    bankName: string
    hasSignature: boolean
    consents: Array<{
      __typename?: 'ACHConsent'
      timestamp: any
      needsConsent: boolean
    }> | null
  }> | null
}

export type CheckPackagesQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type CheckPackagesQuery = {
  __typename?: 'Query'
  payperiod: {
    __typename?: 'PayPeriod'
    checkPackages: Array<{
      __typename?: 'CheckPackage'
      numberOfChecks: number
      shippingAddress: {
        __typename?: 'ShippingAddress'
        shippingAddressUuid: string
        recipientCompany: string
        attention: string
        addressLine1: string
        addressLine2: string
        city: string
        zipCode: string
        stateName: string
        trackingNumber: string | null
      }
      workTaxLocations: Array<{ __typename?: 'WorkTaxLocation'; name: string }>
    } | null> | null
  }
}

export type CustomerQueryVariables = Exact<{ [key: string]: never }>

export type CustomerQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'Customer'
    locations: Array<{
      __typename?: 'Location'
      uuid: string
      name: string | null
    }>
  }
}

export type EstimatedWithdrawalQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type EstimatedWithdrawalQuery = {
  __typename?: 'Query'
  estimatedWithdrawal: {
    __typename?: 'PayrollRequirement'
    cashRequirement: {
      __typename?: 'CashRequirement'
      vendorNetPays: Array<{ __typename?: 'VendorNetPays'; amount: number }>
      totalTaxes: Array<{
        __typename?: 'PayCheckTaxes'
        name: string
        amount: number
        isCollectedByToast: boolean
      }>
      totalEERequirement: {
        __typename?: 'EmployeeRequirement'
        employeesPay: Array<{
          __typename?: 'EmployeeNetPays'
          uuid: string
          amt: number
        }>
        voidNetPayment: Array<{ __typename?: 'PayCheck'; netEarnings: number }>
        invoiceItems: Array<{
          __typename?: 'InvoiceItem'
          uuid: string
          total: number
          caption: string | null
          employeeCount: number | null
          invoiceType: string | null
        }>
      }
    }
    nonCashRequirement: {
      __typename?: 'NonCashRequirement'
      employeesManualPay: Array<{
        __typename?: 'EmployeeNetPays'
        uuid: string
        amt: number
      }>
      voidNetPayment: Array<{ __typename?: 'PayCheck'; netEarnings: number }>
      vendorChecksNetPays: Array<{
        __typename?: 'VendorNetPays'
        amount: number
      }>
    }
  }
}

export type GetFirstPayrollDateQueryVariables = Exact<{ [key: string]: never }>

export type GetFirstPayrollDateQuery = {
  __typename?: 'Query'
  companySummary: {
    __typename?: 'CompanySummary'
    companyProfile: { __typename?: 'CompanyProfile'; firstPayrollDate: any }
  }
}

export type UsePayrollWithdrawalEstimateQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type UsePayrollWithdrawalEstimateQuery = {
  __typename?: 'Query'
  payrollRequirement: {
    __typename?: 'PayrollTotalRequirements'
    cashRequirement: number
    nonCashRequirement: number
    nonDebitedTaxes: number
  }
}

export type ToastPrintedCountsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type ToastPrintedCountsQuery = {
  __typename?: 'Query'
  payperiod: {
    __typename?: 'PayPeriod'
    toastPrintedChecks: {
      __typename?: 'ToastPrinted'
      totalChecks: number
      totalEmployees: number
    } | null
  }
}

export type PaycheckDeliverySettingsQueryVariables = Exact<{
  uuid: Scalars['ID']
}>

export type PaycheckDeliverySettingsQuery = {
  __typename?: 'Query'
  payperiod: { __typename?: 'PayPeriod'; deliveryMethod: DeliveryMethod }
}

export type PaycheckDeliverySettingsByUserQueryVariables = Exact<{
  [key: string]: never
}>

export type PaycheckDeliverySettingsByUserQuery = {
  __typename?: 'Query'
  paycheckDeliverySettings: {
    __typename?: 'PayCheckDeliverySettings'
    method: DeliveryMethod
  }
}

export type FeinsQueryVariables = Exact<{ [key: string]: never }>

export type FeinsQuery = {
  __typename?: 'Query'
  feins: Array<{
    __typename?: 'Fein'
    id: string
    name: string
    tin: string
    isActive: boolean
    address: {
      __typename?: 'FeinAddress'
      streetLine1: string
      streetLine2: string
      city: string
      zipCode: string
      phoneNumber: string
      state: string
      country: string
    }
  }>
}

export type SaveForm8655MutationVariables = Exact<{
  feinUuid: Scalars['String']
  input: Form8655Input
}>

export type SaveForm8655Mutation = {
  __typename?: 'Mutation'
  saveForm8655:
    | {
        __typename?: 'Form8655Error'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'Form8655Success'; status: ResponseStatus }
}

export type Form8655QueryVariables = Exact<{ [key: string]: never }>

export type Form8655Query = {
  __typename?: 'Query'
  getAllForm8655Data: Array<{
    __typename?: 'Form8655'
    id: string | null
    feinUuid: string
    form8655Details: string | null
    dateSigned: any | null
    s3Filename: string | null
  }>
}

export type Form8655DownloadAllQueryVariables = Exact<{ [key: string]: never }>

export type Form8655DownloadAllQuery = {
  __typename?: 'Query'
  downloadForm8655:
    | {
        __typename?: 'Form8655Error'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'Form8655Success'; status: ResponseStatus; result: string }
}

export type Form8655DownloadByFeinsQueryVariables = Exact<{
  feinUuids: Array<Scalars['String']> | Scalars['String']
}>

export type Form8655DownloadByFeinsQuery = {
  __typename?: 'Query'
  downloadForm8655ByFeins:
    | {
        __typename?: 'Form8655Error'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'Form8655Success'; status: ResponseStatus; result: string }
}

export type Form8655DownloadByDateQueryVariables = Exact<{
  startDate: Scalars['Date']
  endDate: Scalars['Date']
}>

export type Form8655DownloadByDateQuery = {
  __typename?: 'Query'
  downloadForm8655ByDateRange:
    | {
        __typename?: 'Form8655Error'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'Form8655Success'; status: ResponseStatus; result: string }
}

export type ArchivePayPeriodMutationVariables = Exact<{
  payrollUuid: Scalars['ID']
}>

export type ArchivePayPeriodMutation = {
  __typename?: 'Mutation'
  archivePayPeriod: string | null
}

export type ArchiveMutationVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type ArchiveMutation = {
  __typename?: 'Mutation'
  archive:
    | {
        __typename?: 'ArchiveError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'ArchiveSuccess'; status: ResponseStatus; result: string }
}

export type OpenMutationVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type OpenMutation = {
  __typename?: 'Mutation'
  open:
    | {
        __typename?: 'OpenError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'OpenSuccess'; status: ResponseStatus }
}

export type PayrollNavigationQueryVariables = Exact<{
  input: NavigationInput
}>

export type PayrollNavigationQuery = {
  __typename?: 'Query'
  navigation: {
    __typename?: 'NavigationResult'
    result: {
      __typename?: 'Navigation'
      path: string
      name: string
      label: string
      url: string
      matchUrls: Array<string>
      labelNew: {
        __typename?: 'LocalizableMessage'
        key: string
        message: string
      }
      children: Array<{
        __typename?: 'Navigation'
        path: string
        name: string
        label: string
        url: string
        matchUrls: Array<string>
        labelNew: {
          __typename?: 'LocalizableMessage'
          key: string
          message: string
        }
      }>
    } | null
    errors: Array<{
      __typename?: 'NavigationErrors'
      path: string
      errors: Array<{
        __typename?: 'NavigationError'
        type: string
        message: string
      }>
    }>
  }
}

export type PayrollSettingsQueryVariables = Exact<{ [key: string]: never }>

export type PayrollSettingsQuery = {
  __typename?: 'Query'
  payrollSettings: {
    __typename?: 'PayrollSettings'
    payrollPostDeadline: PostDeadline
    deadlineEnforcement: boolean | null
    autoApproveTimesheets: boolean | null
  }
}

export type UpcomingQueryVariables = Exact<{ [key: string]: never }>

export type UpcomingQuery = {
  __typename?: 'Query'
  upcoming: Array<{
    __typename?: 'UpcomingPayroll'
    id: string
    name: string
    status: PayrollStatusV2
    startDate: any
    endDate: any
    dueDate: any | null
    payDate: any
    manual: boolean
    unApprovedTimesheetsCount: number
    timesheetsCount: number
    hasTips: boolean
    unReceivedTips: number
  }>
}

export type PreviousYearUnprocessedPayrollsQueryVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type PreviousYearUnprocessedPayrollsQuery = {
  __typename?: 'Query'
  previousYearUnprocessedPayrolls:
    | {
        __typename?: 'PreviousYearUnprocessedPayrollsQuerySuccess'
        status: ResponseStatus
        payrolls: {
          __typename?: 'PreviousYearUnprocessedPayrolls'
          totalCount: number
          canWriteToAllPayrolls: boolean
          unprocessedPayrolls: Array<{
            __typename?: 'UnprocessedPayroll'
            id: string
            name: string
            payFrequency: PayFrequency
            startDate: any
            endDate: any
            checkDate: any
          }> | null
        }
      }
    | {
        __typename?: 'QueryErrorResponse'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
}

export type RolloverPayrollsMutationVariables = Exact<{
  payPeriodUuid: Scalars['String']
}>

export type RolloverPayrollsMutation = {
  __typename?: 'Mutation'
  rolloverPayrolls:
    | {
        __typename?: 'ResponseError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'ResponseSuccess'; status: ResponseStatus }
}

export type SavePayrollSettingMutationVariables = Exact<{
  type: PayrollSetting
  value: Scalars['Boolean']
}>

export type SavePayrollSettingMutation = {
  __typename?: 'Mutation'
  savePayrollSetting:
    | {
        __typename?: 'ResponseError'
        status: ResponseStatus
        errors: Array<{ __typename?: 'Error'; message: string | null }>
      }
    | { __typename?: 'ResponseSuccess'; status: ResponseStatus }
}

export type PayrollSettingsAuditQueryVariables = Exact<{
  type: PayrollSetting
}>

export type PayrollSettingsAuditQuery = {
  __typename?: 'Query'
  payrollSettingsAudit: Array<{
    __typename?: 'PayrollSettingsAudit'
    id: string
    on: any
    byStamp: string
    previous: boolean
    current: boolean
    by: {
      __typename?: 'AuditUser'
      id: string
      username: string
      lastName: string | null
      firstName: string | null
    }
  }>
}

export type GetLevelQueryVariables = Exact<{
  sequenceId: Scalars['Int']
  levelUuid: Scalars['String']
}>

export type GetLevelQuery = {
  __typename?: 'Query'
  getLevel: Array<{
    __typename?: 'PayrollLevelOption'
    uuid: string
    name: string
    sequenceId: number
    isOrphan: boolean
  }>
}

export type GetLevelsConfigQueryVariables = Exact<{ [key: string]: never }>

export type GetLevelsConfigQuery = {
  __typename?: 'Query'
  levelsConfig: Array<{
    __typename?: 'LevelConfig'
    sequence: number
    name: string
    type: string
  }>
}

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AdditionalEarningResponse: [
      'EarningListEmptyError',
      'EmployeeNotFoundError',
      'JobAssignmentNotFoundError',
      'MissingPayGroupError',
      'NextPayPeriodNotFoundError',
      'NextPaycheckDateNotFoundError',
      'Paycheck'
    ],
    AggregateInvoiceResult: [
      'AggregatePayrollInvoiceError',
      'AggregatePayrollInvoiceSuccess'
    ],
    AllEmploymentStatuses: [
      'ActiveEmploymentStatus',
      'ApplicantEmploymentStatus',
      'DeletedEmploymentStatus',
      'DemoEmploymentStatus',
      'ImplementationEmploymentStatus',
      'LeaveOfAbsenceEmploymentStatus',
      'NeverEmployedEmploymentStatus',
      'PendingEmploymentStatus',
      'RehiredEmploymentStatus',
      'SeasonalEmploymentStatus',
      'TerminatedEmploymentStatus'
    ],
    ArchiveResponse: ['ArchiveError', 'ArchiveSuccess'],
    BringBackResponse: ['ResponseError', 'ResponseSuccess'],
    ChangeLinkedUsernameResult: [
      'CannotChangeUnlinkedUsername',
      'InvalidUsername',
      'PayrollUser',
      'ToastVersionIgnored',
      'UserNotFound',
      'UsernameExists'
    ],
    ChangeSalaryResponse: [
      'EmployeeNotFoundError',
      'EmptySalaryAllocationsError',
      'InvalidEffectiveDateError',
      'Salary',
      'SalaryAllocationNotFoundError',
      'SalaryNotFoundError'
    ],
    ChangeUnlinkedUsernameResult: [
      'CannotChangeLinkedUsername',
      'InvalidUsername',
      'PayrollUser',
      'UserNotFound',
      'UsernameExists'
    ],
    CheckCodesConfigResponse: [
      'CheckCodesConfigQuerySuccess',
      'QueryErrorResponse'
    ],
    ConfirmVerificationEmailError: [
      'ConfirmationExpired',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailNotSent'
    ],
    ConfirmVerificationEmailResult: [
      'ConfirmationExpired',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailConfirmed',
      'VerificationEmailNotSent'
    ],
    EcPayrollResponse: ['ResponseError', 'ResponseSuccess'],
    EmailVerificationError: [
      'ConfirmationExpired',
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailNotFound',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailNotSent'
    ],
    EmailVerificationEvent: [
      'ConfirmedEvent',
      'EmailChangedEvent',
      'EmailVerificationFailed',
      'VerificationEmailSentEvent'
    ],
    EmailVerificationResult: [
      'ConfirmationExpired',
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailNotFound',
      'EmailVerification',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailNotSent'
    ],
    EmploymentStatus: [
      'ActiveEmploymentStatus',
      'ApplicantEmploymentStatus',
      'DeletedEmploymentStatus',
      'DemoEmploymentStatus',
      'ImplementationEmploymentStatus',
      'LeaveOfAbsenceEmploymentStatus',
      'NeverEmployedEmploymentStatus',
      'PendingEmploymentStatus',
      'RehiredEmploymentStatus',
      'SeasonalEmploymentStatus',
      'TerminatedEmploymentStatus'
    ],
    Form8655Response: ['Form8655Error', 'Form8655Success'],
    HourlyRetroPayResponse: [
      'HourlyRetroPay',
      'InvalidStartDateError',
      'JobAssignmentNotFoundError',
      'TimesheetRetrievalError'
    ],
    LinkIfMappedResult: [
      'LinkingNotAvailable',
      'MappedUserMismatchedUsername',
      'PayrollUser',
      'UserAlreadyLinked',
      'UserNotMapped'
    ],
    LinkUserResult: ['PayrollUser', 'UserNotFound'],
    MutationError: [
      'ArchiveError',
      'Form8655Error',
      'OpenError',
      'PostError',
      'ResponseError',
      'SaveDeductionError',
      'SaveEarningError',
      'W2PrintingStatusError'
    ],
    MutationSuccess: [
      'ArchiveSuccess',
      'Form8655Success',
      'OpenSuccess',
      'PostSuccess',
      'ResponseSuccess',
      'SaveDeductionSuccess',
      'SaveEarningSuccess',
      'W2PrintingStatusSuccess'
    ],
    OpenResponse: ['OpenError', 'OpenSuccess'],
    PagedResult: ['PastPayrollPagedResult'],
    PayChangeLogResponse: [
      'AbsentPayChangeLogTokenError',
      'InvalidPayChangeLogTokenError',
      'PayChangeLog'
    ],
    PayGroupCalendarResult: ['PayGroupCalendar', 'Unauthorized'],
    PayrollImportResponse: ['ResponseError', 'ResponseSuccess'],
    PinOnlyUserMergeResult: [
      'PinOnlyUserMergeFailed',
      'PinOnlyUserMergeSuccess'
    ],
    PostResponse: ['PostError', 'PostSuccess'],
    PreviousYearUnprocessedPayrollsResponse: [
      'PreviousYearUnprocessedPayrollsQuerySuccess',
      'QueryErrorResponse'
    ],
    QueryError: [
      'AggregatePayrollInvoiceError',
      'QueryErrorResponse',
      'TimesheetAggregateError'
    ],
    QuerySuccess: [
      'AggregatePayrollInvoiceSuccess',
      'CheckCodesConfigQuerySuccess',
      'PreviousYearUnprocessedPayrollsQuerySuccess',
      'TimesheetAggregateSuccess'
    ],
    SalaryRetroPayResponse: [
      'InvalidStartDateError',
      'SalaryPayFrequencyDoesNotExistError',
      'SalaryPayFrequencyNoneError',
      'SalaryRetroPay',
      'TimesheetRetrievalError'
    ],
    SaveDeductionResponse: ['SaveDeductionError', 'SaveDeductionSuccess'],
    SaveEarningResponse: ['SaveEarningError', 'SaveEarningSuccess'],
    SaveEmployeeSsnResponse: ['ResponseError', 'ResponseSuccess'],
    SendEmailVerificationError: [
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch'
    ],
    SendVerificationEmailResult: [
      'DuplicateRequest',
      'EmailAlreadyVerified',
      'EmailHasBeenUpdated',
      'EmailVerificationFailed',
      'KeyDoesNotMatch',
      'VerificationEmailSent'
    ],
    TimesheetAggregateResponse: [
      'TimesheetAggregateError',
      'TimesheetAggregateSuccess'
    ],
    UnlinkUserResult: ['PayrollUser', 'UserNotFound', 'UserNotLinked'],
    UpdateEmployeePaymentMethodEmailResponse: [
      'EmailInvalidError',
      'EmailSendingError',
      'EmployeeNotFoundError',
      'EmployeePaymentMethodEmailResponse'
    ],
    UpdatePendingPayChangeStatusResponse: [
      'EmployeeNotFoundError',
      'PendingPayChangeNotFoundErrors',
      'PendingPayChangeStatusResponse'
    ],
    UserEvent: [
      'UserLinkChanged',
      'UserLinked',
      'UserRegistered',
      'UserRoleAdded',
      'UserRoleRemoved',
      'UserUnlinked',
      'UsernameChanged',
      'UsernameChangedWithConflict'
    ],
    UserMigrationConfirmAccountVerificationEmailResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailConfirmationFailed',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationEmailVerified',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    UserMigrationDefaultsResult: [
      'UserMigrationDefaults',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    UserMigrationMigrateResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationEmailNotVerified',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationSuccess',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    UserMigrationSendAccountVerificationEmailResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationSendAccountVerificationEmailFailed',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound',
      'UserMigrationVerificationEmailSent'
    ],
    UserMigrationSettingsResult: [
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationSettings'
    ],
    UserMigrationVerifyAccountEmailResult: [
      'UserMigrationChooseAnotherUsername',
      'UserMigrationEmailExistsInCustomer',
      'UserMigrationEmailNotVerified',
      'UserMigrationEmailVerified',
      'UserMigrationFailed',
      'UserMigrationNotEnabled',
      'UserMigrationToastUserHasCredentials',
      'UserMigrationUserAlreadyMigrated',
      'UserMigrationUserNotFound'
    ],
    UserRegistrationStatusResult: [
      'UserMigrationFailed',
      'UserRegistered',
      'UserRegistrationCheckFailed',
      'UserRegistrationEmailNotVerified',
      'UserRegistrationExpired',
      'UserRegistrationIdNotFound',
      'UserRegistrationMutationFailed',
      'UserRegistrationPasswordNotProvided',
      'UserRegistrationPayrollUserNotFound',
      'UserRegistrationStatus',
      'UserRegistrationUserAlreadyRegistered',
      'UserRegistrationUserNotActive'
    ],
    W2PrintingStatusResponse: [
      'W2PrintingStatusError',
      'W2PrintingStatusSuccess'
    ]
  }
}
export default result
