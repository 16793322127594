import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertModal } from '@local/payroll/shared/components/modals'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useResetPayrollModal } from './hooks/useResetPayrollModal'
import { usePayrollParams } from './hooks/usePayrollParams'
import { usePayPeriod } from './hooks/usePayPeriod'
import { usePayrollSettings } from '../list/hooks/usePayrollSettings'
import { UseAutoApproveTimesheets } from '../../shared/hooks/usePayrollFeatureFlag'

export const ResetPayrollModal = () => {
  const { t } = useTranslation('payroll')
  const { reset, isOpen, close, isLoading } = useResetPayrollModal()
  const isAutoApproveEnabled = UseAutoApproveTimesheets()
  const {
    settings: { autoApproveTimesheets },
    loading: isLoadingSetting
  } = usePayrollSettings()

  const { uuid } = usePayrollParams()
  const { payPeriod } = usePayPeriod()

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    reset(uuid!)
  }

  if (!isOpen) {
    return null
  }
  return (
    <AlertModal
      title={t(`common.modal.title.reset`)}
      isOpen
      onRequestClose={close}
    >
      <>
        {isLoading || isLoadingSetting || !payPeriod ? (
          <div className='flex flex-col items-center justify-center h-full p-10 space-y-4'>
            <MerryGoRound />
            <div>{t(`common.modal.loading.reset`)}</div>
          </div>
        ) : (
          <>
            <AlertModal.Body>
              <p className='type-large'>
                {!isAutoApproveEnabled
                  ? t(`common.modal.message.reset`)
                  : autoApproveTimesheets
                  ? t(`common.modal.message.resetAutoApprove`)
                  : t(`common.modal.message.resetManuallyApprove`)}
              </p>
            </AlertModal.Body>
            <AlertModal.Footer>
              <Button
                variant={isAutoApproveEnabled ? 'link' : 'secondary'}
                onClick={close}
              >
                {t(`common.action.buttons.cancel`)}
              </Button>
              <Button
                variant={isAutoApproveEnabled ? 'destructive' : undefined}
                onClick={handleClick}
                testId={`${t(`common.action.buttons.reset`)}-confirmed`}
              >
                {t(`common.action.buttons.reset`)}
              </Button>
            </AlertModal.Footer>
          </>
        )}
      </>
    </AlertModal>
  )
}
