import * as React from 'react'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from '@apollo/client'
import { RestLink } from 'apollo-link-rest'
import { RecoilRoot } from 'recoil'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { PortalProvider } from '@toasttab/ec-layout'

import { withEcApplication, useI18n } from '@local/ec-app'
import { PageLoader } from '@local/payroll/shared/components/pageLoader'

import { AppConfirmModal } from '@local/payroll/shared/components/modals/v2/AppConfirmModal'
import { AppErrorModal } from '@local/payroll/shared/components/modals/v2/AppErrorModal'

import en from './shared/localization/en-US'
import es from './shared/localization/es'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

import { ModalContext } from './experience/details/stores/ModalStore'

import generatedIntrospection from '@local/payroll/gen/queries'
import PayrollRoutes from './routes'

// Rest link used ONLY for ec-api support: do not use where graphql will work instead!
// react-query also used in places, but we are deprecating that library to consistently use apollo instead
const restLink = new RestLink({ uri: '/' })
const httpLink = new HttpLink({ uri: '/graphql' })

export const PayrollApolloClient = new ApolloClient({
  link: ApolloLink.from([
    // rest link must go first: https://www.apollographql.com/docs/react/api/link/apollo-link-rest/#link-order
    restLink,
    httpLink
  ]),
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Form8655: {
        keyFields: ['feinUuid']
      },
      Navigation: {
        keyFields: ['path'] // this can be removed once this directive is added to the schema
      }
    }
  })
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
})

const PayrollApplication = () => {
  const { addResourceBundle } = useI18n()

  addResourceBundle('en-US', 'payroll', en)
  addResourceBundle('es', 'payroll', es)

  return (
    <SnackBarProvider>
      <PortalProvider value='data-pui-2'>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ApolloProvider client={PayrollApolloClient}>
              <ModalContext>
                <AppErrorModal />
                <AppConfirmModal />
                <PayrollRoutes />
              </ModalContext>
            </ApolloProvider>
          </QueryClientProvider>
        </RecoilRoot>
      </PortalProvider>
    </SnackBarProvider>
  )
}

export default withEcApplication(
  PayrollApplication,
  'ec-payroll',
  <PageLoader />
)
