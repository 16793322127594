import { useFeature, useFeatureBase } from '@toasttab/ec-features'

export enum PayrollFeatureFlag {
  UseEnhancedWorkflow = 'ec-px-use-enhanced-workflow',
  UsePreventPayoutDeductionEdits = 'ec-px-prevent-payout-deduction-edits',
  UsePayrollAPIOpen = 'ec-px-use-payroll-api-open',
  UsePayrollAPIEditEarning = 'ec-px-use-payroll-api-edit-earning',
  UsePayrollAPIEditDedeuction = 'ec-px-use-payroll-api-edit-deduction',
  UsePayrollAPIPost = 'ec-px-use-payroll-api-post',
  UseEcPayrollDeductionCodesEndpt = 'ec-px-ec-payroll-deduction-codes-api',
  UseEcPayrollCheckPdfEndpt = 'ec-px-ec-payroll-check-pdfs-api',
  UseEcPayrollPayByEmployeeCheckEndpt = 'ec-px-ec-payroll-payby-employee-check-api',
  UseEcPayrollChecksPdfAuditEndpt = 'ec-px-ec-payroll-checks-pdfs-audit-api',
  UseEcPayrollCheckOverrideEndpt = 'ec-px-ec-payroll-check-override-api',
  UseEcPayrollScheduleEndpt = 'ec-px-ec-payroll-schedule-payroll-api',
  UseEcPayrollEarningCodesEndpt = 'ec-px-ec-payroll-earningcodes-api', // Used also in ec-spa-bff
  UseEcPayrollAddPaycheckEndpt = 'ec-px-ec-payroll-add-paycheck-api',
  UseEcPayrollAchSummaryEndpt = 'ec-px-ec-payroll-ach-summary-api',
  UseEcPayrollAddEmployeeEndpt = 'ec-px-ec-payroll-add-employee-api',
  UseEcPayrollPayrollAuditEndpt = 'ec-px-ec-payroll-payroll-audit-api',
  UseEcPayrollPayrollConfigEndpt = 'ec-px-ec-payroll-payroll-config-api',
  UseToastCapitalCard = 'pmts-toast-capital-payroll-card',
  UseEcPayrollArchiveEndpt = 'ec-px-ec-payroll-archive-api',
  UseProdSubmitValidation = 'ec-px-prod-submit-validation',
  UseRolloverModal = 'ec-px-rollover-modal',
  UseSignatureCapture = 'ec-px-use-bank-signature-capture',
  UseMigrateCheckCodesQuery = 'ec-px-migrate-checkcodesquery',
  UseForm8655BlockPayroll = 'ec-px-form-8655-block-payroll',
  UseTaxCenterCustomResyncTime = 'ec-px-custom-tax-checklist-resync-datetime',
  UseEcPayrollImportEndpt = 'ec-px-ec-payroll-import-api',
  UseEcPayrollEmployeeListEndpt = 'ec-px-ec-employee-list-api',
  UsePoQuickbooksConnect = 'po-use-quickbooks-connect',
  UsePoQuickbookConnectMultilocation = 'po-use-quickbook-connect-multilocation',
  UseAutoApproveTimesheets = 'ec-px-use-auto-approve-timesheets',
  UseEcEnforceDeadlineEndpt = 'ec-px-enforce-deadline-api',
  UseEcSaasTransparency = 'ec-px-enable-saas-transparency'
}

export const usePayrollFeatureFlag = (
  flag: PayrollFeatureFlag,
  defaultValue = false
) => useFeature(flag, defaultValue)

export const useTaxCenterCustomResyncTime = (): Date => {
  const isoWithBrackets = useFeatureBase<string>(
    PayrollFeatureFlag.UseTaxCenterCustomResyncTime,
    new Date().toISOString()
  )

  if (!isoWithBrackets) return new Date()

  // dates are formatted as `{ISS-8601}{[Timezone Name]}?` -- if the tz name is included, then we strip it
  return new Date(isoWithBrackets.split('[')[0])
}

export const usePreventPayoutDeductionEdits = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UsePreventPayoutDeductionEdits)

export const usePayrollAPIOpen = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UsePayrollAPIOpen, true)

export const usePayrollAPIEditEarning = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UsePayrollAPIEditEarning, true)

export const usePayrollAPIEditDedeuction = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UsePayrollAPIEditDedeuction, false)

export const usePayrollAPIPost = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UsePayrollAPIPost, true)

export const useEcPayrollDeductionCodesEndpt = () =>
  usePayrollFeatureFlag(
    PayrollFeatureFlag.UseEcPayrollDeductionCodesEndpt,
    false
  )

export const useEcPayrollAchSummaryEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollAchSummaryEndpt, true)

export const useEcPayrollCheckPdfEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollCheckPdfEndpt, false)

export const useEcPayrollChecksPdfAuditEndpt = () =>
  usePayrollFeatureFlag(
    PayrollFeatureFlag.UseEcPayrollChecksPdfAuditEndpt,
    true
  )

export const useEcPayrollCheckOverrideEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollCheckOverrideEndpt, true)

export const useEcPayrollPayByEmployeeCheckEndpt = () =>
  usePayrollFeatureFlag(
    PayrollFeatureFlag.UseEcPayrollPayByEmployeeCheckEndpt,
    true
  )

export const useEcPayrollScheduleEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollScheduleEndpt, true)

export const useToastCapitalCard = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseToastCapitalCard, false)

export const useEcPayrollEarningCodesEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollEarningCodesEndpt, true)

export const useEcPayrollAddPaycheckEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollAddPaycheckEndpt, true)

export const useEcPayrollAddEmployeeEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollAddEmployeeEndpt, true)

export const useEcPayrollPayrollAuditEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollPayrollAuditEndpt, true)

export const useEcPayrollPayrollConfigEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollPayrollConfigEndpt, true)

export const useEcPayrollArchiveEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollArchiveEndpt, false)

export const useProdSubmitValidation = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseProdSubmitValidation, false)

export const useRolloverModal = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseRolloverModal, false)

export const useSignatureCapture = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseSignatureCapture, false)

export const useMigrateCheckCodesQuery = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseMigrateCheckCodesQuery, false)

export const useForm8655BlockPayroll = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseForm8655BlockPayroll, false)

export const useEcPayrollImportEndpt = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollImportEndpt, false)

export const useEcPayrollEmployeeListQuery = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcPayrollEmployeeListEndpt, false)

export const UsePoQuickbooksConnect = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UsePoQuickbooksConnect, false)

export const UsePoQuickbookConnectMultilocation = () =>
  usePayrollFeatureFlag(
    PayrollFeatureFlag.UsePoQuickbookConnectMultilocation,
    false
  )
export const UseAutoApproveTimesheets = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseAutoApproveTimesheets, false)

export const UseEcPayrollEnforceDeadlineQuery = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcEnforceDeadlineEndpt, false)

export const UseEcSaasTransparency = () =>
  usePayrollFeatureFlag(PayrollFeatureFlag.UseEcSaasTransparency, false)
